<div>
    <mat-toolbar color="accent">
        <span>Fax Queue</span>
        <span class="fill-space"></span>
        <!-- <button mat-flat-button (click)="AddPatient()">{{ 'patients.newpatient' | translate }}</button> -->
    </mat-toolbar>
    <kendo-grid
        [kendoGridBinding]="faxes | async"
        kendoGridSelectBy="id"
        [pageSize]="15"
        [pageable]="true"
        [sortable]="{
            allowUnsort: true,
            mode: 'single'
        }"
        [filterable]="true"
        [groupable]="false"
        [reorderable]="true"
        [resizable]="true"
        [columnMenu]="{ filter: true }"
        [loading]="reportService.loading"
    >
    <kendo-grid-messages noRecords="{{ 'global.norecords' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
    </kendo-grid-messages>
    <!-- <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column> -->
    <kendo-grid-column field="type" title="{{ 'report.type' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="clinic_name" title="{{ 'clinic.clinicname' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="patient_name" title="{{ 'patients.name' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="order_number" title="{{ 'rx.ordernumber' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="status" title="{{ 'global.status' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="createAt" title="{{ 'global.added' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.createdAt?.toDate() | date: 'yyyy-MM-dd HH:mm a'}}
        </ng-template> 
    </kendo-grid-column>
    <kendo-grid-column field="updatedAt" title="{{ 'global.sent' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedAt?.toDate() | date: 'yyyy-MM-dd HH:mm a'}}
        </ng-template> 
    </kendo-grid-column>
  </kendo-grid>
  </div>    