import { Component, OnDestroy, OnInit, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { SignaturePad } from 'angular2-signaturepad';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { RxAddComponent } from 'src/app/order/rx/rx-add/rx-add.component';
import { RxReorderComponent } from 'src/app/order/rx/rx-reorder/rx-reorder.component';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Patient } from 'src/app/shared/models/patient';
import { Rx } from 'src/app/shared/models/rx';
import { User } from 'src/app/shared/models/user';
import { PatientAddComponent } from '../patient-add/patient-add.component';
import { PatientService } from '../patient.service';
import { chunk } from 'lodash';
import { AngularFirestore } from '@angular/fire/firestore';
import { faSignature, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { OrderService } from 'src/app/order/order.service';
import { Medication } from 'src/app/shared/models/medication';
import * as moment from "moment";
@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('callDeleteConfirmDialog') callDeleteConfirmDialog: TemplateRef<any>;
  @ViewChild('patientConsentDialog') patientConsentDialog: TemplateRef<any>;
  @ViewChild('availableEnrollments') availableEnrollments: TemplateRef<any>;
  @ViewChild('injectionAddConfirmDialog') injectionAddConfirmDialog: TemplateRef<any>;

  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;

  ngUnsubscribe: Subject<void> = new Subject();
  patients: any;
  public gridView: any;
  user: User = <User>{};
  appSettings: any;
  displayedColumns: string[] = [
    'patient.last_name',
    'patient.first_name',
    'patient.group',
    'patient.dob',
    'patient.last_rx',
  ];
  public mySelection: string[] = [];
  public sort: SortDescriptor[] = [
    {
      field: 'last_name',
      dir: 'asc',
    },
    {
      field: 'first_name',
      dir: 'asc',
    },
  ];
  private editedRowIndex: number;

  selectedPatient: Patient;
  signature: string;
  termsone: boolean = false;
  consentDialogRef: any;
  enrollmentDialogRef: any;
  injectionAddConfirmDialogRef: any;
  incompleteCount: number = 0;

  faSignature = faSignature;
  faFileSignature = faFileSignature;

  language: string;
  appointmentTime: any;
  note: string;
  medications: any[] = [];
  eyes: any[] = [];

  // Translations
  consentTitle = '';
  agreeButton = '';
  agreeText = '';
  cancelButton = '';
  clearSignatureButton = '';
  saveButton = '';

  constructor(
    private auth: AuthService,
    public patientService: PatientService,
    public utility: UtilityService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private db: DataService,
    private afs: AngularFirestore,
    private orderService: OrderService,
  ) {}

  ngOnInit() {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.patients = this.patientService.getPatients(this.user.clinic_id).pipe(
      map((patients) => {
        this.incompleteCount = 0;
        patients.forEach((patient) => {
          patient['chart_id'] = patient['chart_id'] ? parseInt(patient['chart_id']) : null;
          patient.consentSigned = patient.consentSigned ? patient.consentSigned : false;
          patient.chart_id = patient.chart_id ? parseInt(patient.chart_id) : null;
          patient.isReadyForEnrollment = this.isRecordCompleteForEnrollments(patient);
          patient.isReadyForRx = this.isRecordCompleteForRx(patient);
        });
        return patients;
      }),
      takeUntil(this.ngUnsubscribe)
    );

    this.db.getAppSettings()
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(settings => {
      this.appSettings = settings;
      settings['medications'].forEach(medication => {
        if (medication.provinces.includes(this.user.clinic_province)) {
          this.medications.push(medication);
        }
      });
      this.eyes = settings['eyes'];
    })
  }

  ngOnDestroy() {
    // console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.patients, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'last_name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'first_name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'email',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'clinic_name',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    this.dataBinding.skip = 0;
  }

  public resetData() {}

  public async AddPatient() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '850px';
    const dialogRef = this.matDialog.open(PatientAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.addRx) {
          this.patientService.getPatient(this.user.clinic_id, result.patientId).pipe(take(1))
            .subscribe(patient => {
              this.addRx(patient);
            });
        } else if (result.addConsent) {
          this.patientService.getPatient(this.user.clinic_id, result.patientId).pipe(take(1))
            .subscribe(patient => {
              this.signConsent(patient);
            });
        }
      }
    });
  }

  public EditPatient(patient: Patient) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '850px';
    if (patient.dob) {
      patient.dob = patient.dob.toString();
    }
    dialogConfig.data = patient;
    const dialogRef = this.matDialog.open(PatientAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result.addRx) {
          this.patientService.getPatient(this.user.clinic_id, result.patientId).pipe(take(1))
          .subscribe(patient => {
            this.addRx(patient);
          })
        } else if (result.addConsent) {
          this.patientService.getPatient(this.user.clinic_id, result.patientId).pipe(take(1))
          .subscribe(patient => {
            this.signConsent(patient);
          })
        }
      }
    });
  }

  DeletePatient(patientId: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
                this.patientService.deletePatient(this.user.clinic_id, patientId).then(() => {
                  this.utility.showSnackBar(this.translate.instant('patients.delete.success'), 2000, 'center', 'top');
                })
                .catch((err) => {
                  this.utility.showSnackBar(this.translate.instant('patients.error.delete'), 2000, 'center', 'top');
                })
            } 
        }
    })
  }


  addRx(patient: Patient) {
    this.selectedPatient = patient;
    this.appointmentTime = this.utility.roundDate(moment(), moment.duration(15, "minutes"), "ceil").format('hh:mm A');
    // console.log('Appointment time required: ', this.user.clinic.requiresAppointmentTime);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    this.injectionAddConfirmDialogRef = this.matDialog.open(this.injectionAddConfirmDialog, dialogConfig);
    this.injectionAddConfirmDialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              let rx: Rx = <Rx>{};
              rx.appointmentTime = this.user.clinic.requiresAppointmentTime ? this.appointmentTime : null;
              rx.clinic = this.user.clinic;
              rx.note = this.note ? this.note : null;
              rx.patient = patient;
              rx.medication_name = this.selectedPatient.medication.name
              rx.medication = this.selectedPatient.medication;
              rx.doctor = patient.doctor;
              rx.eye = this.selectedPatient.eye;
              rx.doctor_id = patient.doctor.id;
              rx.language = patient.language;
              rx.patient_id = patient.id;
              rx.injectionclinic = this.user.injectionclinic;
              rx.injectiondate = this.user.injectionclinic.clinicDate;
              rx.location = this.user.injectionclinic.location;

              if (rx.eye === 'BOTH') {
                rx.quantity = "2x";
                rx.quantity_numeric = 2;
              } else {
                rx.quantity = "1x";
                rx.quantity_numeric = 1;
              }
              this.orderService.addRxToBatch(this.user, rx).then(() => {
                this.utility.showSnackBar(this.translate.instant('rx.success.add'), 2000, 'center', 'top');
                this.appointmentTime = null; //moment().add(15, 'minutes').format('hh:mm A');
                this.note = null;
                this.selectedPatient = null;
              })
              .catch((err) => {
                this.utility.showSnackBar(this.translate.instant('rx.error.add'), 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  public AddRx(patient) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    patient.dob = patient.dob.toString();
    dialogConfig.data = patient;
    this.matDialog.open(RxAddComponent, dialogConfig);
  }

  public ReorderRx(patient) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    dialogConfig.data = patient;
    this.matDialog.open(RxReorderComponent, dialogConfig);
  }

  public addTooltip(lastRx: Rx) {
    if (lastRx) {
      let tooltip = '';
      tooltip += 'Dr ' + lastRx.doctor.last_name + ' - ';
      tooltip += this.utility.formatDate(lastRx.createdAt, 'MMM DD yyyy') + ' - ';
      tooltip += lastRx.medication_name + ' - ' + lastRx.eye + ' - ' + lastRx.quantity;
      if (lastRx.tobra) tooltip += 'Plus Tobramycin 1-2 GTTS AE Q4H x 3D POST INJ - ';
      if (lastRx.note) tooltip += 'Note: ' + lastRx.note;
      return tooltip;
    } else {
      return null;
    }
  }

  signConsent(patient: Patient) {
    this.selectedPatient = patient;
    this.consentTitle = this.selectedPatient.language === 'en' ? 'Consent' : 'Consentement';
    this.agreeButton = this.selectedPatient.language === 'en' ? 'Yes, I agree' : `Oui je suis d'accord`;
    this.agreeText = this.selectedPatient.language === 'en' ? 'Do you agree to let Sentrex share your information with drug plans to navigate insurance coverage.' : `Acceptez-vous que Sentrex partage vos informations avec les régimes d'assurance-médicaments pour naviguer dans la couverture d'assurance.`;
    this.clearSignatureButton = this.selectedPatient.language === 'en' ? 'Clear signature' : 'Signature claire';
    this.cancelButton = this.selectedPatient.language === 'en' ? 'Cancel' : 'Annuler';
    this.saveButton = this.selectedPatient.language === 'en' ? 'Save' : 'Sauvegarder';
    
    this.termsone = false;
    this.signature = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.height = 'auto';
    // dialogConfig.minHeight = '80vh';
    dialogConfig.width = '720px';
    // dialogConfig.width = '800px';
    this.consentDialogRef = this.matDialog.open(this.patientConsentDialog, dialogConfig);
    this.consentDialogRef.afterClosed().subscribe(result => {
        // console.log("Dialog close result: ", result);
        // if (result !== undefined) {
        //     if (result !== 'Cancel') {
              
        //     } else {
        //       this.orderForm.reset();
        //     }
        // } else {
        //   this.orderForm.reset();
        // }
    });
  }

  submitConsent() {
    // const faxnumber = this.appSettings.faxNumber;
    this.patientService.addPatientConsent(this.user.clinic_id, this.user.clinic_name, this.selectedPatient, this.signature).then(() => {
      this.utility.showSnackBar("The patient consent has been updated", 2000, 'center', 'top');
      this.consentDialogRef.close();
    })
    .catch((err) => {
      this.utility.showSnackBar("There was a problem completing the consent.  Please try again", 2000, 'center', 'top');
    })
  }

  clearSignature(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.clear();
    this.signature = null;
  }

  drawStart(index) {
    // console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    // console.log("Ended drawing on signature", index);
    let signaturePad = this.signaturePads.toArray()[index];
    if(!signaturePad.isEmpty())  {
      this.signature = signaturePad.toDataURL();
    }
  }

  async DeletePatients()  {
    let count = 1;
    this.patientService.getPatientsPromise(this.user.clinic_id).subscribe(patients => {
      const kentpatients = patients.filter(p => p.createdBy === 'Kent Spadzinski');
      const batches = chunk(kentpatients, 200).map(patients => {
        // console.log("Batch: ", count);
        const batch = this.afs.firestore.batch();
        patients.forEach(patient => {
          batch.delete(this.afs.firestore.doc(`/clinics/${this.user.clinic_id}/patients/${patient.id}`));
        });
        count++;
        return batch.commit()
      });
    })
  }

  SaveView(grid: GridComponent): void {
    const columns = grid.columns;
    // console.log(grid);
  }

  isRecordCompleteForEnrollments(patient: Patient): boolean  {
    this.incompleteCount++;
    if (!patient.first_name) return false;
    if (!patient.last_name) return false;
    if (!patient.gender) return false;
    if (!patient.dob) return false;
    if (!patient.healthcard) return false;
    if (!patient.address) return false;
    if (!patient.city) return false;
    if (!patient.province) return false;
    if (!patient.postal_code) return false;
    if (!patient.doctor) return false;
    // if (!patient.doctor.signature) return false;
    // if (!patient.consentSigned) return false;
    this.incompleteCount--;
    return true;
  }


  isRecordCompleteForRx(patient: Patient): boolean  {
    if (!patient.gender) return false;
    if (!patient.dob) return false;
    if (!patient.healthcard) return false;
    if (!patient.doctor) return false;
    if (!patient.medication) return false;
    if (!patient.eye) return false;
    return true;
  }

  public compareMedications(c1: Medication, c2: Medication): boolean {
    if (c1 && c2) {
      return c1.din == c2.din;
    }
    return false;
  }


  showPrivateSpecialPriorAuthorization() {
    if (!this.selectedPatient.medication || !this.selectedPatient.insurance_provider) return false;
    if (this.selectedPatient.insurance_provider.type === 'Private' && this.selectedPatient.insurance_provider.name === 'Medavie Blue Cross' && (this.selectedPatient.medication.name.includes('Eylea') || this.selectedPatient.medication.name.includes('Lucentis'))) {
      return true;
    }
    return false;
  }

  showPublicSpecialPriorAuthorization() {
    if (!this.selectedPatient.medication || !this.selectedPatient.healthcard) return false;
   // if (this.selectedPatient.medication.name.toLowerCase().includes('beovu')) return false;
    if (this.selectedPatient.healthcard) return true;
    return false;
  }

  showEyleaEnrollmentForm() {
    if (!this.selectedPatient.medication) return false;
    if (this.selectedPatient.medication.name.includes('Eylea'))  {
      return true;
    }
    return false;
  }

  showLucentisEnrollmentForm() {
    if (!this.selectedPatient.medication) return false;
    if (this.selectedPatient.medication.name.includes('Lucentis'))  {
      return true;
    }
    return false;
  }

  showBeovuEnrollmentForm() {
    if (!this.selectedPatient.medication) return false;
    if (this.selectedPatient.medication.name === 'Beovu')  {
      return true;
    }
    return false;
  }


  async createEnrollments(patient: Patient) {
    this.selectedPatient = patient;
    // console.log('Selected Patient Doctor Before: ', this.selectedPatient.doctor)
    this.orderService.getDoctor(this.user.clinic_id, this.selectedPatient.doctor.id).pipe(take(1)).subscribe(doctor => {
      this.selectedPatient.doctor.signature = doctor.signature;
      // console.log('Selected Patient Doctor After: ', this.selectedPatient.doctor)
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = '600px';
      this.enrollmentDialogRef = this.matDialog.open(this.availableEnrollments, dialogConfig);
      this.enrollmentDialogRef.afterClosed().subscribe(result => {
          this.selectedPatient = null;
          // console.log("Dialog close result: ", result);
      });
    })
  }


async createNBSA() {
  const url = '/assets/forms/medicare_sa.pdf';
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const form = pdfDoc.getForm();
  const doctorFirstName = form.getTextField('First Name');
  const doctorLastName = form.getTextField('Last Name')
  const patientFirstName  = form.getTextField('First Name_1');
  const patientLastName  = form.getTextField('Last Name_1');
  const patientDOB = form.getTextField('Date of Birth DDMMYYYY');
  const medicareNumber  = form.getTextField('undefined');
  const clinicAddress = form.getTextField('Mailing Address Street City Province Postal Code');
  const doctorTelephone = form.getTextField('Telephone');
  const doctorFax = form.getTextField('Fax');
  const drugName = form.getTextField('Drug Name');
  const doseRegimen = form.getTextField('Dose and Regimen');
  const diagnosisIndication = form.getTextField('Diagnosis/Indication/Rational for Use');
  const previousTherapies = form.getTextField('Relevant Previous Drug Therapies');
  const otherInformation = form.getTextField('Other Relevant Information');
  const doctorLicense = form.getTextField('License or Registration Number');
  const formDate = form.getTextField('Date');

  const doctor = this.selectedPatient.doctor;
  doctorFirstName.setText(doctor.first_name);
  doctorLastName.setText(doctor.last_name);
  doctorLicense.setText(doctor.license_number);
  doctorFax.setText(doctor.fax_number);
  doctorTelephone.setText(doctor.phone_number);
  clinicAddress.setText(
    [
      this.user.clinic.address.address,
      this.user.clinic.address.city,
      this.user.clinic.address.province,
      this.user.clinic.address.postal_code
    ]
    .join('\n')
  );

  patientFirstName.setText(this.selectedPatient.first_name);
  patientLastName.setText(this.selectedPatient.last_name);
  patientDOB.setText(this.utility.formatDate(this.selectedPatient.dob, 'DD/MM/YYYY'));
  medicareNumber.setText(this.selectedPatient.healthcard ? this.selectedPatient.healthcard.substring(0, 9) : '');

  const medName = this.selectedPatient.medication.name;
  if (medName.includes('Eylea'))  {
    drugName.setText('Eylea');
    doseRegimen.setText('2mg/0.05ml monthly intravitreal injection')
  } else if (medName.includes('Lucentis')) {
    drugName.setText('Lucentis');
    doseRegimen.setText('10mg/ml monthly intravitreal injection')
  } else if (medName.includes('Beovu'))  {
    drugName.setText('Beovu');
    doseRegimen.setText('6 mg/0.05 mL monthly intravitreal injection');
  }

  if (this.selectedPatient.diagnosis === 'wAMD') {
    diagnosisIndication.setText(
      [
        'wet age related macular degeneration',
        'Best Corrected Visual Acuity (BCVA) is between 20/40 and 20/320',
        'The lesion size is less than 12 disc areas in greatest linear dimension',
        'There is evidence of recent (less than 3 months) presumed disease progression as indicated by optical coherence tomography (OCT)'
      ]
      .join('\n')
    );
  } else if (this.selectedPatient.diagnosis === 'DME') {
    diagnosisIndication.setText(      
      [
      'Diabetic Macular Edema',
      'Patient has center-involved macular edema',
      'Best Corrected Visual Acuity is less than 20/32',
      'Central retinal thickness is greater than 250 micrometers'
      ]
      .join('\n'));
  } else if (this.selectedPatient.diagnosis === 'CRVO') {
    diagnosisIndication.setText(
      [
        'Retinal Vein Occlusion',
        'Patient has center-involved macular edema',
        'Best Corrected Visual Acuity is less than 20/32',
        'Central retinal thickness is greater than 250 micrometers'
      ]
      .join('\n')
    );
  }

  if (this.selectedPatient.insurance_provider)  {
    otherInformation.setText(
      [
        `Provider: ${this.selectedPatient.insurance_provider.name} - Policy: ${this.selectedPatient.insurance_policy} - Cert #: ${this.selectedPatient.insurance_certificate ? this.selectedPatient.insurance_certificate : 'NA'} - Carrier ID: ${this.selectedPatient.insurance_carrier ? this.selectedPatient.insurance_carrier : 'NA'}`,
        `Plan Member Name: ${this.selectedPatient.insurance_planmembername ? this.selectedPatient.insurance_planmembername : 'NA'}`
      ]
      .join('\n')
    );
  }

  previousTherapies.setText('None');
  formDate.setText(this.utility.formatDate(null, 'DD/MM/YYYY'))

  const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
  const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
  const page = pdfDoc.getPage(0);
  const pngDims = pngImage.scale(0.2)
  page.drawImage(pngImage, {
    x: 30,
    y: 105,
    width: pngDims.width,
    height: pngDims.height,
  });

    // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  // const pages = pdfDoc.getPages()
  // const firstPage = pages[0]
  // const { width, height } = firstPage.getSize()
  // firstPage.drawText('This was a test by Kent', {
  //   x: 5,
  //   y: height / 2 + 300,
  //   size: 50,
  //   font: helveticaFont,
  //   color: rgb(0.95, 0.1, 0.1),
  //   rotate: degrees(-45),
  // })

  const pdfBytes = await pdfDoc.save()
  var file = new Blob([pdfBytes], { type: 'application/pdf' });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
  // const blob = new Blob([pdfBytes]);
  // const fileName = `test.pdf`;
  // const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    // if (link.download !== undefined) {
    //   const url = URL.createObjectURL(blob);
    //   link.setAttribute('href', url);
    //   link.setAttribute('download', fileName);
    //   link.style.visibility = 'hidden';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }
  }

  async createMBCSA() {
    const url = '/assets/forms/bluecross_sa_eylea_lucentis.pdf';
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
  
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
    const form = pdfDoc.getForm();
    const doctorName = form.getTextField('Physician Name');
    // const doctorLicense = form.getTextField('License or Registration Number');
    const doctorTelephone = form.getTextField('Telephone Number_3');
    const doctorFax = form.getTextField('Fax Number_2');

    const patientPlanMemberName  = form.getTextField('Plan Member Name');
    const address = form.getTextField('Address');
    const city = form.getTextField('City');
    const province = form.getTextField('Province');
    const postalCode = form.getTextField('Postal Code');

    const patientName = form.getTextField('Patient Name if not plan member');
    const address2 = form.getTextField('Address_2');
    const city2 = form.getTextField('City_2');
    const province2 = form.getTextField('Province_2');
    const postalCode2 = form.getTextField('Postal Code_2');

    const patientDOB = form.getTextField('Date of Birth');
    // const medicareNumber  = form.getTextField('undefined');
    // const clinicAddress = form.getTextField('Mailing Address Street City Province Postal Code');
    // const drugName = form.getTextField('Drug Name');
    // const doseRegimen = form.getTextField('Dose and Regimen');
    // const diagnosisIndication = form.getTextField('Diagnosis/Indication/Rational for Use');
    // const previousTherapies = form.getTextField('Relevant Previous Drug Therapies');
    // const otherInformation = form.getTextField('Other Relevant Information');

    const formDate = form.getTextField('Date_3');
  
    const doctor = this.selectedPatient.doctor;
    doctorName.setText(doctor.full_name);
    // doctorLicense.setText(doctor.license_number);
    doctorFax.setText(doctor.fax_number);
    doctorTelephone.setText(doctor.phone_number);
    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const page = pdfDoc.getPage(3);
    const pngDims = pngImage.scale(0.15)
    page.drawImage(pngImage, {
      x: 110,
      y: 40,
      width: pngDims.width,
      height: pngDims.height,
    });
    const pName = this.selectedPatient.first_name + ' ' + this.selectedPatient.last_name;
    const planMemberName = this.selectedPatient.insurance_planmembername ? this.selectedPatient.insurance_planmembername : pName; 
    patientPlanMemberName.setText(planMemberName);
    form.getTextField('Telephone Number').setText(this.selectedPatient.phone_number ? this.selectedPatient.phone_number : 'NA');
    form.getTextField('Policy Number').setText(this.selectedPatient.insurance_policy ? this.selectedPatient.insurance_policy : '');
    form.getTextField('ID Number').setText(this.selectedPatient.insurance_carrier ? this.selectedPatient.insurance_carrier : '');

    form.getTextField('Telephone Number_2').setText(this.selectedPatient.phone_number ? this.selectedPatient.phone_number : 'NA');
    form.getTextField('ID Number_2').setText(this.selectedPatient.insurance_carrier ? this.selectedPatient.insurance_carrier : '');
    patientName.setText(pName);
    patientDOB.setText(this.utility.formatDate(this.selectedPatient.dob, 'DD/MM/YYYY'));
    address.setText(this.selectedPatient.address);
    address2.setText(this.selectedPatient.address);
    city.setText(this.selectedPatient.city);
    city2.setText(this.selectedPatient.city);
    province.setText(this.selectedPatient.province);
    province2.setText(this.selectedPatient.province);
    postalCode.setText(this.selectedPatient.postal_code);
    postalCode2.setText(this.selectedPatient.postal_code);

    let pharmacyName = '';
    let pharmacyAddress = '';
    let pharmacyPhone = '';
    let pharmacyFax = '';
    if (this.user.clinic_province === 'NB') {
      pharmacyName = 'Sentrex Pharmacy Moncton';
      pharmacyPhone = '1 (833) 753-1468';
      pharmacyFax = '1 (833) 478-1407';
    } else if (this.user.clinic_province === 'NS')  {
      pharmacyName = 'Sentrex Pharmacy Halifax';
      pharmacyPhone = '1 (833) 743-0623';
      pharmacyFax = '1 (833) 516-2199';
    } else if (this.user.clinic_province === 'NL')  {
      pharmacyName = 'Sentrex Pharmacy Corner Brook';
      pharmacyPhone = '1 (833) 753-1469';
      pharmacyFax = 'NA';
    } else if (this.user.clinic_province === 'ON')  {
      pharmacyName = '';
      pharmacyPhone = '';
      pharmacyFax = '';
    }
    form.getCheckBox('Fax').uncheck;
    form.getCheckBox('Telephone').check;
    form.getTextField('Name of ProgramPharmacy').setText(pharmacyName);
    form.getTextField('Contact Number').setText(pharmacyPhone);
    form.getTextField('Fax Number').setText(pharmacyFax);

    // const medName = this.medicationControl.value.name;
    // if (medName.includes('Eylea'))  {
    //   drugName.setText('Eylea');
    //   doseRegimen.setText('2mg/0.05ml monthly intravitreal injection')
    // } else if (medName.includes('Lucentis')) {
    //   drugName.setText('Lucentis');
    //   doseRegimen.setText('10mg/ml monthly intravitreal injection')
    // } else if (medName.includes('Beovu'))  {
    //   drugName.setText('Beovu');
    //   doseRegimen.setText('6 mg/0.05 mL monthly intravitreal injection');
    // }
  
    // if (this.diagnosisControl.value === 'wAMD') {
    //   diagnosisIndication.setText('wet age related macular degeneration');
    //   otherInformation.setText(
    //     [
    //       'Best Corrected Visual Acuity (BCVA) is between 20/40 and 20/320',
    //       'The lesion size is less than 12 disc areas in greatest linear dimension',
    //       'There is evidence of recent (less than 3 months) presumed disease progression as indicated by optical coherence tomography (OCT)'
    //     ]
    //     .join('\n')
    //   );
    // } else if (this.diagnosisControl.value === 'DME') {
    //   diagnosisIndication.setText('Diabetic Macular Edema');
    //   otherInformation.setText(
    //     [
    //       'Patient has center-involved macular edema',
    //       'Best Corrected Visual Acuity is less than 20/32',
    //       'Central retinal thickness is greater than 250 micrometers'
    //     ]
    //     .join('\n')
    //   );
    // } else if (this.diagnosisControl.value === 'CRVO') {
    //   diagnosisIndication.setText('Retinal Vein Occlusion');
    //   otherInformation.setText(
    //     [
    //       'Patient has center-involved macular edema',
    //       'Best Corrected Visual Acuity is less than 20/32',
    //       'Central retinal thickness is greater than 250 micrometers'
    //     ]
    //     .join('\n')
    //   );
    // }
  
    // previousTherapies.setText('None');
    formDate.setText(this.utility.formatDate(null, 'DD/MM/YYYY'))
  
      // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    // const pages = pdfDoc.getPages()
    // const firstPage = pages[0]
    // const { width, height } = firstPage.getSize()
    // firstPage.drawText('This was a test by Kent', {
    //   x: 5,
    //   y: height / 2 + 300,
    //   size: 50,
    //   font: helveticaFont,
    //   color: rgb(0.95, 0.1, 0.1),
    //   rotate: degrees(-45),
    // })
  
    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    // const blob = new Blob([pdfBytes]);
    // const fileName = `test.pdf`;
    // const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      // if (link.download !== undefined) {
      //   const url = URL.createObjectURL(blob);
      //   link.setAttribute('href', url);
      //   link.setAttribute('download', fileName);
      //   link.style.visibility = 'hidden';
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // }
  }

  async createEyeQEnrolment() {
    const url = this.selectedPatient.language === 'en' ? '/assets/forms/eylea_en.pdf' : '/assets/forms/eylea_fr.pdf';
    // const url = '/assets/forms/eylea_en.pdf';
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    // const fields = form.getFields()
    // fields.forEach(field => {
    //   const name = field.getName()
    //   // console.log('Field name:', name)
    // })

    if (this.selectedPatient.diagnosis === 'wAMD') {
      form.getCheckBox('Check Box5').check();
    } else if (this.selectedPatient.diagnosis === 'DME') {
      form.getCheckBox('Check Box3').check();
    } else if (this.selectedPatient.diagnosis === 'CRVO') {
      form.getCheckBox('Check Box2').check();
    } else if (this.selectedPatient.diagnosis === 'BRVO') {
      form.getCheckBox('Check Box1').check();
    } else if (this.selectedPatient.diagnosis === 'mCNV') {
      form.getCheckBox('Check Box4').check();
    }

    if (this.selectedPatient.healthcard) {
      form.getCheckBox('Check Box7').check();
    } else {
      if (this.selectedPatient.insurance_provider)  {
        form.getCheckBox('Check Box8').check();
      }
    }

    if (this.selectedPatient.eye === 'OD') {
      form.getCheckBox('Check Box13').check();
      form.getCheckBox('Check Box11').check();
    } else if (this.selectedPatient.eye === 'OS') {
      form.getCheckBox('Check Box14').check();
      form.getCheckBox('Check Box11').check();
    } else {
      form.getCheckBox('Check Box15').check();
      form.getCheckBox('Check Box12').check();
    }
    
    form.getTextField('Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    form.getTextField('Date 2').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    form.getTextField('Date 3').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    form.getTextField('Injection Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));

    form.getTextField('Name').setText(this.selectedPatient.first_name + ' ' + this.selectedPatient.last_name);
    form.getTextField('Birthdate').setText(this.utility.formatDate(this.selectedPatient.dob, 'MM-DD-YYYY'));
    form.getTextField('Address').setText(this.selectedPatient.address);
    form.getTextField('Address 2').setText(this.selectedPatient.city + ' ' + this.selectedPatient.province + ', ' + this.selectedPatient.postal_code);
    form.getCheckBox('Check Box19').check();
    if (this.selectedPatient.insurance_provider) {
      if (this.selectedPatient.insurance_provider.type === 'Private') {
        form.getTextField('Private Insurer Name').setText(this.selectedPatient.insurance_provider.name);
      }
    }
    form.getTextField('Phone').setText(this.selectedPatient.phone_number ? this.selectedPatient.phone_number : '');
    form.getTextField('Email').setText(this.selectedPatient.email ? this.selectedPatient.email : '');

    if (this.selectedPatient.language === 'en')  {
      form.getCheckBox('Check Box16').check();
    } else {
      form.getCheckBox('Check Box17').check();
    }

    const doctor = this.selectedPatient.doctor
    form.getTextField('Verbal consent provided by').setText(doctor.full_name);
    form.getTextField(`Prescribing Physician's Name`).setText(doctor.full_name);
    form.getTextField('Clinic Name/Location').setText(this.user.clinic.clinic_name + ', ' + this.user.clinic.address.address + ', ' + this.user.clinic.address.city + ' ' + this.user.clinic.address.province + ', ' + this.user.clinic.address.postal_code);
    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const page = pdfDoc.getPage(0);

    let patSigY = 110;
    let patDoc1Y = 380;
    let patDoc2Y = 40;
    let patDoc2X = 100;
    if (this.selectedPatient.language)  {
      if (this.selectedPatient.language === 'fr') {
        patSigY = 100;
        patDoc1Y = 360;
        patDoc2Y = 40;
        patDoc2X = 200;
      } 
    }
    if (this.selectedPatient.consentId) {
      const patientConsentDoc = await this.patientService.getPatientConsent(this.selectedPatient.consentId);
      const patientSignature = patientConsentDoc.signature;
      const patientSignatureImageBytes = await fetch(patientSignature).then(res => res.arrayBuffer());
      const pngPatImage = await pdfDoc.embedPng(patientSignatureImageBytes)
  
      const pngDims1 = pngPatImage.scale(0.10)
      page.drawImage(pngPatImage, {
        x: 120,
        y: patSigY,
        width: pngDims1.width,
        height: pngDims1.height,
      });

      const patSigPage2 = pdfDoc.getPage(1);
      patSigPage2.drawImage(pngPatImage, {
        x: patSigPage2.getWidth() / 2 - 80,
        y: 135,
        width: pngDims1.width,
        height: pngDims1.height,
      });

      form.getTextField('Printed name').setText(this.selectedPatient.first_name + ' ' + this.selectedPatient.last_name);      
      form.getTextField('Date 4').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));      
    }

    const pngDims = pngImage.scale(0.15)
    page.drawImage(pngImage, {
      x: 70,
      y: patDoc1Y,
      width: pngDims.width,
      height: pngDims.height,
    });
    page.drawImage(pngImage, {
      x: patDoc2X,
      y: patDoc2Y,
      width: pngDims.width,
      height: pngDims.height,
    });
    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  async createLucentisEnrolment() {
    // const url = this.selectedPatient.language === 'en' ? '/assets/forms/lucentis_en.pdf' : '/assets/forms/lucentis_fr.pdf';
    const url = '/assets/forms/lucentis_en.pdf';
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    // const fields = form.getFields()
    // fields.forEach(field => {
    //   const name = field.getName()
    //   // console.log('Field name:', name)
    // })

    if (this.selectedPatient.diagnosis === 'wAMD') {
      form.getCheckBox('Check Box4').check();
    } else if (this.selectedPatient.diagnosis === 'DME') {
      form.getCheckBox('Check Box5').check();
    } else if (this.selectedPatient.diagnosis === 'CRVO') {
      form.getCheckBox('Check Box6').check();
    } else if (this.selectedPatient.diagnosis === 'BRVO') {
      form.getCheckBox('Check Box7').check();
    } else if (this.selectedPatient.diagnosis === 'mCNV') {
      form.getCheckBox('Check Box8').check();
    }

    if (this.selectedPatient.healthcard)  {
      form.getTextField('Healthcard Number').setText(this.selectedPatient.healthcard);
    } else {
      form.getRadioGroup('YN3').select("Choice1");
    }

    if (this.selectedPatient.eye === 'OD') {
      form.getCheckBox('Check Box11').check();
      form.getCheckBox('Check BoxA5').check();
    } else if (this.selectedPatient.eye === 'OS') {
      form.getCheckBox('Check Box10').check();
      form.getCheckBox('Check BoxA4').check();
    } else {
      form.getCheckBox('Check Box10').check();
      form.getCheckBox('Check Box11').check();
      form.getCheckBox('Check BoxA4').check();
      form.getCheckBox('Check BoxA5').check();
    }
    
    // form.getTextField('Text Field Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Verbal Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Injection Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));

    form.getTextField('Patient Surname').setText(this.selectedPatient.last_name);
    form.getTextField('Patient First Name').setText(this.selectedPatient.first_name);
    form.getTextField('Date of Birth 1').setText(this.utility.formatDate(this.selectedPatient.dob, 'MM-DD-YYYY'));
    form.getTextField('Patient Address').setText(this.selectedPatient.address);
    form.getTextField('Patient City').setText(this.selectedPatient.city);
    form.getTextField('Patient Prov').setText(this.selectedPatient.province);
    form.getTextField('Patient Postal Code').setText(this.selectedPatient.postal_code);
    form.getCheckBox('Check Box3').check();

    if (this.selectedPatient.gender === 'F') {
      form.getRadioGroup('Gender').select("Choice1");
    } else {
      form.getRadioGroup('Gender').select("Choice2");
    }
    // form.getTextField('Text Field Patient Address Line 2').setText(this.cityControl.value + ' ' + this.provinceControl.value + ', ' + this.postalCodeControl.value);
    // form.getCheckBox('Check Box Phone').check();
    // if (this.insuranceProviderControl.value.type === 'Private') {
    //   form.getTextField('Text Field Patient Private Insurer').setText(this.insuranceProviderControl.value.name);
    // }
    form.getTextField('Phone Number 1').setText(this.selectedPatient.phone_number ? this.selectedPatient.phone_number : '');
    if (this.selectedPatient.email)  {
      form.getCheckBox('Check Box2').check();
      form.getTextField('Email Address 1').setText(this.selectedPatient.email ? this.selectedPatient.email : '');
    }
    // form.getTextField('Text Field Patient Email').setText(this.emailControl.value ? this.emailControl.value : '');

    // if (this.languageControl.value === 'en')  {
    //   form.getCheckBox('Check Box English').check();
    // } else {
    //   form.getCheckBox('Check Box French').check();
    // }

    form.getRadioGroup('Dose').select("Choice1");
    form.getTextField('Patient Name 2').setText(this.selectedPatient.first_name + ' ' + this.selectedPatient.last_name);
    form.getTextField('Date 5').setText(this.utility.formatDate(this.selectedPatient.dob, 'MM-DD-YYYY'));
    form.getCheckBox('Check BoxA2').check();
    const doctor = this.selectedPatient.doctor
    form.getTextField('Physician Name').setText(doctor.full_name);
    form.getTextField('License Number').setText(doctor.license_number);
    form.getTextField('Physician Address').setText(this.user.clinic.address.address);
    form.getTextField('City 2').setText(this.user.clinic.address.city);
    form.getTextField('Prov 2').setText(this.user.clinic.address.province);
    form.getTextField('Code 2').setText(this.user.clinic.address.postal_code);
    form.getTextField('Phone Number 2').setText(doctor.phone_number);
    form.getTextField('Phone Number 3').setText(doctor.fax_number);
    // + ', ' + this.user.clinic.address.city + ' ' + this.user.clinic.address.province + ', ' + this.user.clinic.address.postal_code);
    form.getTextField('Date 6').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));

    const page = pdfDoc.getPage(0);

    if (this.selectedPatient.consentId) {
      const patientConsentDoc = await this.patientService.getPatientConsent(this.selectedPatient.consentId);
      const patientSignature = patientConsentDoc.signature;
      const patientSignatureImageBytes = await fetch(patientSignature).then(res => res.arrayBuffer());
      const pngPatImage = await pdfDoc.embedPng(patientSignatureImageBytes)
  
      const pngDims1 = pngPatImage.scale(0.15)
      page.drawImage(pngPatImage, {
        x: 70,
        y: page.getHeight() - 190,
        width: pngDims1.width,
        height: pngDims1.height,
      });

      const patPage2 = pdfDoc.getPage(1);
      patPage2.drawImage(pngPatImage, {
        x: page.getWidth() / 2 + 50,
        y: page.getHeight() / 2 - 20,
        width: pngDims1.width,
        height: pngDims1.height,
      });
      form.getTextField('Text8').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    }

    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const pngDims = pngImage.scale(0.25)
    page.drawImage(pngImage, {
      x: page.getWidth() / 2 + 30,
      y: 140,
      width: pngDims.width,
      height: pngDims.height,
    });
    const page2 = pdfDoc.getPage(1);
    const pngDims2 = pngImage.scale(0.15)
    page2.drawImage(pngImage, {
      x: 60,
      y: 190,
      width: pngDims2.width,
      height: pngDims2.height,
    });

    let pharmacyName = '';
    let pharmacyAddress = '';
    let pharmacyCity = '';
    let pharmacyProvince = '';
    let pharmacyPostalCode = '';
    let pharmacyPhone = '';
    let pharmacyFax = '';
    if (this.user.clinic_province === 'NB') {
      pharmacyName = 'Sentrex Pharmacy Moncton';
      pharmacyAddress = '3–240 Commerce Street';
      pharmacyCity = 'Moncton';
      pharmacyProvince = 'NB';
      pharmacyPostalCode = 'E1H 2G2';
      pharmacyPhone = '1 (833) 753-1468';
      pharmacyFax = '1 (833) 478-1407';
    } else if (this.user.clinic_province === 'NS')  {
      pharmacyName = 'Sentrex Pharmacy Halifax';
      pharmacyAddress = '3H–110 Chain Lake Drive';
      pharmacyCity = 'Halifax';
      pharmacyProvince = 'NS';
      pharmacyPostalCode = 'B3S 1A9';
      pharmacyPhone = '1 (833) 743-0623';
      pharmacyFax = '1 (833) 516-2199';
    } else if (this.user.clinic_province === 'NL')  {
      pharmacyName = 'Sentrex Pharmacy Corner Brook';
      pharmacyAddress = '4 Grenfell Drive, Suite 101';
      pharmacyCity = 'Corner Brook';
      pharmacyProvince = 'NL';
      pharmacyPostalCode = 'A2H 0J6';
      pharmacyPhone = '1 (833) 753-1469';
      pharmacyFax = 'NA';
    } else if (this.user.clinic_province === 'ON')  {
      pharmacyName = '';
      pharmacyAddress = '';
      pharmacyPhone = '';
      pharmacyFax = '';
    }
    form.getTextField('Name of Pharmacy').setText(pharmacyName);
    form.getTextField('Pharmacy Address').setText(pharmacyAddress);
    form.getTextField('City 4').setText(pharmacyCity);
    form.getTextField('Prov 4').setText(pharmacyProvince);
    form.getTextField('Code 4').setText(pharmacyPostalCode);
    form.getTextField('Phone Number 4').setText(pharmacyPhone);
    form.getTextField('Phone Number 5').setText(pharmacyFax);
    form.getTextField('Shipping').setText('Same as pharmacy address above');

    // await pdfDoc.save();
    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  getLogo() {
    if (this.selectedPatient.medication)  {
      if (this.selectedPatient.medication.name.toLowerCase().includes('eylea')) return  `../../../assets/images/eylea_program_logo.png`
      if (this.selectedPatient.medication.name.toLowerCase().includes('lucentis')) return  `../../../assets/images/lucentis_program_logo.png`
      if (this.selectedPatient.medication.name.toLowerCase().includes('beovu')) return  `../../../assets/images/beovu_program_logo.png`
    }
    return null;
  }

  async createBeovuEnrolment() {
    // const url = this.selectedPatient.language === 'en' ? '/assets/forms/beovu_en_nb.pdf' : '/assets/forms/beovu_fr_nb.pdf';
    const url = '/assets/forms/beovu_en_nb.pdf'

    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    const fields = form.getFields()
    fields.forEach(field => {
      const name = field.getName()
      // console.log('Field name:', name)
    })

    if (this.selectedPatient.diagnosis === 'wAMD') {
      form.getCheckBox('Check Box 4').check();
    // } else if (this.diagnosisControl.value === 'DME') {
    //   form.getCheckBox('Check Box5').check();
    // } else if (this.diagnosisControl.value === 'CRVO') {
    //   form.getCheckBox('Check Box6').check();
    // } else if (this.diagnosisControl.value === 'BRVO') {
    //   form.getCheckBox('Check Box7').check();
    // } else if (this.diagnosisControl.value === 'mCNV') {
    //   form.getCheckBox('Check Box8').check();
    }

    if (this.selectedPatient.healthcard)  {
      form.getTextField('Text Field 9').setText(this.selectedPatient.healthcard);
    }

    if (!this.selectedPatient.insurance_provider)  {
      form.getCheckBox('Check Box 5').check();
    } 
    // else {
    //   form.getTextField('Health Card Number').setText(this.selectedPatient.healthcard);
    // }

    form.getCheckBox('Check Box 2').check();
    form.getCheckBox('Check Box 12').check();
    form.getCheckBox('Check Box 3').check();
    form.getCheckBox('Check Box 6').check();
    form.getCheckBox('Check Box 15').check();
    // initial dose
    form.getCheckBox('Check Box 18').check();
    if (this.selectedPatient.eye === 'OD') {
      form.getCheckBox('Check Box 8').check();
      form.getCheckBox('Check Box 20').check();
      form.getCheckBox('Check Box 16').check();
    } else if (this.selectedPatient.eye === 'OS') {
      form.getCheckBox('Check Box 7').check();
      form.getCheckBox('Check Box 19').check();
      form.getCheckBox('Check Box 14').check();
    } else {
      form.getCheckBox('Check Box 7').check();
      form.getCheckBox('Check Box 8').check();
      form.getCheckBox('Check Box 19').check();
      form.getCheckBox('Check Box 20').check();
      form.getCheckBox('Check Box 14').check();
      form.getCheckBox('Check Box 16').check();
    }
    



    // form.getTextField('Text Field Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Verbal Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Injection Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));

    form.getTextField('Text Field 4').setText(this.selectedPatient.last_name + ' ' + this.selectedPatient.first_name);
    form.getTextField('Text Field 15').setText(this.selectedPatient.last_name + ' ' + this.selectedPatient.first_name);
    form.getTextField('Text Field 11').setText(this.utility.formatDate(this.selectedPatient.dob, 'MM-DD-YYYY'));
    form.getTextField('Text Field 18').setText(this.utility.formatDate(this.selectedPatient.dob, 'MM-DD-YYYY'));
    form.getTextField('Text Field 5').setText(this.selectedPatient.address);
    form.getTextField('Text Field 6').setText(this.selectedPatient.city);
    form.getTextField('Text Field 7').setText(this.selectedPatient.province);
    form.getTextField('Text Field 8').setText(this.selectedPatient.postal_code);
    form.getCheckBox('Check Box 5').check();
     // voicemail
     form.getCheckBox('Check Box 13').check();

    if (this.selectedPatient.gender === 'F') {
      form.getCheckBox('Check Box 10').check();
    } else {
      form.getCheckBox('Check Box 11').check();
    }
    // form.getTextField('Text Field Patient Address Line 2').setText(this.cityControl.value + ' ' + this.provinceControl.value + ', ' + this.postalCodeControl.value);
    // form.getCheckBox('Check Box Phone').check();
    // if (this.insuranceProviderControl.value.type === 'Private') {
    //   form.getTextField('Text Field Patient Private Insurer').setText(this.insuranceProviderControl.value.name);
    // }
    form.getTextField('Text Field 10').setText(this.selectedPatient.phone_number ? this.selectedPatient.phone_number : '');
    if (this.selectedPatient.email)  {
      form.getCheckBox('Check Box 1').check();
      form.getTextField('Text Field 3').setText(this.selectedPatient.email ? this.selectedPatient.email : '');
    }
    //form.getCheckBox('Check Box 12').check();
    // form.getTextField('Text Field Patient Email').setText(this.emailControl.value ? this.emailControl.value : '');

    // if (this.languageControl.value === 'en')  {
    //   form.getCheckBox('Check Box English').check();
    // } else {
    //   form.getCheckBox('Check Box French').check();
    // }

    // form.getCheckBox('Check Box 14').check();
    // form.getTextField('Patient Name').setText(this.selectedPatient.first_name + ' ' + this.selectedPatient.last_name);
    form.getTextField('Text Field 12').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    form.getTextField('Text Field 17').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    const doctor = this.selectedPatient.doctor
    // form.getTextField('Text Field 16').setText(doctor.full_name);
    form.getTextField('Text Field 19').setText(doctor.full_name);
    form.getTextField('Text Field 20').setText(doctor.license_number);
    form.getTextField('Text Field 21').setText(this.user.clinic.address.address);
    form.getTextField('Text Field 22').setText(this.user.clinic.address.city);
    form.getTextField('Text Field 25').setText(this.user.clinic.address.province);
    form.getTextField('Text Field 26').setText(this.user.clinic.address.postal_code);
    form.getTextField('Text Field 23').setText(doctor.phone_number);
    form.getTextField('Text Field 27').setText(doctor.fax_number);

    form.getTextField('Text Field 28').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    const page = pdfDoc.getPage(0);

    if (this.selectedPatient.consentId) {
      const patientConsentDoc = await this.patientService.getPatientConsent(this.selectedPatient.consentId);
      const patientSignature = patientConsentDoc.signature;
      const patientSignatureImageBytes = await fetch(patientSignature).then(res => res.arrayBuffer());
      const pngPatImage = await pdfDoc.embedPng(patientSignatureImageBytes)
  
      const pngDims1 = pngPatImage.scale(0.15)
      page.drawImage(pngPatImage, {
        x: 35,
        y: page.getHeight() - 160,
        width: pngDims1.width,
        height: pngDims1.height,
      });

      form.getTextField('Text Field 2').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    }

    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const pngDims2 = pngImage.scale(0.25)
    page.drawImage(pngImage, {
      x: page.getWidth() / 2 - 10,
      y: 105,
      width: pngDims2.width,
      height: pngDims2.height,
    });
    const page2 = pdfDoc.getPage(1);
    const pngDims3 = pngImage.scale(0.15)
    page2.drawImage(pngImage, {
      x: page.getWidth() / 2,
      y: 320,
      width: pngDims3.width,
      height: pngDims3.height,
    });

    let pharmacyName = '';
    let pharmacyAddress = '';
    let pharmacyCity = '';
    let pharmacyProvince = '';
    let pharmacyPostalCode = '';
    let pharmacyPhone = '';
    let pharmacyFax = '';
    if (this.user.clinic_province === 'NB') {
      pharmacyName = 'Sentrex Pharmacy Moncton';
      pharmacyAddress = '3–240 Commerce Street';
      pharmacyCity = 'Moncton';
      pharmacyProvince = 'NB';
      pharmacyPostalCode = 'E1H 2G2';
      pharmacyPhone = '1 (833) 753-1468';
      pharmacyFax = '1 (833) 478-1407';
    } else if (this.user.clinic_province === 'NS')  {
      pharmacyName = 'Sentrex Pharmacy Halifax';
      pharmacyAddress = '3H–110 Chain Lake Drive';
      pharmacyCity = 'Halifax';
      pharmacyProvince = 'NS';
      pharmacyPostalCode = 'B3S 1A9';
      pharmacyPhone = '1 (833) 743-0623';
      pharmacyFax = '1 (833) 516-2199';
    } else if (this.user.clinic_province === 'NL')  {
      pharmacyName = 'Sentrex Pharmacy Corner Brook';
      pharmacyAddress = '4 Grenfell Drive, Suite 101';
      pharmacyCity = 'Corner Brook';
      pharmacyProvince = 'NL';
      pharmacyPostalCode = 'A2H 0J6';
      pharmacyPhone = '1 (833) 753-1469';
      pharmacyFax = 'NA';
    } else if (this.user.clinic_province === 'ON')  {
      pharmacyName = '';
      pharmacyAddress = '';
      pharmacyPhone = '';
      pharmacyFax = '';
    }
    // form.getCheckBox('Check Box 26').check();
    form.getTextField('Text Field 35').setText(pharmacyName);
    form.getTextField('Text Field 36').setText(pharmacyAddress);
    form.getTextField('Text Field 37').setText(pharmacyCity);
    form.getTextField('Text Field 38').setText(pharmacyProvince);
    form.getTextField('Text Field 40').setText(pharmacyPostalCode);
    form.getTextField('Text Field 39').setText(pharmacyPhone);

    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

    // const gridConfig = {
    //     state: this.grid1Settings.state,
    //     columnsConfig: columns.toArray().map(
    //         item => Object.keys(item)
    //             .filter(
    //                 propName => !propName.toLowerCase().includes('template')
    //             )
    //             .reduce((acc, curr) => ({...acc, ...{[curr]: item[curr]}}), <ColumnSettings> {});
    //     )
    // };

    // this.persistingService.set('gridSettings', gridConfig);
}
