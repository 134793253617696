<h2 mat-dialog-title class="title">{{ pageTitle ? pageTitle : ('patients.newpatient' | translate) }}</h2>
<mat-dialog-content class="mat-typography"> 
    <form [formGroup]="patientForm">
        <div>
            <mat-form-field>
                <mat-label>{{ 'rx.doctor' | translate }}</mat-label>
                <mat-select cdkFocusInitial [formControl]="doctorControl" [compareWith]="compareDoctors" required>
                  <mat-option *ngFor="let doctor of doctors" [value]="doctor">Dr {{ doctor.full_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              &nbsp;
              <mat-form-field class="fc125" required>
                <mat-label>{{ 'patients.indication' | translate }}</mat-label>
                <mat-select [formControl]="diagnosisControl" required>
                    <mat-option *ngFor="let diag of diagnosis" [value]="diag.value">{{ language === 'fr' ? diag.name_fr : diag.name }}</mat-option>
                </mat-select>
            </mat-form-field> 
            &nbsp;
            <mat-form-field class="fc125">
                <mat-label>{{ 'rx.medication' | translate }}</mat-label>
                <mat-select [formControl]="medicationControl" [compareWith]="compareMedications" required>
                  <mat-option *ngFor="let medication of medications" [value]="medication">{{ medication.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              &nbsp;
              <mat-form-field class="fc100">
                <mat-label>{{ 'rx.eye' | translate }}</mat-label>
                <mat-select [formControl]="eyeControl" required>
                  <mat-option *ngFor="let eye of eyes" [value]="eye['value']">{{ language === 'fr' ? eye['name_fr'] : eye['name'] }}</mat-option>
                </mat-select>
              </mat-form-field>
              &nbsp;           
              <mat-form-field class="fc150" required>
               <mat-label>Medicare #</mat-label>
               <input autocomplete="off"  matInput [formControl]="healthCardControl" [errorStateMatcher]="matcher" maxlength="12" minlength="9" [required]="!dobControl.value">
               <!-- <mat-error *ngIf="healthCardControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error> -->
               <mat-error *ngIf="healthCardControl.hasError('required')" [innerHTML]="'global.doborhealthcardrequired' | translate"></mat-error>
           </mat-form-field>
        </div>
        <div>
            <!-- <div class="subheader">Details</div>
            <mat-divider class="divider"></mat-divider> -->
            <mat-form-field class="fc150">
                <mat-label>{{ 'patients.firstname' | translate }}</mat-label>
                <input matInput autocomplete="off"  [formControl]="firstNameControl" [errorStateMatcher]="matcher" required />
                <mat-error *ngIf="firstNameControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
            &nbsp;            
            <mat-form-field class="fc150">
                <mat-label>{{ 'patients.lastname' | translate }}</mat-label>
                <input matInput autocomplete="off"  [formControl]="lastNameControl" [errorStateMatcher]="matcher" required />
                <mat-error *ngIf="lastNameControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
            &nbsp;
            <mat-form-field class="fc100" >
                <mat-label>{{ 'patients.gender' | translate }}</mat-label>
                <mat-select autocomplete="off"  [formControl]="genderControl" [errorStateMatcher]="matcher" required>
                  <mat-option value="M">{{ 'patients.male' | translate}}</mat-option>
                  <mat-option value="F">{{ 'patients.female' | translate}}</mat-option>
                </mat-select>
                <mat-error *ngIf="genderControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
              </mat-form-field>
              &nbsp;            
              <mat-form-field class="fc125">
                  <input matInput autocomplete="off"  [formControl]="dobControl" [matDatepicker]="dob" [errorStateMatcher]="matcher" placeholder="{{ 'patients.dateofbirth' | translate}}" [required]="!healthCardControl.value">
                  <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                  <mat-datepicker #dob disabled="false"></mat-datepicker>
                  <!-- <mat-error *ngIf="dobControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error> -->
                  <mat-error *ngIf="dobControl.hasError('required')" [innerHTML]="'global.doborhealthcardrequired' | translate"></mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field class="fc100" >
                    <mat-label>{{ 'global.language' | translate }}</mat-label>
                    <mat-select autocomplete="off" [formControl]="languageControl" [errorStateMatcher]="matcher" required>
                      <mat-option value="fr">{{ 'global.french' | translate}}</mat-option>
                      <mat-option value="en">{{ 'global.english' | translate}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="languageControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                  </mat-form-field>
        </div>
        <!-- <div class="subheader">{{ 'patients.diagnosismedication' | translate }}</div>  -->
        <div>        
              <mat-form-field *ngIf="groups.length > 0" class="fc200">
                  <mat-label>{{ 'patients.group' | translate }}</mat-label>
                  <mat-select autocomplete="off"  [formControl]="groupControl">
                      <mat-option *ngFor="let group of groups" [value]="group">{{ group.group_name }}</mat-option>
                  </mat-select>
              </mat-form-field> 
            &nbsp;
            <mat-form-field class="fc200">
                <mat-label>{{ 'patients.email' | translate }}</mat-label>
                <input matInput autocomplete="off"  [formControl]="emailControl" [errorStateMatcher]="matcher" />
                <!-- <mat-icon matSuffix>mail</mat-icon> -->
                <mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')" [innerHTML]="'patients.errors.dobinvalid' | translate"></mat-error>
                <mat-error *ngIf="emailControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
            &nbsp;
            <mat-form-field class="fc125">
                <mat-label>{{ 'patients.phone' | translate }}</mat-label>
                <input matInput autocomplete="off"  [formControl]="phoneNumberControl" [errorStateMatcher]="matcher" />
                <!-- <mat-icon matSuffix>phone</mat-icon> -->
                <mat-error *ngIf="phoneNumberControl.hasError('required')">
                    Phone number is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="fc200">
                <mat-label>{{ 'patients.address' | translate }}</mat-label>
                <input matInput autocomplete="off"  [formControl]="addressControl" [errorStateMatcher]="matcher" />
                <mat-error *ngIf="addressControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
            &nbsp;
            <mat-form-field class="fc200">
                <mat-label>{{ 'patients.address2' | translate }}</mat-label>
                <input matInput autocomplete="off" [formControl]="address2Control"/>
            </mat-form-field>
            &nbsp;            
            <mat-form-field class="fc125">
                <mat-label>{{ 'patients.city' | translate }}</mat-label>
                <input matInput autocomplete="off" [formControl]="cityControl" [errorStateMatcher]="matcher" />
                <mat-error *ngIf="cityControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field *ngIf="provinces.length > 0" class="fc200">
                <mat-label>{{ 'patients.province' | translate }}</mat-label>
                <mat-select autocomplete="off"  [formControl]="provinceControl" required>
                    <mat-option *ngFor="let province of provinces" [value]="province.value">{{ language === 'fr' ? province.name_fr : province.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="provinceControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field> 
            &nbsp;
            <mat-form-field class="fc125">
                <mat-label>{{ 'patients.postalcode' | translate }}</mat-label>
                <input matInput autocomplete="off" [formControl]="postalCodeControl" [errorStateMatcher]="matcher" />
                <mat-error *ngIf="postalCodeControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
            &nbsp;
            <mat-form-field class="fc150">
                <mat-label>{{ 'patients.emrid' | translate }}</mat-label>
                <input matInput [formControl]="chartIdControl" [errorStateMatcher]="matcher" />
                <mat-error *ngIf="chartIdControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
        </div>
        <div class="subheader">{{ 'patients.healthinsurance' | translate }}</div> 
        <div>
            <mat-form-field>
                <mat-label>{{ 'patients.insuranceprovider' | translate }}</mat-label>
                <mat-select [formControl]="insuranceProviderControl" [compareWith]="compareInsuranceProviders">
                  <mat-option *ngFor="let insuranceProvider of insuranceProviders" [value]="insuranceProvider">{{ insuranceProvider.name }}</mat-option>
                </mat-select>
                <button *ngIf="insuranceProviderControl.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="insuranceProviderControl.setValue(null); $event.stopPropagation()">
          <mat-icon>close</mat-icon>
        </button>
              </mat-form-field>
              <ng-container *ngIf="insuranceProviderControl.value">
            &nbsp;           
             <mat-form-field class="fc175">
                <mat-label>{{ showPolicyLabel() }}</mat-label>
                <input matInput [formControl]="insurancePolicyControl" [errorStateMatcher]="matcher" required/>
                <mat-error *ngIf="insurancePolicyControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field> 
            </ng-container>
        <!-- <div>
            <mat-form-field class="fc700" required>
                <mat-label>{{ 'patients.insurancecriteria' | translate }}</mat-label>
                <mat-select [formControl]="insuranceCriteriaControl" multiple required>
                    <mat-option value="ramq">{{ 'patients.insurancetypeone' | translate }}</mat-option>
                    <mat-option value="ramqspecial">{{ 'patients.insurancetypetwo' | translate }}</mat-option>
                    <mat-option value="private">{{ 'patients.insurancetypethree' | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="insuranceCriteriaControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field> 
        </div> -->
        <ng-container *ngIf="privateRequired">
<!--            
            <mat-form-field class="fc175" >
                <mat-label>{{ 'patients.insuranceprovider' | translate }}</mat-label>
                <input matInput [formControl]="insuranceProviderControl" [errorStateMatcher]="matcher" [required]="privateRequired"/>
                <mat-error *ngIf="insuranceProviderControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
              &nbsp; -->  
              &nbsp;        
            <mat-form-field class="fc175">
                <mat-label>{{ 'patients.insurancecertificate' | translate }}</mat-label>
                <input matInput [formControl]="insuranceCertificateControl" [errorStateMatcher]="matcher" required />
                <mat-error *ngIf="insuranceCertificateControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
            &nbsp;           
            <mat-form-field class="fc175">
                <mat-label>{{ 'patients.insurancecarrier' | translate }}</mat-label>
                <input matInput [formControl]="insuranceCarrierControl" [errorStateMatcher]="matcher" required/>
                <mat-error *ngIf="insuranceCarrierControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
        </ng-container>
        </div>
        <ng-container *ngIf="privateRequired">
            <div>
                <mat-form-field class="fc300">
                    <mat-label>{{ 'patients.planmembername' | translate }}</mat-label>
                    <input matInput [formControl]="insurancePlanMemberNameControl" [errorStateMatcher]="matcher"/>
                    <mat-error *ngIf="insurancePlanMemberNameControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                </mat-form-field>
            </div>
        </ng-container>
        <!-- <mat-divider class="divider"></mat-divider>  -->
        <div class="subheader">{{ 'patients.notes' | translate }}</div>
        <div>
         
            <mat-form-field class="note">
              <!-- <mat-label>{{ 'patients.notes' | translate }}</mat-label> -->
              <textarea matInput class="note_field" [formControl]="notesControl"></textarea>
            </mat-form-field>
          </div>
    </form>
    
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- <button mat-flat-button color="warn"  [disabled]="!patientForm.valid"  *ngIf="showPublicSpecialPriorAuthorization()" (click)="createNBSA()">Create Medicare SA</button>
    <button mat-flat-button color="warn"  [disabled]="!patientForm.valid"  *ngIf="showPrivateSpecialPriorAuthorization()" (click)="createMBCSA()">Create Medavie Blue Cross SA</button> -->
    <!-- <button mat-flat-button color="warn"  [disabled]="!patientForm.valid"  *ngIf="showEyleaEnrollmentForm()" (click)="createEyeQEnrolment()">Create EyeQ Enrolment</button>
    <button mat-flat-button color="warn"  [disabled]="!patientForm.valid"  *ngIf="showLucentisEnrollmentForm()" (click)="createLucentisEnrolment()">Create Lucentis Enrolment</button>
    <button mat-flat-button color="warn"  [disabled]="!patientForm.valid"  *ngIf="showBeovuEnrollmentForm()" (click)="createBeovuEnrolment()">Create Beovu Enrolment</button> -->
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-button mat-dialog-close>{{ 'patients.cancel' | translate }}</button>
    <button mat-flat-button [disabled]="!patientForm.valid" (click)="addPatient()">{{ 'patients.save' | translate }}</button>
    <button mat-flat-button color="accent" [disabled]="!patientForm.valid" (click)="addPatient(false, true)">Save -> Add Consent</button>
    <button mat-flat-button color="primary" [disabled]="!patientForm.valid" (click)="addPatient(true, false)">{{ 'patients.saveorderrx' | translate }}</button>
  </mat-dialog-actions>