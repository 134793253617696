<div><mat-toolbar color="accent">
    <span>{{ 'orders.title' | translate }}</span>
    <span class="fill-space"></span>
</mat-toolbar>
<kendo-grid
[kendoGridBinding]="orders"
kendoGridSelectBy="id"
[pageSize]="15"
[pageable]="true"
[sortable]="{
    allowUnsort: true,
    mode: 'single'
}"
[filterable]="true"
[reorderable]="true"
[resizable]="true"
[columnMenu]="{ filter: true }"
[loading]="orderService.loading"
>
<kendo-grid-messages noRecords="{{ 'global.norecords' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
</kendo-grid-messages>
<!-- <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column> -->
<kendo-grid-column title="{{ 'orders.ordernumber' | translate }}" field="order_number" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem>
           <!--<button style="width: 30px !important;" mat-button color="warn" (click)="DeletePatient(dataItem.id)"><mat-icon>delete</mat-icon></button> -->
        <a mat-button [matMenuTriggerFor]="orderOptionMneu">{{ dataItem.order_number }}<mat-icon>expand_more</mat-icon></a>
        <mat-menu #orderOptionMneu="matMenu">
            <a mat-menu-item mat-menu-item *ngIf="dataItem.type === 'Rx'"  [routerLink]="buildLink(dataItem)"><mat-icon>visibility</mat-icon>{{ 'orders.view' | translate }}</a>
            <a mat-menu-item mat-menu-item *ngIf="dataItem.type === 'Rx' && dataItem.documentPdf" [href]="dataItem.documentPdf ? dataItem.documentPdf.url : ''" target="_blank"><mat-icon>summarize</mat-icon>{{ 'orders.summary' | translate }}</a>
            <a mat-menu-item mat-menu-item *ngIf="dataItem.type === 'Rx' && dataItem.documentPdf" (click)="downloadRxs(dataItem.order_number);"><mat-icon>download</mat-icon>Download All Rx</a>
            <a mat-menu-item mat-menu-item *ngIf="dataItem.type === 'Supply' && dataItem.documentPdf" [href]="dataItem.documentPdf ? dataItem.documentPdf.url : ''" target="_blank"><mat-icon>visibility</mat-icon>{{ 'orders.view' | translate }}</a>
        </mat-menu>
    </ng-template>
  </kendo-grid-column>
<kendo-grid-column field="type" title="{{ 'orders.ordertype' | translate }}" [width]="175"></kendo-grid-column>
<kendo-grid-column field="clinic_name" title="{{ 'clinic.clinicname' | translate }}" [width]="400"></kendo-grid-column>
<kendo-grid-column [filterable]="true" title="Reconciled" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
       {{ dataItem.reconciled ? 'Yes' : 'No'}}
    </ng-template>
</kendo-grid-column>
<kendo-grid-column [filterable]="true" title="{{ 'shell.injectionclinic.title' | translate }}" [width]="250">
    <ng-template kendoGridCellTemplate let-dataItem>
       <span *ngIf="dataItem.injectionclinic"><span *ngIf="dataItem.injectionclinic.name">{{ dataItem.injectionclinic.name }}</span> - {{ this.utility.formatDate(dataItem.injectionclinic.clinicDate, 'yyyy-MM-DD') }}</span>
    </ng-template>
</kendo-grid-column>
<!-- <kendo-grid-column field="createdBy.name" title="{{ 'orders.createdby' | translate }}"></kendo-grid-column> -->
<kendo-grid-column field="createdAt" [filterable]="false" title="{{ 'orders.createddate' | translate }}" [width]="190">
    <ng-template kendoGridCellTemplate let-dataItem>
        {{ this.utility.formatDate(dataItem.createdAt, 'yyyy-MM-DD HH:mm A') }}
    </ng-template>
</kendo-grid-column>
<kendo-grid-column field="expectedDeliveryDate" [filterable]="false" title="{{ 'orders.expecteddeliverydate' | translate }}" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.expectedDeliveryDate">
            {{ this.utility.formatDate(dataItem.expectedDeliveryDate, 'yyyy-MM-DD') }}
        </ng-container>
    </ng-template>
</kendo-grid-column>
<!-- <kendo-grid-column field="status" title="{{ 'orders.status' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span translate>{{ dataItem.status }}</span>
    </ng-template>
</kendo-grid-column> -->
<kendo-grid-column field="count" filter="numeric" title="{{ 'orders.rxcount' | translate }}" [width]="175"></kendo-grid-column>
<!-- <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="380">
<ng-template kendoGridCellTemplate let-dataItem>
    <button *ngIf="dataItem.type === 'Rx'" mat-button color="accent"  color="accent" [routerLink]="'/order-detail/' + dataItem.id + '/' + dataItem.order_number + '/order-report'">{{ 'orders.view' | translate }}</button>
    <a *ngIf="dataItem.type === 'Rx' && dataItem.documentPdf" mat-button color="accent"  color="accent" [href]="dataItem.documentPdf ? dataItem.documentPdf.url : ''" target="_blank">{{ 'orders.summary' | translate }}</a>
    <button *ngIf="dataItem.type === 'Rx' && dataItem.documentPdf" mat-button color="accent"   mat-button color="accent"  (click)="downloadRxs(dataItem.order_number);">Download All Rx</button>
    <a *ngIf="dataItem.type === 'Supply' && dataItem.documentPdf" mat-button color="accent"  color="accent" [href]="dataItem.documentPdf ? dataItem.documentPdf.url : ''" target="_blank">{{ 'orders.view' | translate }}</a>
</ng-template>
</kendo-grid-column> -->
</kendo-grid>
</div>