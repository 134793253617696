<div>
    <mat-toolbar color="accent">
        <span>{{ 'doctors.title' | translate }}</span>
        <span class="fill-space"></span>
        <button mat-flat-button (click)="openAddDoctorDialog()">{{ 'doctors.adddoctor' | translate }}</button>
    </mat-toolbar>
    <kendo-grid
        [kendoGridBinding]="doctors | async"
        kendoGridSelectBy="id"
        [pageSize]="15"
        [pageable]="true"
        [sortable]="{
            allowUnsort: true,
            mode: 'single'
        }"
        [sort]="sort"
        [groupable]="false"
        [reorderable]="true"
        [resizable]="true"
        [columnMenu]="{ filter: true }"
        [loading]="adminService.loading"
    >
    <kendo-grid-column field="last_name" title="{{ 'doctors.lastname' | translate }}" [groupable]="false"></kendo-grid-column>
    <kendo-grid-column field="first_name" title="{{ 'doctors.firstname' | translate }}" [groupable]="false"></kendo-grid-column>
    <kendo-grid-column field="license_number" title="{{ 'doctors.licensenumber' | translate }}" [groupable]="false"></kendo-grid-column>
    <kendo-grid-column field="language" title="{{ 'global.language' | translate }}" [groupable]="false"></kendo-grid-column>
    <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="300">
        <ng-template kendoGridCellTemplate let-dataItem>
            <!-- <button style="width: 30px !important;" mat-button color="primary" (click)="editUser(dataItem);"><mat-icon>edit</mat-icon></button> -->
            <button mat-button color="accent" (click)="updateDoctorSupplies(dataItem)">{{ 'global.supplies' | translate }}</button>
            <button mat-button color="primary" (click)="openAddDoctorDialog(dataItem)"><mat-icon>edit</mat-icon></button>
            <button mat-button color="warn" (click)="DeleteDoctor(dataItem)"><mat-icon>delete</mat-icon></button>
        </ng-template>
      </kendo-grid-column>
  </kendo-grid>
</div>

<ng-template #deleteConfirmDialog>
    <h2 matDialogTitle>{{ 'doctors.delete.title' | translate }}</h2>
    <mat-dialog-content>
        <div>
        {{ 'doctors.delete.message' | translate }} {{ deleteDoctorFullName }}
         </div>
         <div>
            <mat-form-field class="fc300">
                <mat-label>{{ 'doctors.name' | translate }}</mat-label>
                <input matInput [(ngModel)]="typedDoctorFullName" />
            </mat-form-field>   
         </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-flat-button color="warn" [disabled]="deleteDoctorFullName !== typedDoctorFullName" matDialogClose="yes">{{ 'global.delete' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>

  <ng-template #addDialog>
    <h2 matDialogTitle class="title"><span *ngIf="!this.selectedDoctor">{{ 'doctors.adddoctor' | translate }}</span><span *ngIf="this.selectedDoctor">Dr {{ this.selectedDoctor.full_name }}</span></h2>
    <mat-dialog-content>
         <div>
            <form [formGroup]="doctorForm">
                    <div>
                        <mat-form-field class="fc150">
                            <mat-label>{{ 'doctors.firstname' | translate }}</mat-label>
                            <input matInput cdkFocusInitial [formControl]="firstNameControl" required />
                            <mat-error *ngIf="firstNameControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field> 
                          &nbsp;
                          <mat-form-field class="fc150">
                            <mat-label>{{ 'doctors.lastname' | translate }}</mat-label>
                            <input matInput cdkFocusInitial [formControl]="lastNameControl" required />
                            <mat-error *ngIf="lastNameControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field> 
                        &nbsp;                       
                       <mat-form-field class="fc125">
                            <mat-label>{{ 'doctors.licensenumber' | translate }}</mat-label>
                            <input matInput [formControl]="licenseNumberControl" [errorStateMatcher]="matcher" required/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="licenseNumberControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                   </div>
                   <div>
                        <mat-form-field class="fc125" >
                            <mat-label>{{ 'global.language' | translate }}</mat-label>
                            <mat-select [formControl]="languageControl" [errorStateMatcher]="matcher" required>
                              <mat-option value="fr">Français</mat-option>
                              <mat-option value="en">English</mat-option>
                            </mat-select>
                            <mat-error *ngIf="languageControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field>
                          &nbsp;
                          <mat-form-field class="fc150">
                            <mat-label>{{ 'global.phone' | translate }}</mat-label>
                            <input matInput [formControl]="phoneNumberControl" [errorStateMatcher]="matcher" required/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="phoneNumberControl.hasError('required')">
                                {{ 'global.required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="phoneNumberControl.hasError('pattern')">
                                {{ 'global.invalidphone' | translate }}
                            </mat-error>
                        </mat-form-field>
                        &nbsp;                        <mat-form-field class="fc150">
                            <mat-label>{{ 'global.fax' | translate }}</mat-label>
                            <input matInput [formControl]="faxNumberControl" [errorStateMatcher]="matcher" required/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="faxNumberControl.hasError('required')">
                                {{ 'global.required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="faxNumberControl.hasError('pattern')">
                                {{ 'global.invalidphone' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div style="border-bottom: 2px dashed black;">
                      <signature-pad [id]="'d0'" [options]="{'minWidth': 2, 'canvasWidth': 550,'canvasHeight': 200 }"
                          (onBeginEvent)="drawStart(0)" (onEndEvent)="drawComplete(0)"></signature-pad>
                  </div>
            </form>
         </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="warn" [disabled]="!signature" (click)="clearSignature(0);">{{ 'rx.clearsignature' | translate }} </button>
        <button mat-button matDialogClose="no">{{ 'global.cancel' | translate }}</button>
        <button mat-flat-button color="accent" [disabled]="!doctorForm.valid" matDialogClose="yes" (click)="saveDoctor()">{{ 'global.save' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>

  <ng-template #doctorSuppliesDialog>
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle class="title">{{ 'doctors.supplies.title' | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      <mat-selection-list (selectionChange)="supplyChange($event)">
        <ng-container *ngFor="let supply of doctorSupplies; let supplyIdx = index">
            <h2 *ngIf="categoryDivider(supply.category)">{{ supply.category }}</h2>
            <mat-list-option [selected]="supply.doctors ? supply.doctors.includes(this.selectedDoctor.id) : false" [value]="supply">
            {{supply.name}}
        </mat-list-option>
        </ng-container>
     </mat-selection-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button cdkFocusInitial mat-button mat-dialog-close>{{ 'global.finished' | translate }}</button>
    </mat-dialog-actions>
</ng-template>