<div>
  <mat-toolbar color="accent">
    <span>{{ 'batch.title' | translate }}</span>
    <span class="fill-space"></span>
    <button *ngIf="order.length > 0" mat-flat-button (click)="openSubmitOrderDialog()">{{ 'batch.submit' | translate
      }}</button>&nbsp;
    <button *ngIf="order.length > 0" mat-flat-button (click)="deleteBatch()" color="warn">{{ 'batch.delete' | translate
      }}</button>
  </mat-toolbar>
  <kendo-grid [kendoGridBinding]="order" [filterable]="true" [groupable]="true" [group]="groups" (groupChange)="groupChange($event)" [reorderable]="true"
    [resizable]="true" [columnMenu]="{ filter: true }" [loading]="orderService.loading"
    kendoGridSelectBy="id"
    [pageSize]="15"
    [pageable]="{
      buttonCount: 10,
      info: true,
      pageSizes: [15, 50, 100, 1000],
      previousNext: true
    }"
    [sortable]="{
      allowUnsort: true,
      mode: 'single'
    }"
    [sort]="sort" 
    >
    <kendo-grid-messages noRecords="{{ 'global.norecords' | translate }}" pagerOf=":"
      pagerItems="{{ 'global.items' | translate }}">
    </kendo-grid-messages>
    <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="190">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button mat-button color="accent"  (click)="viewRx(dataItem)">{{ 'orders.view' | translate }}</button>&nbsp;
        <button mat-button color="warn" (click)="deleteRx(dataItem.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="patient.full_name" title="{{ 'patients.title' | translate }}" [width]="240"></kendo-grid-column>
    <kendo-grid-column field="patient.dob" title="{{ 'patients.dob' | translate }}" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.patient.dob }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="doctor.full_name" title="{{ 'doctors.title' | translate }}" [width]="200">
      <ng-template kendoGridGroupHeaderTemplate let-value="value" let-aggregates="aggregates" let-field="field">
        <strong>{{ value }}</strong>: {{aggregates[field]?.count}}
      </ng-template>
    </kendo-grid-column>
    <ng-container *ngIf="user">
      <kendo-grid-column *ngIf="user.clinic.requiresAppointmentTime" [filterable]="false" field="injectiondate" [format]="{ date: 'short' }" [sortable]="true"  title="{{ 'rx.time' | translate }}" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.injectiondate | date: 'hh:mm a' }}</span>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
    <kendo-grid-column field="medication_name" title="{{ 'rx.medication' | translate }}" [width]="150">
      <ng-template kendoGridGroupHeaderTemplate let-value="value" let-aggregates="aggregates" let-field="field">
        <strong>{{ value }}</strong>: {{aggregates[field]?.count}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="patients.diagnosis" title="{{ 'patients.diagnosis' | translate }}" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span translate>{{ dataItem.patient.diagnosis }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="eye" title="{{ 'rx.eye' | translate }}" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span translate>{{ dataItem.eye }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column filter="numeric" field="quantity_numeric" title="{{ 'rx.qty' | translate }}">
    </kendo-grid-column>
  </kendo-grid>
</div>


<ng-template #callDeleteConfirmDialog>
  <h2 matDialogTitle>{{ 'global.areyousure.title' | translate }}</h2>
  <mat-dialog-content>
      {{ 'global.areyousure.message' | translate }}
  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
      <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #orderSubmitConfirmDialog>
  <h2 matDialogTitle>{{ 'rx.dialog.sendorder.title' | translate }}</h2>
  <mat-dialog-content>
    {{ 'rx.dialog.sendorder.message' | translate }}
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
    <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #submitDialog>
  <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle class="title">{{ 'rx.sendorder' | translate }}</h2>
  <mat-dialog-content class="mat-typography">
    <mat-horizontal-stepper linear #stepper>
      <mat-step label="{{ 'rx.ordersummary' | translate }}" completed="false">
        <div>
          {{'rx.ordersummarymessage' | translate}}
        </div>
        <div>
          <ul class="summary">
            <li *ngFor="let medication of summary">
              <span class="summary_label" *ngIf="language === 'fr'">{{ medication.name }}</span>
              <span class="summary_label" *ngIf="language === 'en'">{{ medication.name }}</span>
              <span class="summary_quantity">{{ medication.quantity}}&nbsp;&nbsp;-&nbsp;&nbsp;{{ medication.count }} {{'rx.prescriptions' | translate}}</span>
            </li>
            <li class="total_row">
              <span class="summary_label">{{ 'global.total' | translate }}</span>
              <span class="summary_quantity">{{ totalQuantity }}</span>
            </li>
          </ul>
        </div>
        <div class="button_container" align="end">
          <button cdkFocusInitial mat-button (click)="nextClicked($event)">{{ 'rx.next' | translate }}</button>
          </div>
      </mat-step>

      <ng-container *ngFor="let doctor of orderdoctors; let i = index">
        <mat-step label="Dr {{ doctor.last_name }}" completed="false">
          <div *ngIf="language === 'fr'" class="subheader">{{ 'rx.doctorsignature' | translate }} Dr {{ doctor.last_name
            }} </div>
          <div *ngIf="language === 'en'" class="subheader">Dr {{ doctor.last_name }}'s {{ 'rx.doctorsignature' |
            translate }}</div>
          <div style="border-bottom: 2px dashed black;">
            <signature-pad [id]="'d' + i" [options]="{'minWidth': 2, 'canvasWidth': 550,'canvasHeight': 200 }" 
              (onBeginEvent)="drawStart(i)" (onEndEvent)="drawComplete(i)"></signature-pad>
          </div>
          <div class="button_container" align="end">
            <button mat-button color="warn" (click)="clearSignature(i);">{{ 'rx.clearsignature' | translate }} </button>
            <button mat-button matStepperPrevious>{{ 'rx.back' | translate }}</button>
            <button [disabled]="!doctor.signature" cdkFocusInitial mat-button (click)="nextClicked($event)">{{ 'rx.next'
              | translate }}</button>
          </div>
        </mat-step>
      </ng-container>

      <mat-step label="Default Doctor Supplies" completed="false">
        <div>
          <mat-selection-list (selectionChange)="selectionChange($event)">
            <ng-container *ngFor="let docSupply of doctorSuppliesList; let docIdx = index">
            <h3 matSubheader color="accent">Dr {{ docSupply.doctor.full_name }} Default Supplies</h3>
            <mat-list-option *ngFor="let supply of docSupply.supplies; let supplyIdx = index" [selected]="supply.selected" [value]="docIdx + '-' + supplyIdx">
              {{supply.name}}
            </mat-list-option>
            <mat-divider></mat-divider>
            </ng-container>
         </mat-selection-list>
        </div>
        <div class="button_container" align="end">
          <button mat-button matStepperPrevious>{{ 'rx.back' | translate }}</button>
          <button mat-button (click)="nextClicked($event)">{{ 'rx.next' | translate }}</button>
        </div>
      </mat-step>

      <mat-step label="{{ 'rx.sendorder' | translate }}" completed="false">
        <div class="subheader">{{ 'rx.sendorder' | translate }}</div>
        <div>{{ 'rx.sendordermessage' | translate }}</div>
        <!-- <mat-divider class="divider"></mat-divider> -->
        <form [formGroup]="orderForm">
          <div>
            <mat-form-field class="fc300">
              <mat-label>{{ 'rx.pharmacy' | translate }}</mat-label>
              <mat-select [formControl]="faxControl" required [compareWith]="comparePharmacies">
                <mat-option *ngFor="let pharmacy of pharmacies" [value]="pharmacy">{{ pharmacy.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="faxControl.hasError('required')"
                [innerHTML]="'rx.errors.faxnumberrequired' | translate"></mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="fc200">
              <mat-label>{{ 'rx.faxnumberother' | translate }}</mat-label>
              <input matInput [formControl]="faxnumberOtherControl" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="faxnumberOtherControl.hasError('pattern')"
                [innerHTML]="'rx.errors.faxnumberotherpattern' | translate"></mat-error>
            </mat-form-field> -->
          </div>
          <div>
            <mat-form-field class="fc200">
              <input matInput [formControl]="expectedDeliveryDateControl" [matDatepicker]="edd" [errorStateMatcher]="matcher" placeholder="{{ 'rx.expecteddeliverydate' | translate}}">
              <mat-datepicker-toggle matSuffix [for]="edd"></mat-datepicker-toggle>
              <mat-datepicker #edd disabled="false"></mat-datepicker>
              <mat-error *ngIf="expectedDeliveryDateControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="note">
              <mat-label>{{ 'rx.notes' | translate }}</mat-label>
              <textarea matInput class="note_field" [formControl]="notesControl"></textarea>
            </mat-form-field>
          </div>
        </form>
        <div class="button_container" align="end">
          <button mat-button matStepperPrevious>{{ 'rx.back' | translate }}</button>
          <button mat-button color="accent" [disabled]="!orderForm.valid" (click)="submitOrder()">{{ 'rx.send' |
            translate }}</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ 'batch.cancel' | translate }}</button>
   </mat-dialog-actions> -->
</ng-template>

<ng-template #viewDialog>
  <h2 mat-dialog-title class="title" *ngIf="user">{{ selectedRx.patient.first_name }} {{ selectedRx.patient.last_name }}</h2>
<mat-dialog-content class="mat-typography" *ngIf="language">

    <!-- <img class="logo" [src]="logo"> -->
    <div class="patient">Dr {{ selectedRx.doctor.full_name }}<span *ngIf="selectedRx.location"> - {{ selectedRx.location?.name }}</span></div>
    <div class="patient">{{ selectedRx.patient.first_name }} {{ selectedRx.patient.last_name }} - {{ selectedRx.patient.dob }} - {{ ( selectedRx.patient.gender === 'MALE' || selectedRx.patient.gender === 'M' ? 'patients.male' : 'patients.female' ) | translate }}</div>
    <ng-container *ngIf="selectedRx.patient.insurance_provider">
      <div *ngIf="selectedRx.patient.insurance_provider.type === 'Public'" class="healthcard">{{ selectedRx.patient.insurance_provider.name }} - {{ selectedRx.patient.healthcard}}</div>
      <div *ngIf="selectedRx.patient.insurance_provider.type === 'Private'" class="healthcard">{{ selectedRx.patient.insurance_provider.name }}<br/>{{ 'patients.insurancepolicy' | translate }}: {{ selectedRx.patient.insurance_policy }}<span *ngIf="selectedRx.patient.insurance_certificate"> - {{ 'patients.insurancecertificate' | translate }}: {{ selectedRx.patient.insurance_certificate }}</span><span *ngIf="selectedRx.patient.insurance_carrier"> - {{ 'patients.insurancecarrier' | translate }}: {{ selectedRx.patient.insurance_carrier }}</span></div>
    </ng-container>
    <div class="address">
      <div *ngIf="selectedRx.patient.address">{{ selectedRx.patient.address }}</div>
      <div *ngIf="selectedRx.patient.city || selectedRx.patient.province"><span *ngIf="selectedRx.patient.city">{{ selectedRx.patient.city }}</span><span *ngIf="selectedRx.patient.city && selectedRx.patient.province">, </span><span *ngIf="selectedRx.patient.province">{{ selectedRx.patient.province }}</span></div>
      <div *ngIf="selectedRx.patient.postal_code">{{ selectedRx.patient.postal_code }}</div>
      <div *ngIf="selectedRx.patient.phone_number">{{ selectedRx.patient.phone_number }}</div>
    </div>
    <div>{{ selectedRx.medication.name }} - {{ selectedRx.eye }} - {{ selectedRx.quantity }}</div>
    <!-- <div>
      <mat-checkbox color="primary" [formControl]="tobraControl">Tobramycin 1-2 GTTS AE Q4H x 3D POST IN</mat-checkbox>
    </div> -->
    <div class="patient">{{ this.utility.formatDate(selectedRx.appointmentDate, 'yyyy-MM-DD') }} <span *ngIf="selectedRx.appointmentTime">{{ selectedRx.injectiondate | date: 'hh:mm a' }}</span></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'global.close' | translate }}</button>
</mat-dialog-actions>
</ng-template>