<mat-dialog-content class="mat-typography">
    <!-- <img class="logo" [src]="logo"> -->
    <form [formGroup]="rxForm">
    <div *ngIf="user" class="clinic">{{ user.clinic_name }}</div>
    <div class="doctor">Dr. {{ last_rx.doctor.first_name }} {{ last_rx.doctor.last_name }}</div>
    <div class="patient">{{ patient.first_name }} {{ patient.last_name }} - {{ patient.dob }} -
        {{ ( patient.gender === 'MALE' || patient.gender === 'M' ? 'patients.male' : 'patients.female' ) | translate }} - {{ patient.diagnosis }}</div>
    <ng-container *ngIf="patient.insurance_provider">
      <div *ngIf="patient.insurance_provider.type === 'Public'" class="healthcard">{{ patient.insurance_provider.name }} - {{ patient.healthcard}}</div>
      <div *ngIf="patient.insurance_provider.type === 'Private'" class="healthcard">{{ patient.insurance_provider.name }}<br/>{{ 'patients.insurancepolicy' | translate }}: {{ patient.insurance_policy }}<span *ngIf="patient.insurance_certificate"> - {{ 'patients.insurancecertificate' | translate }}: {{ patient.insurance_certificate }}</span><span *ngIf="patient.insurance_carrier"> - {{ 'patients.insurancecarrier' | translate }}: {{ patient.insurance_carrier }}</span></div>
    </ng-container>
    <div class="address">
      <div *ngIf="patient.address">{{ patient.address }}</div>
      <div *ngIf="patient.city || patient.province"><span *ngIf="patient.city">{{ patient.city }}</span><span *ngIf="patient.city && patient.province">, </span><span *ngIf="patient.province">{{ patient.province }}</span></div>
      <div *ngIf="patient.postal_code">{{  patient.postal_code }}</div>
      <div *ngIf="patient.phone_number">{{ patient.phone_number }}</div>
    </div>
    <div class="medication">{{ last_rx.medication.name }} - {{ last_rx.eye }} - {{ last_rx.quantity }} <span
            *ngIf="last_rx.lucode"> - {{ last_rx.lucode }}</span></div>
    <div *ngIf="last_order_date" class="lastorderdate"><strong>{{ 'rx.lastordered' | translate }}: </strong>{{ last_order_date }}</div>
    <!-- <div *ngIf="last_rx.tobra" class="medication">Plus: {{ last_rx.tobra }}</div> -->
      <div>
        <mat-form-field class="fc200">
          <input matInput [formControl]="appointmentDateControl" [matDatepicker]="ad" placeholder="{{ 'rx.appointmentdate' | translate}}">
          <mat-datepicker-toggle matSuffix [for]="ad"></mat-datepicker-toggle>
          <mat-datepicker #ad disabled="false"></mat-datepicker>
        </mat-form-field>
        <!-- &nbsp;
      <mat-form-field class="fc200">
        <input matInput [formControl]="expectedDeliveryDateControl" [matDatepicker]="edd" placeholder="{{ 'rx.expecteddeliverydate' | translate}}">
        <mat-datepicker-toggle matSuffix [for]="edd"></mat-datepicker-toggle>
        <mat-datepicker #edd disabled="false"></mat-datepicker>
      </mat-form-field> -->
  </div>
      <div>
        <mat-form-field class="note">
          <mat-label>{{ 'rx.notes' | translate }}</mat-label>
          <textarea matInput class="note_field" [formControl]="notesControl">{{ last_rx.notes }}</textarea>
        </mat-form-field>
      </div>
<!-- 
    <div class="doctor_signature">
        <img class="signature" [src]="last_rx.doctor.signature" />
        <div class="doctor">Dr. {{ last_rx.doctor.first_name }} {{ last_rx.doctor.last_name }} - Prescriber Signature
        </div>
        <div class="datestamp">{{ datestamp }}</div>
    </div> -->
    <ng-container *ngIf="appSettings">
      <div class="disclaimer_container" *ngIf="appSettings.showDisclaimer">
        <div class="disclaimer" *ngFor="let item of appSettings.rxDisclaimer">
          <p *ngIf="item.type ==='p'">{{ language === 'fr' ? item.value_fr : item.value }}</p>
          <ul *ngIf="item.type ==='l'">
            <ng-container *ngIf="language === 'en'">
              <li *ngFor="let li of item.value">{{ li }} </li>
            </ng-container>
            <ng-container *ngIf="language === 'fr'">
              <li *ngFor="let li of item.value_fr">{{ li }} </li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="appSettings.rxSubstitution">
          <ng-container *ngIf="language === 'en'">
            {{ appSettings.rxSubstitution }}
          </ng-container>
          <ng-container *ngIf="language === 'fr'">
            {{ appSettings.rxSubstitution_fr }}
          </ng-container>
        </div>
      </div>
    </ng-container>
  </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'rx.cancel' | translate }}</button>
    <button mat-button color="accent" [disabled]="!rxForm.valid" (click)="reorderRx()">{{ 'rx.addrxtobatch' | translate }}</button>
  </mat-dialog-actions>