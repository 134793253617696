<div class="bounds">
  <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
    <div fxFlex="100" fxLayoutAlign="center center" class="sec1">
      <!-- <div>
        <img src="../../assets/images/logo.png" style="min-width: 200px; max-width: 80%;">
      </div> -->
      <mat-card>
        <img src="../../assets/images/sentrex_new_header_logo_500.png" style="min-width: 200px; max-width: 80%;">
        <!-- <mat-card-header>
          <mat-card-title class="large_text">

          </mat-card-title>
        </mat-card-header> -->
        <div style="padding: 10px;">
          <h1>
            {{ 'login.welcome' | translate }}
          </h1>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <mat-form-field  appearance="outline">
            <mat-label>{{ 'login.email' | translate }}</mat-label>
            <input matInput formControlName="email" type="email" placeholder="{{ 'login.email' | translate }}@{{ 'login.email' | translate }}.com"
              autocomplete="off" />

            <mat-error *ngIf="email?.invalid && email?.dirty">
              {{ 'login.emailerror' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field [color]="email?.valid && 'accent'"  appearance="outline">
            <mat-label>{{ 'login.password' | translate }}</mat-label>
            <input matInput formControlName="password" type="password"
              placeholder="{{ 'login.password' | translate }}" autocomplete="off" />

            <mat-error *ngIf="password?.invalid && password?.dirty">
              {{ 'login.passworderror' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-error class="server-error">{{ serverMessage }}</mat-error>

          <button style="width: 100% !important;" mat-raised-button color="accent" type="submit" [disabled]="form.invalid || loading">
            {{ 'login.signin' | translate }}
          </button>
        </form>
        <div class="language_container"> <button mat-button color="primary"
            (click)="chooseLanguage('en')">English</button> | <button mat-button color="primary"
            (click)="chooseLanguage('fr')">Français</button>
            <!-- | <button mat-button color="primary"
            (click)="updateTemporaryPassword()">Temp password</button> -->
          </div>
          <div>
            <button mat-button color="accent"
            (click)="resetPassword()">Reset Password</button>
          </div>
          <div>
            {{ env }} {{ version }}
          </div>
      </mat-card>
    </div>
</div>
</div>  

<ng-template #resetPasswordDialog>
  <h2 matDialogTitle>{{ 'global.resetpassword.title' | translate }}</h2>
  <mat-dialog-content>
      <div>
      {{ 'global.resetpassword.message' | translate }}
       </div>
       <div>
          <mat-form-field class="fc150">
              <mat-label>{{ 'login.email' | translate }}</mat-label>
              <input matInput cdk [(ngModel)]="emailAddress" />
          </mat-form-field>   
       </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-flat-button color="accent" [disabled]="!emailAddress" matDialogClose="yes">{{ 'global.sendrequest' | translate }}</button>
  </mat-dialog-actions>
</ng-template>