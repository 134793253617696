import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataBindingDirective, GridDataResult } from '@progress/kendo-angular-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { SignaturePad } from 'angular2-signaturepad';
import { Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Roles, User } from 'src/app/shared/models/user';
import { AdminService } from '../../admin-service.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss']
})
export class DoctorListComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('deleteConfirmDialog') deleteConfirmDialog: TemplateRef<any>;
  @ViewChild('addDialog') addDialog: TemplateRef<any>;
  @ViewChild('doctorSuppliesDialog') doctorSuppliesDialog: TemplateRef<any>;

  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;
  
  ngUnsubscribe: Subject<void> = new Subject();
  public doctors: any;
  language: string;
  public gridView: any;
  user: User = <User>{};
  roles: Roles = <Roles>{};
  loading: boolean = false;
  isSavingUser: boolean = false;
  isEdit: boolean = false;
  addDialogRef: any;
  public mySelection: string[] = [];
  public sort: SortDescriptor[] = [
    {
        field: 'last_name',
        dir: 'asc'
    },
    {
      field: 'first_name',
      dir: 'asc'
    }
  ];
  private editedRowIndex: number;

  public deleteDoctorFullName: string;
  public typedDoctorFullName: string;
  doctorSupplies: any[] = [];
  selectedDoctor: Doctor;
  lastCategory: string;
  signature: string;

  phoneregex: RegExp = /^\+[1-9]\d{10,14}(\se?x?t?(\d*))?$/
  postalcoderegex: RegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

  doctorForm: FormGroup;
  firstNameControl = new FormControl('', [Validators.required,]);
  lastNameControl = new FormControl('', [Validators.required,]);
  licenseNumberControl = new FormControl(null, [Validators.required]);
  languageControl = new FormControl('fr', [Validators.required]);
  faxNumberControl = new FormControl('', [Validators.required,Validators.pattern(this.phoneregex)]);
  phoneNumberControl = new FormControl('', [Validators.required,Validators.pattern(this.phoneregex)]);

  matcher = new MyErrorStateMatcher();

  constructor(
    private auth: AuthService,
    public adminService: AdminService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private utility: UtilityService,
    private spinner: SpinnerService
    ) { }

  ngOnInit() {
    this.doctorForm = new FormGroup({
      first_name: this.firstNameControl,
      last_name: this.lastNameControl,
      license_number: this.licenseNumberControl,
      language: this.languageControl,
      phone_number: this.phoneNumberControl,
      fax_number: this.faxNumberControl,
    })
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.language = this.translate.currentLang;
    this.doctors = this.adminService.getDoctors(this.user.clinic_id).pipe(takeUntil(this.ngUnsubscribe));
  }

  ngOnDestroy() {
    // console.log('Destroy User View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  DeleteDoctor(doctor: Doctor) {
    this.deleteDoctorFullName = doctor.full_name;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    let dialogRef = this.matDialog.open(this.deleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              if (this.deleteDoctorFullName === this.typedDoctorFullName) {
                this.spinner.spin$.next(true);
                this.adminService.deleteDoctor(this.user.clinic_id, doctor.id).then(() => {
                  this.utility.showSnackBar(this.translate.instant('doctors.delete.success'), 3000, 'center', 'top');
                })
                .catch((err) => {
                  this.utility.showSnackBar(this.translate.instant('doctors.error.delete'), 3000, 'center', 'top');
                })
                .finally(() => {
                  this.deleteDoctorFullName = null;
                  this.typedDoctorFullName = null;
                  this.spinner.spin$.next(false);
                })
              } else {
                this.deleteDoctorFullName = null;
                this.typedDoctorFullName = null;
              }
            } 
        }
    })
  }

  openAddDoctorDialog(doctor?: Doctor) {
    this.firstNameControl.setValue(null);
    this.lastNameControl.setValue(null);
    this.licenseNumberControl.setValue(null);
    this.phoneNumberControl.setValue(null);
    this.faxNumberControl.setValue(null);
    if (doctor) { 
      this.isEdit = true;
      this.selectedDoctor = doctor;
      this.doctorForm.patchValue(doctor);
    } else {
      this.isEdit = false;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '750px';
    this.addDialogRef = this.matDialog.open(this.addDialog, dialogConfig);
    this.addDialogRef.afterOpened().subscribe(result => {
      if (doctor) this.setExistingSignature(0, doctor.signature);
    });
    this.addDialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result !== 'yes') {
              
            } 
        }
    })
  }

  saveDoctor() {
    this.isSavingUser = true;
    this.spinner.spin$.next(true);

      let newDoctor: Doctor = <Doctor>{};
      const d = this.doctorForm.value;
      if (this.selectedDoctor) newDoctor.id = this.selectedDoctor.id;
      newDoctor.first_name = d.first_name;
      newDoctor.last_name = d.last_name;
      newDoctor.full_name = d.first_name + ' ' + d.last_name;
      newDoctor.license_number = d.license_number;
      newDoctor.fax_number = d.fax_number;
      newDoctor.phone_number = d.phone_number;
      newDoctor.language = d.language;
      newDoctor.signature = this.signature ? this.signature : null;
      this.adminService.setDoctor(this.user, newDoctor)
      .then(() => {
        this.utility.showSnackBar(this.translate.instant('doctors.addsuccess'), 2000, 'center', 'top');
        this.isSavingUser = false;
        this.selectedDoctor = null;
        this.isEdit = false;
        this.languageControl.setValue(this.translate.defaultLang);
        this.addDialogRef.close();
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant('doctors.error.add'), 2000, 'center', 'top');
      })
      .finally(() => {
        this.isSavingUser = false;
        this.spinner.spin$.next(false);
      })
  }

  updateDoctorSupplies(doctor: Doctor)  {
    this.selectedDoctor = doctor;
    this.adminService.getClinicSuppliesList(this.user.clinic_id)
    .pipe(
      take(1)
    )
    .subscribe(supplies => {
      this.doctorSupplies = supplies;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = '800px';
      let dialogRef = this.matDialog.open(this.doctorSuppliesDialog, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {});
    })
  }

  supplyChange(change: MatSelectionListChange) {
    let supply = change.option.value;
    if (change.option.selected) {
      if(supply.doctors) {
        supply.doctors.push(this.selectedDoctor.id);
      }
      else {
        supply['doctors'] = [];
        supply.doctors.push(this.selectedDoctor.id);
      }
    } else {
      if(supply.doctors)  {
        const index: number = supply.doctors.indexOf(this.selectedDoctor.id, 0);
        if (index > -1) {
          supply.doctors.splice(index, 1);
       }
      }
      if(supply.doctors)  {
        const index: number = supply.doctors.indexOf(this.selectedDoctor.id, 0);
        if (index > -1) {
          supply.doctors.splice(index, 1);
        }
      }
    }
    this.adminService.updateSupply(supply)
    .then(()=>{})
    .catch((err) => {});
  }

  categoryDivider(category: string) {
    if (this.lastCategory !== category) {
      this.lastCategory = category;
      return true;
    } else {
      this.lastCategory = category;
      return false;
    }
  }

  clearSignature(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.clear();
    this.signature = null;
  }

  setExistingSignature(index, signature: string) {
    let signaturePad = this.signaturePads.toArray()[index];
    this.signature = signature;
    signaturePad.fromDataURL(signature);
  }

  drawStart(index) {
    // console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    // console.log("Ended drawing on signature", index);
    let signaturePad = this.signaturePads.toArray()[index];
    if(!signaturePad.isEmpty())  {
      this.signature = signaturePad.toDataURL();
    }
  }
}
