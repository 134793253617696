<div>
    <mat-toolbar color="accent">
        <span>Utility</span>
        <span class="fill-space"></span>
        <!-- <button mat-flat-button (click)="AddPatient()">Utility</button> -->
    </mat-toolbar>
<div class="viewPadding">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="App Settings">
            <div>
            <mat-form-field  style="width: 40%;" appearance="fill">
                <mat-label>Current Settings</mat-label>
                <textarea rows="20" matInput>{{ settings }}</textarea>
              </mat-form-field>&nbsp;
              <mat-form-field style="width: 40%;" appearance="fill"> 
                <mat-label>New Settings</mat-label>
                <textarea rows="20" matInput placeholder="New settings" [(ngModel)]="newsettings" [name]="newsettings"></textarea>
            </mat-form-field>
        </div>
        <button mat-flat-button [disabled]="!newsettings" color="warn" (click)="saveSettings()">Save</button>
        </mat-tab>
        <mat-tab label="Document Processing">
            <div style="padding: 10px;">
            <div>
                <mat-form-field class="fc250">
                    <mat-label>Document Path</mat-label>
                    <input matInput [(ngModel)]="documentpath" [name]="documentpath" />
                </mat-form-field>
                <button mat-button color="accent" (click)="getDocument()">Get Document</button>
            </div>
            <mat-form-field  style="width: 40%;" appearance="fill">
                <mat-label>Current Document</mat-label>
                <textarea rows="20" matInput>{{ document }}</textarea>
              </mat-form-field>
            <div>
                <mat-form-field class="fc250">
                    <mat-label>Document Path</mat-label>
                    <input matInput [(ngModel)]="newdocumentpath" [name]="newdocumentpath" />
                </mat-form-field>
                <button mat-button color="warn" (click)="saveDocument()">Save Document</button>
            </div>
            <div>
              <mat-form-field style="width: 40%;" appearance="fill"> 
                <mat-label>New Document</mat-label>
                <textarea rows="20" matInput placeholder="New settings" [(ngModel)]="newdocument" [name]="newdocument"></textarea>
            </mat-form-field>
        </div>
        </div>
        </mat-tab>
        <!-- <mat-tab label="Third">Content 3</mat-tab> -->
      </mat-tab-group>
      </div>

      <ng-template #saveDialog>
        <h2 matDialogTitle>{{ 'global.areyousure.title' | translate }}</h2>
        <mat-dialog-content>
            <div>
            {{ 'global.areyousure.message' | translate }}
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
            <button mat-flat-button color="warn" matDialogClose="yes">{{ 'global.yes' | translate }}</button>
        </mat-dialog-actions>
      </ng-template>
    </div>