import { Component, OnDestroy, OnInit, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DataResult, GroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { first, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Rx } from 'src/app/shared/models/rx';
import { User } from 'src/app/shared/models/user';
import { OrderService } from '../../order.service';
import { RxAddComponent } from '../../rx/rx-add/rx-add.component';
import { SignaturePad } from 'angular2-signaturepad';
import { MatStepper } from '@angular/material/stepper';
import { CreatedBy, RxOrder } from 'src/app/shared/models/order';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from 'src/app/shared/models/patient';
import { PatientService } from 'src/app/patient/patient.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DateTime } from 'luxon';
import * as moment from "moment";

const createFormGroup = (dataItem) =>
  new FormGroup({
    name: new FormControl(dataItem.name),
    id: new FormControl(dataItem.id, Validators.required),
  });
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  @ViewChild('callDeleteConfirmDialog') callDeleteConfirmDialog: TemplateRef<any>;
  @ViewChild('orderSubmitConfirmDialog') orderSubmitConfirmDialog: TemplateRef<any>;
  @ViewChild('updateRxDialog') updateRxDialog: TemplateRef<any>;
  @ViewChild('submitDialog') submitDialog: TemplateRef<any>;
  @ViewChild('confirmSendUpdateDialog') confirmSendUpdateDialog: TemplateRef<any>;
  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;
  @ViewChild('stepper') stepper: MatStepper;

  @ViewChild('patientConsentDialog') patientConsentDialog: TemplateRef<any>;


  user: User;
  export: any;
  order: any[] = [];
  orderdoctors: Doctor[] = [];
  submitDialogRef: any;
  updateRxDialogRef: any;
  confirmSendUpdateDialogRef: any;
  selectedRx: Rx;

  alldata: ExcelExportData;

  language: string;
  doctors: any[] = [];
  locations: Location[] = [];
  appSettings: any;
  datestamp: any;
  medications: any[] = [];
  lucodes: string[] = [];
  eyes: any[] = [];
  noshow: boolean;

  selectedPatient: Patient;
  selectedRxId: string;
  signature: string;
  termsone: any;
  termstwo: any;
  termsthree: any;
  termsfour: any;
  termsfive: any;
  consentDialogRef: any;

  orderId: string;
  orderNumber: string;
  orderSummaryUrl: string;
  returnModule: string;
  returnUrl: string = '/order-list'
  orderInjectionClinicDoctor: string;
  orderInjectionClinicDate: string;
  reconciled = false;

  rxForm: FormGroup;
  // doctorControl = new FormControl(null);
  medicationControl = new FormControl(null);
  eyeControl = new FormControl(null);

  public formGroup: FormGroup;
  private editedRowIndex: number;

  public groups: GroupDescriptor[] = [
    { field: 'medication_name', aggregates: [{aggregate: "count", field: 'medication_name'}]}, 
    
    //{ field: 'quantity', aggregates: [{aggregate: "count", field: "quantity"}] }
  ];

  public sort: SortDescriptor[] = [
    {
      field: "injectiondate",
      dir: "asc",
    },
  ];
  

  public gridView: DataResult;
  constructor(
    private auth: AuthService,
    public orderService: OrderService,
    private matDialog: MatDialog,
    public utility: UtilityService,
    private db: DataService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private translate: TranslateService,
    private patientService: PatientService
  ) {
    this.allData = this.allData.bind(this);
    this.orderId = this.aRoute.snapshot.params.orderId;
    this.orderNumber = this.aRoute.snapshot.params.orderNumber;
    this.orderSummaryUrl = this.aRoute.snapshot.params.url;
    this.returnModule = this.aRoute.snapshot.params.returnModule;
    this.orderInjectionClinicDoctor = this.aRoute.snapshot.params.orderInjectionClinicDoctor;
    this.orderInjectionClinicDate = this.aRoute.snapshot.params.orderInjectionClinicDate;

    if (this.returnModule === 'order-report') this.returnUrl = '/reports/order-report';
    this.reconciled = this.aRoute.snapshot.params.reconciled === 'true' ? true : false;
   }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
    this.rxForm = new FormGroup({
      // doctor_update: this.doctorControl,
      medication_update: this.medicationControl,
      eye_update: this.eyeControl,
    });
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.language = this.user.language;
    this.appSettings = await this.db.getAppSettings().pipe(first()).toPromise();
    this.appSettings['medications'].forEach(medication => {
        if (medication.provinces.includes(this.user.clinic_province)) {
          this.medications.push(medication);
        }
    });
    this.eyes = this.appSettings['eyes'];
    
    this.doctors = await this.orderService.getDoctorsByClinic(this.user.clinic_id).pipe(first()).toPromise();
    // if (this.doctors.length === 1) {
    //   this.doctorControl.setValue(this.doctors[0]);
    // }
    
    this.orderService.getRxOrder(this.orderId).subscribe((rx) => {
      this.export = rx;
      this.order = rx;
      rx.map(r => {
        if (r.appointmentTime)  {
          // const injectionDateTimeString = `${DateTime.fromSeconds(r.injectiondate.seconds).toFormat('yyyy-MM-dd')} ${r.appointmentTime.replace(' ', ':00').toLowerCase()}`;
          // const result = DateTime.fromSQL(injectionDateTimeString)
          var hour = moment(r.appointmentTime, ["h:mm A"]).format("HH");
          var minute = moment(r.appointmentTime, ["h:mm A"]).format("mm");
          const d = DateTime.fromJSDate(r.injectiondate.toDate()).set({
            hour: parseInt(hour),
            minute: parseInt(minute)
          })
          console.log(d)
          r.injectiondate = d.toJSDate();
        }
      })
      this.gridView = process(this.order, { group: this.groups });
      this.alldata = {
        data: process(rx, {}).data
      };
    })
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.gridView = process(this.order, { group: this.groups });
  }

  async deleteBatch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              this.orderService.deleteRxBatchByClinic(this.user.clinic_id).then(() => {
                this.utility.showSnackBar("The order has been cleared.", 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar("There was a problem clearing the order.  Please try again", 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  async editRx(rx: Rx)  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    dialogConfig.data = rx;
    this.matDialog.open(RxAddComponent, dialogConfig);
  }

  async deleteRx(rxId: string)  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              this.orderService.deleteRx(rxId).then(() => {
                this.utility.showSnackBar("The prescription has been deleted and removed from this order.", 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar("There was a problem deleting the prescription.  Please try again", 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  /********* Submit Order *****************/
  async openSubmitOrderDialog() {
    this.orderdoctors = this.getUniqueDoctors();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.submitDialogRef = this.matDialog.open(this.submitDialog, dialogConfig);
      this.submitDialogRef.afterClosed().subscribe(result => {
        console.log("Dialog close result: ", result);
        if (result !== undefined) {
            if (result !== 'Cancel') {

            } 
        }
    })
  }

  async submitOrder() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.orderSubmitConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
        if (result !== undefined) {
            if (result === 'yes') {
              let order: RxOrder = <RxOrder>{};
              order.clinic_id = this.user.clinic_id;
              order.clinic_name = this.user.clinic_name;
              order.clinic_province = this.user.clinic_province;
              order.orderDoctors = this.orderdoctors;
              order.rx = this.order;
              order.status = 'Queued';
              order.type = 'Rx';
              order.complete = false;
          
              let createdBy: CreatedBy = <CreatedBy>{};
              createdBy.id = this.user.id;
              createdBy.name = this.user.display_name;
              order.createdBy = createdBy;
          
              this.orderService.createRxOrder(order).then(async () => {
                this.submitDialogRef.close();
                await this.router.navigate(['/patient-list']);
                this.utility.showSnackBar("The order was successfully submitted.", 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar("There was a problem submitting the order.  Please try again", 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  getUniqueDoctors(): Doctor[] {
    let doctors = this.order.map(rx => rx.doctor)
    let uniqueDoctors = [...new Map(doctors.map(item => [item['id'], item])).values()];
    return uniqueDoctors;
  }

  nextClicked(event) {
    console.log(event);
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
  }

  allData(): ExcelExportData {
    return this.alldata;
  }

  signConsent(rxId: string, patient: Patient) {
    this.selectedRxId = rxId;
    this.selectedPatient = patient;
    this.termsone = null;
    this.termstwo = null;
    this.termsthree = null;
    this.termsfour = null;
    this.termsfive = null;
    this.signature = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.consentDialogRef = this.matDialog.open(this.patientConsentDialog, dialogConfig);
    this.consentDialogRef.afterClosed().subscribe(result => {
        console.log("Dialog close result: ", result);
        // if (result !== undefined) {
        //     if (result !== 'Cancel') {
              
        //     } else {
        //       this.orderForm.reset();
        //     }
        // } else {
        //   this.orderForm.reset();
        // }
    });
  }

  // submitConsent() {
  //   const faxnumber = this.appSettings.faxNumber;
  //   this.patientService.addPatientConsent(this.user.clinic_id, this.user.clinic_name, this.selectedPatient, this.signature, faxnumber, this.selectedRxId).then(() => {
  //     this.utility.showSnackBar("The patient consent has been updated", 2000, 'center', 'top');
  //     this.consentDialogRef.close();
  //   })
  //   .catch((err) => {
  //     this.utility.showSnackBar("There was a problem completing the consent.  Please try again", 2000, 'center', 'top');
  //   })
  // }

  clearSignature(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.clear();
    this.signature = null;
  }

  drawStart(index) {
    console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    console.log("Ended drawing on signature", index);
    let signaturePad = this.signaturePads.toArray()[index];
    if(!signaturePad.isEmpty())  {
      this.signature = signaturePad.toDataURL();
    }
  }

  openUpdateDialog(rx) {
    this.selectedRx = { ...rx};
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    this.updateRxDialogRef = this.matDialog.open(this.updateRxDialog, dialogConfig);
    this.updateRxDialogRef.afterClosed().subscribe((result) => {

    });
  }

  submitUpdate()   {
    let doc = {  medication_name_update: null, medication_update: null, eye_update: null, quantity_update: null, quantity_update_numeric: null }
    if (this.medicationControl.value) {
      doc.medication_update = this.medicationControl.value;
      doc.medication_name_update = doc.medication_update.name;
    }
    if (this.eyeControl.value)  {
      doc.eye_update = this.eyeControl.value;
      if (doc.eye_update === 'BOTH') {
        doc.quantity_update = "2x";
        doc.quantity_update_numeric = 2;
      } else {
        doc.quantity_update = "1x";
        doc.quantity_update_numeric = 1;
      }
    }

    this.orderService.updateRx(this.user.clinic_id, this.selectedRx.id, doc, true).then(() => {
      console.log(this.rxForm.value, this.selectedRx);
      this.updateRxDialogRef.close();
      this.selectedRx = null;
      this.eyeControl.reset();
      this.medicationControl.reset();
    })
  }

  toggleNoShow(rx)  {
    this.selectedRx = { ...rx};
    console.log("**********", this.selectedRx.AppointmentComplete);
    if (this.selectedRx.AppointmentComplete) {
      delete this.selectedRx.AppointmentComplete;
    } else {
      this.selectedRx.AppointmentComplete = true;
    }
    console.log(this.selectedRx)
    this.orderService.updateRx(this.user.clinic_id, this.selectedRx.id, { ...this.selectedRx}, false).then(() => {
      this.selectedRx = null;
    }) 
  }

  test(rx)  {
    console.log(rx);
  }

  async CompleteAndUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    this.confirmSendUpdateDialogRef = this.matDialog.open(this.confirmSendUpdateDialog, dialogConfig);
      this.confirmSendUpdateDialogRef.afterClosed().subscribe(result => {
        console.log("Dialog close result: ", result);
        if (result !== undefined) {
            if (result === 'yes') {
              this.orderService.reconcileOrder(this.user.clinic_id, this.orderId, this.user).then(() => {
                this.utility.showSnackBar("The order is now reconciled", 2000, 'center', 'top');
                this.reconciled = true;
              })
              .catch((err) => {
                this.utility.showSnackBar("There was a problem submitting the reconciliation update.  Please try again", 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.formGroup = createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const clinic = formGroup.value;
    // this.injectionClinicService.updateInjectionClinicName(this.user, this.user.clinic_id, clinic.id, clinic.name).then(() => {
    //   this.utility.showSnackBar(this.translate.instant('updatesuccess'), 2000, 'center', 'top');
    //   this.faxes[rowIndex].name = clinic.name;
    // })
    // .catch((err) => {
    //   this.utility.showSnackBar(this.translate.instant('updateerror'), 2000, 'center', 'top');
    // })
    sender.closeRow(rowIndex);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}
