<div>
    <mat-toolbar color="accent">
        <span>Patients</span>
        <ng-container *ngIf="user">
            <span *ngIf="user.injectionclinic">&nbsp;-&nbsp;Currently assigning patients to {{ user.injectionclinic.name ? user.injectionclinic.name : '' }} {{ this.utility.formatDate(user.injectionclinic.clinicDate, 'YYYY-MM-DD') }}</span>
        </ng-container>
        <span class="fill-space"></span>
        <!-- <button mat-flat-button (click)="SaveView(grid)">{{ 'global.save' | translate }}</button>&nbsp; -->
        <button mat-flat-button (click)="AddPatient()">{{ 'patients.newpatient' | translate }}</button>
        <!-- <ng-container *ngIf="user">
            &nbsp;<button mat-flat-button color="warn" *ngIf="user.last_name === 'Spadzinski'" (click)="DeletePatients()">Delete</button>
        </ng-container> -->
    </mat-toolbar>
    <kendo-grid
        #grid
        [kendoGridBinding]="patients | async"
        kendoGridSelectBy="id"
        [selectedKeys]="mySelection"
        [pageSize]="15"
        [pageable]="true"
        [sortable]="{
            allowUnsort: true,
            mode: 'single'
        }"
        [sort]="sort"
        [filterable]="true"
        [groupable]="false"
        [reorderable]="true"
        [resizable]="true"
        [columnMenu]="{ filter: true }"
        [loading]="patientService.loading"
    >
    <kendo-grid-messages filterBooleanAll="{{ 'patients.filter.all' | translate }}" filterIsTrue="{{ 'patients.filter.true' | translate }}" filterIsFalse="{{ 'patients.filter.false' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
    </kendo-grid-messages>
    <!-- <kendo-grid-column  [includeInChooser]="false" [sortable]="true" [filterable]="false" field="last_rx" title="{{ 'shell.rxorders' | translate }}" [width]="207">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button mat-button color="primary" [matTooltip]="addTooltip(dataItem.last_rx)" matTooltipClass="multiline-tooltip" [disabled]="!dataItem.last_rx" (click)="ReorderRx(dataItem);">{{ 'patients.reorder' | translate }}</button>
            <button mat-button color="accent" (click)="AddRx(dataItem);">{{ 'patients.addrx' | translate }}</button>
        </ng-template>
    </kendo-grid-column> -->

    <kendo-grid-column title="Patient Complete" field="isReadyForEnrollment" filter="boolean"  [sortable]="false" [width]="330">
        <ng-template kendoGridCellTemplate let-dataItem>
               <!--<button style="width: 30px !important;" mat-button color="warn" (click)="DeletePatient(dataItem.id)"><mat-icon>delete</mat-icon></button> -->
            <a mat-button [matMenuTriggerFor]="sysAdminMenu">{{ 'shell.actions' | translate }}<mat-icon>expand_more</mat-icon></a>
            <mat-menu #sysAdminMenu="matMenu">
              <a [disabled]="!dataItem.isReadyForEnrollment" mat-menu-item mat-menu-item (click)="createEnrollments(dataItem);"><mat-icon>picture_as_pdf</mat-icon>Create Enrollments</a>
              <a mat-menu-item mat-menu-item (click)="EditPatient(dataItem);"><mat-icon>edit</mat-icon>Edit Patient</a>
              <a mat-menu-item mat-menu-item (click)="DeletePatient(dataItem.id)"><mat-icon>delete</mat-icon>Delete Patient</a>
            </mat-menu>
            <button *ngIf="user.injectionclinic" [disabled]="!dataItem.isReadyForRx" mat-button color="accent" (click)="addRx(dataItem);">Add To Injection Clinic</button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="consentSigned" filter="boolean" title="{{ 'patients.consent' | translate }}" [width]="154">
        <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem.medication">
                <ng-container *ngIf="dataItem.consentSigned">
                    <button mat-button (click)="signConsent(dataItem);"><span *ngIf="dataItem.consentDate">{{utility.formatDate(dataItem.consentDate, 'yyyy-MM-DD')}}</span></button>
                </ng-container>
                <ng-container *ngIf="!dataItem.consentSigned">
                    <button mat-button color="primary" (click)="signConsent(dataItem);"><fa-icon style="font-size: 20px !important" [icon]="faFileSignature"></fa-icon></button>
                    <!-- <button mat-button color="primary" (click)="signConsent(dataItem);"><mat-icon fontSet="fa" fontIcon="faSignature"></mat-icon></button> -->
                    <!-- <button mat-button (click)="signConsent(dataItem);">{{ 'patients.consent' | translate }}</button> -->
                </ng-container>
            </ng-container>
        </ng-template>
    </kendo-grid-column>
    <!-- <kendo-grid-column field="isReadyForEnrollment" filter="boolean" title="Patient Complete" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem.isReadyForEnrollment">
                <div style="text-align: center"><button mat-button color="primary" (click)="createEnrollments(dataItem);">Enrollment Forms</button></div>
            </ng-container>
            <ng-container *ngIf="!dataItem.isReadyForEnrollment">
                <div style="text-align: center"></div>
            </ng-container>
        </ng-template>
    </kendo-grid-column> -->
      <!-- <kendo-grid-column field="consentSigned" filter="boolean" title="{{ 'patients.consent' | translate }}" [width]="154">
        <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem.consentSigned">
                <a mat-button *ngIf="dataItem.consentPdf" mat-button [href]="dataItem.consentPdf.url" target="_blank">{{dataItem.consentDate.toDate() | date: 'yyyy-MM-dd'}}</a>
                <span *ngIf="!dataItem.consentPdf">{{dataItem.consentDate.toDate() | date: 'yyyy-MM-dd'}}</span>
            </ng-container>
            <ng-container *ngIf="!dataItem.consentSigned">
                <button mat-button (click)="signConsent(dataItem);">{{ 'patients.consent' | translate }}</button>
            </ng-container>
        </ng-template>
    </kendo-grid-column> -->
    <kendo-grid-column field="last_name" title="{{ 'patients.lastname' | translate }}" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.last_name | titlecase }}
        </ng-template> 
    </kendo-grid-column>
    <kendo-grid-column field="first_name" title="{{ 'patients.firstname' | translate }}" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.first_name | titlecase }}
        </ng-template> 
    </kendo-grid-column>
    <kendo-grid-column field="dob" title="{{ 'patients.dob' | translate }}" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.dob">{{dataItem.dob | date: 'yyyy-MM-dd'}}</span>  
        </ng-template> 
    </kendo-grid-column>
    <!-- <kendo-grid-column field="dob" filter="date" format="{0:yyyy-MM-dd}" title="{{ 'patients.dob' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.dob | date: 'yyyy-MM-dd'}}
        </ng-template>
    </kendo-grid-column> -->
    <kendo-grid-column field="diagnosis" title="{{ 'patients.diagnosis' | translate }}" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.diagnosis }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="medication.name" title="{{ 'rx.medication' | translate }}" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.medication">{{ dataItem.medication.name }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="eye" title="{{ 'rx.eye' | translate }}" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.eye }}</span>
        </ng-template>
      </kendo-grid-column>
    <!-- <kendo-grid-column field="chart_id" filter="numeric" title="{{ 'patients.emrid' | translate }}" [width]="150"></kendo-grid-column> -->
    <kendo-grid-column field="healthcard"  title="{{ 'patients.healthcard' | translate }}" [width]="200"></kendo-grid-column>
    <!-- <kendo-grid-column field="phone_number" title="{{ 'patients.phone' | translate }}"></kendo-grid-column> -->

  </kendo-grid>
  </div>       

      <ng-template #callDeleteConfirmDialog>
        <h2 matDialogTitle>{{ 'patients.delete.title' | translate }}</h2>
        <mat-dialog-content>
            {{ 'patients.delete.message' | translate }}
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
            <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
        </mat-dialog-actions>
    </ng-template>


<ng-template #patientConsentDialog>
    <!-- <button mat-icon-button class="margnify-button">
        <mat-icon>zoom_in</mat-icon>
    </button>
    <button mat-icon-button class="margnify-button">
        <mat-icon>zoom_out</mat-icon>
    </button> -->
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <img style="width: 400px;" *ngIf="this.selectedPatient.medication" [src]="getLogo()">
    <h2 matDialogTitle>{{ consentTitle }}<span *ngIf="selectedPatient"> - {{ selectedPatient.full_name }}</span></h2>
    <mat-dialog-content class="mat-typography">
        <div class="english_consent_text" style="overflow-y: auto; height:250px;">
            <ng-container *ngIf="showBeovuEnrollmentForm()">
                <ng-container *ngIf="selectedPatient.language === 'en'">
                    <p>
                        To register a patient to the ViaOpta® ForSight Reimbursement Assistance Program, please complete section 1 and section 2.  Once the form is completed, fax both pages to: 1-866-777-1761.
                    </p>
                    <p>
                        <strong>What is the ViaOpta® ForSight Reimbursement Assistance Program?</strong><br/>
                        The ViaOpta® ForSight Reimbursement Assistance Program is a patient support program (“Program”) provided by Novartis Pharmaceuticals Canada Inc. and/or its affiliates (collectively “Novartis”, we, us, our) to Canadian patients who have been prescribed Beovu®. Your health care professional believes you could benefit from the Program. The Program Services may include health/disease/product information, insurance reimbursement assistance or treatment services (the “Services”). A third-party service provider is the administrator of the Program: its employees and/or agents handle your Personal Information, which is processed in accordance with privacy laws and Novartis privacy/data protection standards. You will be notified should the administrator of the Program change, including in the case of administration by a Novartis department; your Personal Information will continue to be protected with equivalent safeguards.
                    </p>
                    <p>
                        Your participation in the Program is voluntary. If you choose not to participate, neither your medical treatment nor your insurance coverage eligibility will be impacted. However, if you do not participate, you cannot receive assistance or Services from the Program. The Program is not intended to provide medical advice or medical diagnoses. You agree to seek the advice of your physician or other qualified health care professional if you have health concerns, and not to disregard professional medical advice based on information obtained from the Program. Novartis reserves the right to modify or terminate the Program at any time without prior notice.
                    </p>
                    <p>
                        <strong>Why is Personal Information collected, for which purposes and to whom could it be shared?</strong><br/>
                        Information, such as your date of birth, contact information, drug/medical, and insurance/financial information (collectively “Personal Information”), is collected to communicate with you, provide you with the Program’s Services, audit or monitor the Program, and perform certain activities as required or permitted by law, including to process and report adverse events (“AEs”). We may contact you at the contact information you have provided: email, phone or other (if via cellular, we will not assume any resulting cellular phone charges).
                    </p>
                    <p>
                        Only relevant personnel will have access to your Personal Information. Your Personal Information may be collected from and disclosed to health care professionals, insurance providers or other third parties, as needed for the Program’s administration and Services. Our third-party providers are contractually obliged to strict data protection and security requirements. In the case of AEs processing and reporting to regulatory authorities, if monitoring or auditing is performed, or if required and/or permitted by law, it may be that Novartis employees or agents will have access to your Personal Information. Novartis employees or agents may also have access to your Personal Information to provide you with the service of delivering product to you, your clinic or pharmacist, if you are eligible for such service, as it may be available within the Program. In such case, Novartis employees or agents will only have access to data of your Personal Information needed to manage the distribution and delivery of product. Novartis and/or its agents may de-identify (replace your identifying data with a code or label), aggregate (combine with other data) or anonymize data from the Program to conduct analyses for commercial, research or publication purposes. Analyses are performed to help us improve our offers and services such as this Program or others, treatment reimbursement, disease educational campaigns, online communications, and may be conducted using digital capabilities. Your Personal Information may be stored or processed outside of Canada, including for AEs processing and reporting requirements. In such case, Novartis ensures that your Personal Information is protected. Your Personal Information may be subject to the laws of foreign jurisdictions, with a different level of protection than your country of residence. 
                    </p>
                    <p>
                        <strong>What happens if I withdraw from the Program?</strong><br/>
                        You may revoke your consent at any time. Withdrawing your consent will result in the termination of your participation in the Program and its Services; no new Personal Information will be collected; the file containing your Personal Information will be maintained during the term of the Program for monitoring and regulatory purposes; deidentified, aggregated or anonymized data may continue to be used as described above.
                     </p>
                     <p>
                        You may request access or correction to your file by contacting the Novartis Privacy Officer at 385 Bouchard Blvd, Dorval, Québec, H9S 1A9 or privacy.pharmacanada@novartis.com. By signing the consent, you agree to the collection, use and disclosure of your Personal Information as described herein. You can learn more about how Novartis protects privacy at www.novartis.ca/en/privacy-policy.
                     </p>
                </ng-container>
                <ng-container *ngIf="selectedPatient.language === 'fr'">
                    <p>
                        Pour inscrire un patient au Programme d’aide au remboursement VISION de ViaOptaMD, veuillez remplir les sections 1 et 2.  Une fois le formulaire dûment rempli, veuillez envoyer les deux pages par télécopieur au numéro : 1 866 777-1761.
                    </p>
                    <p>
                        <strong>En quoi consiste le Programme d’aide au remboursement VISION de ViaOptaMD?</strong><br/>
                        Le Programme d’aide au remboursement VISION de ViaOptaMD est un programme de soutien aux patients (le « Programme ») offert par Novartis Pharma Canada inc. ou ses sociétés affiliées (« Novartis », « nous », « notre ») aux patients canadiens ayant reçu une ordonnance de BeovuMD. Votre professionnel de la santé estime que vous pourriez bénéficier du Programme. Les Services du Programme peuvent inclure des renseignements sur la santé/un produit/la maladie, une aide en matière de remboursement ou des services de traitement (les « Services »).
                    </p>
                    <p>
                        Un fournisseur de services tiers est l’administrateur du Programme : ses employés et/ou ses agents traitent vos Renseignements personnels conformément aux lois sur la confidentialité et aux normes de protection de la vie privée et des données de Novartis. Vous serez avisé dans le cas d’un changement d’administrateur du Programme, y compris dans le cas d’une administration par un service de Novartis; vos Renseignements personnels continueront de faire l’objet de mesures de protection équivalentes.
                    </p>
                    <p>
                        Votre participation au Programme est volontaire. Si vous décidez de ne pas y participer, ni votre traitement médical ni votre admissibilité au régime d’assurance ne sera touché. Toutefois, si vous n’y participez pas, vous ne pouvez pas recevoir d’aide de la part du Programme, ni bénéficier de ses Services. Le Programme n’a pas pour but de prodiguer des conseils médicaux ni de poser de diagnostics. Vous convenez de toujours consulter votre médecin ou un autre professionnel de la santé compétent pour tout problème de santé et de ne pas ignorer les conseils médicaux professionnels qui vous sont prodigués au profit des renseignements obtenus dans le cadre du Programme. Novartis se réserve le droit de modifier le Programme ou d’y mettre fin à tout moment et sans préavis.
                    </p>
                    <p>
                        <strong>Pourquoi des Renseignements personnels sont-ils recueillis, à quelles fins, et à qui se pourrait-il qu’ils soient transmis?</strong><br/>
                        Des renseignements tels que votre date de naissance, vos coordonnées, les médicaments que vous prenez, les traitements que vous recevez, votre assurance et vos informations financières (collectivement « Renseignements personnels ») sont recueillis dans le but de communiquer  avec vous, vous fournir les Services du Programme, vérifier ou surveiller le Programme et réaliser certaines activités aux fins exigées ou autorisées par la loi, y compris pour traiter et signaler des événements indésirables (« EI »). Il peut nous arriver de communiquer avec vous par l’entremise des coordonnées que vous avez fournies, soit par courriel, téléphone ou autre (si nous communiquons avec vous par cellulaire, nous n’assumons aucuns des frais afférents). Seul le personnel concerné aura accès à vos Renseignements personnels.
                    </p>
                    <p>
                        Only relevant personnel will have access to your Personal Information. Your Personal Information may be collected from and disclosed to health care professionals, insurance providers or other third parties, as needed for the Program’s administration and Services. Our third-party providers are contractually obliged to strict data protection and security requirements. In the case of AEs processing and reporting to regulatory authorities, if monitoring or auditing is performed, or if required and/or permitted by law, it may be that Novartis employees or agents will have access to your Personal Information. Novartis employees or agents may also have access to your Personal Information to provide you with the service of delivering product to you, your clinic or pharmacist, if you are eligible for such service, as it may be available within the Program. In such case, Novartis employees or agents will only have access to data of your Personal Information needed to manage the distribution and delivery of product. Novartis and/or its agents may de-identify (replace your identifying data with a code or label), aggregate (combine with other data) or anonymize data from the Program to conduct analyses for commercial, research or publication purposes. Analyses are performed to help us improve our offers and services such as this Program or others, treatment reimbursement, disease educational campaigns, online communications, and may be conducted using digital capabilities. Your Personal Information may be stored or processed outside of Canada, including for AEs processing and reporting requirements. In such case, Novartis ensures that your Personal Information is protected. Your Personal Information may be subject to the laws of foreign jurisdictions, with a different level of protection than your country of residence. 
                    </p>
                    <p>
                        Vos Renseignements personnels pourront être collectés par des professionnels de la santé, des assureurs et/ou d’autres tiers (p. ex., lorsque nécessaire à l’administration du Programme et des Services) et leur être divulgués. Nos fournisseurs tiers sont contractuellement tenus de respecter des exigences strictes en matière de protection des données et de sécurité.
                    </p>
                    <p>
                        Dans le cas du traitement et du signalement d’EI à des organismes de réglementation, si une surveillance ou un audit est effectué, ou si la loi l’exige ou le permet, il se peut que des employés ou des agents de Novartis aient accès à vos Renseignements personnels. Les employés ou les agents de Novartis pourraient avoir accès à vos Renseignements personnels en vue de vous fournir le service de livraison du produit, de fournir ce service à votre clinique ou à votre pharmacien si vous êtes admissible à un tel service et que celui-ci est offert dans le cadre du Programme. Si tel est le cas, les employés ou les agents de Novartis n’auront accès qu’aux données qui concernent vos Renseignements personnels et qui sont nécessaires à la gestion de la distribution et de la livraison du produit.
                    </p>
                    <p>
                        Novartis ou ses agents peuvent désidentifier (remplacer votre identité par un code ou des lettres), agréger (combiner avec d’autres données) ou anonymiser les données du Programme afin de réaliser des analyses à des fins commerciales, de recherche ou de publication. Ces analyses peuvent être effectuées en utilisant des outils technologiques numériques et sont créées afin de nous aider à améliorer nos offres et services, tels que ce Programme ou d’autres programmes, le remboursement de médicaments, des campagnes médicales de sensibilisation ou descommunications en ligne. Vos Renseignements personnels peuvent être conservés ou traités à l’extérieur du Canada, y compris pour se conformer aux exigences de signalement des EI et leur traitement. Dans un tel cas, Novartis garantit que vos Renseignements personnels sont protégés. Vos Renseignements personnels peuvent être assujettis aux lois da’ utres pays ayant un niveau de protection qui diffère de celui de votre pays de résidence.
                    </p>
                    <p>
                        <strong>Que se passe-t-il si je me retire du Programme?</strong><br/>
                        Vous pouvez révoquer votre consentement à tout moment. Le retrait de votre consentement entraînera la résiliation de votre participation au Programme et l’arrêt de prestation des Services. Aucun nouveau renseignement ne sera recueilli; le dossier comportant vos Renseignements personnels sera conservé pour la durée du Programme à des fins de surveillance et de réglementation; des données anonymisées ou agrégées pourront continuer à être utilisées comme il est décrit ci-dessus.                        
                     </p>
                     <p>
                        Vous pouvez demander l’accès ou des corrections à votre dossier en communiquant avec l’agent de la protection de la vie privée de Novartis au 385, boulevard Bouchard, Dorval, Québec, H9S 1A9 ou par courriel à l’adresse privacy.pharmacanada@novartis.com. En signant le présent formulaire de consentement, vous consentez à la collecte, à l’utilisation et à la divulgation de vos Renseignements personnels, comme il est décrit dans le présent document. Vous pouvez en apprendre davantage sur les pratiques de confidentialité de Novartis à l’adresse suivante : www.novartis.ca/fr/politique-sur-la-protection-des-renseignements-personnels.                        
                     </p>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="showEyleaEnrollmentForm()">
                <ng-container *ngIf="selectedPatient.language === 'en'">
                    <p>
                        (If the patient provides verbal consent to participate in the program, but does not sign the enrollment form, a Healthcare Professional signature is required, as indicated on page 1 of this form. Upon receipt of the completed enrollment form, the program will contact the patient to receive final consent.)</p>
                    <p>
                        The EYLEA® NOW Program (“Program”) is funded by Bayer Inc., 2920 Matheson Boulevard East, Mississauga, Ontario, L4W 5R6 (“Bayer”).
                    </p>
                    
                    <p>
                        The Service Provider acting on behalf of Bayer Inc. is McKesson Specialty (“Service Provider”).
                        You are being invited to take part in the EYLEA® NOW Program for patients who are
                        diagnosed with any of the following: BRVO, CRVO, DME, mCNV and wAMD and have been
                        prescribed EYLEA® (aflibercept). Before you decide whether to participate or not, please
                        take time to read the following information which explains the purpose of this Program and
                        what will happen if you take part.
                    </p>
                    
                    <p>There are two parts to this document: Patient Information and a Statement of Consent.</p>
                    
                    <p>
                        <strong>Patient Information</strong><br/>
                        The purpose of the EYLEA® NOW Program is to provide bridging services for EYLEA®
                        (aflibercept), reimbursement navigation and financial support associated with EYLEA® for
                        patients who have been prescribed EYLEA® for treatment of any of the following: BRVO,
                        CRVO, DME, mCNV and wAMD for the duration of their treatment.
                        Your participation in this Program is completely voluntary. It will not affect your medical care
                        if you decide not to participate. You can also withdraw from the Program at any time without
                        giving a reason by contacting the Service Provider. Ask your treating healthcare professional
                        or Program contact if there is anything that is not clear or if you would like more information.
                        There will be no additional cost to you for your participation in the Program. Participation in
                        the Program involves contact with the Service Provider by mail, telephone and/or e-mail
                        in order to provide you with applicable information and support regarding your EYLEA®
                        therapy as well as to determine your eligibility for reimbursement assistance.
                    </p>
                    
                    <p>
                        <strong>Patient Privacy Statement</strong><br/>
                        This Privacy Statement outlines how personal data about you is collected, used and
                        disclosed by Bayer and by the Service Provider on behalf of Bayer in connection with this
                        Program. “Personal data” includes but is not limited to any personally identifying information
                        such as your name, address, telephone number, email address, medical condition, medical
                        history, treatment or financial information such as your insurance coverage.
                        All of your personal data is confidential and will only be shared with your insurance
                        provider, treating healthcare professional and the Service Provider for administration of
                        the Program. In addition, your personal data may be provided to an additional vendor in
                        order to ensure you have only one active enrollment in the Program. If you are eligible for
                        benefits under the Non-Insurance Health Benefit (NIHB) program and live in a remote area,
                        it may be necessary for you to provide personal data required for the administration of the
                        Program directly to your pharmacy. In such a case, the pharmacy may share that data
                        with the Service Provider. Bayer may also authorize disclosure of your personal data when
                        required by law, such as to respond to court orders, subpoenas or other legal processes
                        by authorities within the jurisdiction. Your data will be locally stored by the Program contact
                        partner for at least 15 years after the end of the Program.
                        Bayer uses technical and organizational security precautions to protect your data from
                        manipulation, loss, destruction, or unauthorized access, use, disclosure or alteration. Service
                        providers and vendors that process personal data on behalf of Bayer are contractually
                        obligated to safeguard data and only use the data provided to them for the purposes
                        outlined in this enrollment form, including this Privacy Statement.
                        You have the right to review and amend any personal data collected for your participation
                        in the Program. If you wish to ask a question about the information in your file or request a
                        change to the information in your file, please contact:
                    </p>
                    <p>
                        McKesson Privacy Officer<br/>
                        McKesson Canada, Tel: 1-855-993-9932, Fax: 1-855-933-9532<br/>
                        Email: info@eyleadirect.ca<br/>
                    </p>
                    
                    <p>
                        <strong>Statement of Consent</strong><br/>
                        Patient Declaration:<br/>
                        I confirm that:
                    </p>
                    <ul>
                        <li>I have read and understand the Program being offered, and I have had all of my questions answered.</li>
                        <li>I understand that I will receive a copy of the Patient Information and this Statement of Consent once I have signed it.</li>
                        <li>I understand that Program services begin after the physician’s prescription.</li>
                        <li>The Service Provider may reach out to me to discuss my personal insurance information, and for the administration of patient satisfaction surveys.</li>
                        <li>I understand that the service is provided free of charge by the Service Provider on behalf of Bayer and that Bayer can modify or cancel this Program and the associated service(s) at any time.</li>
                        <li>The Service Provider may communicate with me via SMS text message and/or e-mail in order to support my participation in the Program.</li>
                        <li>The Service Provider may share my medical condition, any complaints and/or adverse events related to the product or my disease state with my treating healthcare professional.</li>
                        <li>The Service Provider may contact me for the follow-up of adverse events, product technical complaints and/or usability issues or other safety-related events.</li>
                        <li>Bayer Pharmacovigilance Department may contact the Service Provider and/or my treating healthcare professional for the follow-up of adverse events, product technical complaints and/or usability issues or other safety-related events.</li>
                        <li>My decision to take part in the Program is entirely voluntary. I can decide to leave the Program at any time without affecting my standard medical care.</li>
                        <li>I agree to participate in the Program as described in the Patient Information section.</li>
                    </ul>
                    <p>
                        Consent:<br/>
                        My consent to the following allows for the handling of my personal data.<br/>
                        My consent is voluntary. It will allow my participation in the Program.<br/>
                    </p>
                    <ul>
                        <li>I consent to the collection, use and disclosure of personal data for the purposes of administering the Program.</li>
                        <li>I agree that in case of any adverse event / product technical complaint / usability issue or other safety-related event, necessary information will be forwarded to Bayer’s local pharmacovigilance unit and health authorities, as required for reporting purposes.</li>
                        <li>I agree that in case of audit or inspection, necessary information will be shared with auditors and/or Health Authorities, as required.</li>
                        <li>I am aware that I may withdraw from participation in the Program at any time, including my consent to the further handling of my personal data. I understand that data already collected may be used in accordance with the manner and purpose outlined in the Patient Privacy Statement section and in accordance with applicable laws.</li>
                        <li>I agree that upon enrollment my personal data will be provided to an additional vendor in order to ensure that I only have one active enrollment in the Program.</li>
                        <li>If at any time and for any reason the Service Provider for my clinic changes, I hereby give permission for the transfer of my personal data to the new Service Provider, for the purpose of continuing my participation in the Program.</li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="selectedPatient.language === 'fr'">
                    <p>
                        <strong>Consentement du patient</strong><br/>
                        (Si le patient consent verbalement à participer au programme, mais ne signe pas le formulaire
                        d’inscription, la signature du professionnel de la santé est requise, tel qu’indiqué à la page 1
                        de ce formulaire. Après réception du formulaire d’inscription dûment rempli, le programme
                        communiquera avec le patient pour obtenir son consentement final.)
                        Le programme EYLEA® NOW (le « programme ») est financé par Bayer Inc., dont les bureaux
                        sont situés au 2920 Matheson Boulevard East, Mississauga (Ontario) L4W 5R6 (« Bayer »).
                        Le fournisseur de services agissant au nom de Bayer Inc. est McKesson Specialty (le
                        « fournisseur de services »).                        
                    </p>
                    <p>
                        Vous êtes invité à participer au programme EYLEA® NOW destiné aux patients ayant reçu
                        un diagnostic d’OBVR, d’OVCR, d’OMD, de NVC myopique ou de DMLA humide à qui on
                        a prescrit EYLEA® (aflibercept). Avant de décider de participer ou non, prenez le temps de
                        lire les renseignements suivants expliquant l’objectif de ce programme et ce qui arrivera si
                        vous y prenez part.
                    </p>
                    <p>
                        Ce document comporte deux parties : Les renseignements sur le patient et une déclaration de consentement.
                    </p>
                    <p>
                        <strong>Renseignements sur le patient</strong><br/>
                        L’objectif du Programme EYLEA® NOW est de fournir des services de transition pour EYLEA®
                        (aflibercept), ainsi que du soutien relatif au remboursement et de l’aide financière associés à
                        EYLEA® aux patients à qui on a prescrit EYLEA® pour le traitement de l’OBVR, de l’OVCR, de
                        l’OMD, de la NVC myopique ou de la DMLA humide, et ce, pendant toute la durée du traitement.
                        La participation à ce programme est entièrement volontaire. Si vous décidez de ne pas y
                        participer, vous continuerez quand même de recevoir des soins médicaux standards. Vous
                        pouvez également vous retirer du programme en tout temps, sans donner de raison. Il suffit
                        d’en informer le fournisseur de services. Si vous souhaitez obtenir des précisions ou de plus
                        amples renseignements, adressez-vous à votre professionnel de la santé ou à la personneressource
                        du programme.
                    </p>
                    <p>
                        Votre participation à ce programme n’entraîne aucuns frais supplémentaires. La participation
                        à ce programme implique des communications avec le fournisseur de services par la poste,
                        par téléphone et/ou par courriel, qui vous permettront d’obtenir l’information et le soutien
                        pertinents relativement à votre traitement par EYLEA® et de déterminer votre admissibilité
                        au remboursement.
                    </p>
                    <p>
                        <strong>Énoncé sur la confidentialité pour le patient</strong><br/>
                        Cet énoncé sur la confidentialité décrit de quelle façon vos renseignements personnels sont
                        recueillis, utilisés et divulgués par Bayer et par le fournisseur de service pour Bayer, en lien
                        avec ce programme. Les renseignements personnels incluent notamment des données
                        d’identification comme vos nom, adresse, numéro de téléphone, adresse électronique, votre
                        affection et vos antécédents médicaux, votre traitement ou des renseignements financiers
                        comme votre régime d’assurance.
                    </p>
                    <p>
                        Tous vos renseignements personnels sont confidentiels et ne seront communiqués qu’à votre
                        fournisseur d’assurance, au professionnel de la santé qui vous traite et au fournisseur de services
                        aux fins d’administration du programme. Par ailleurs, vos renseignements personnels peuvent être
                        communiqués à un autre fournisseur afin de garantir que vous n’ayez qu’une seule inscription
                        active au programme. Si vous êtes admissible au remboursement en vertu des Services de
                        santé non assurés (NNSA) et que vous habitez dans une région éloignée, vous pourriez devoir
                        fournir les renseignements personnels requis par l’administration du programme directement
                        à votre pharmacie. Le cas échéant, la pharmacie pourrait partager ces renseignements avec
                        le fournisseur de services. Bayer pourrait aussi autoriser la divulgation de vos renseignements
                        personnels si la loi l’exige, par exemple pour répondre aux ordonnances de tribunaux, aux
                        assignations à comparaître ou à d’autres processus juridiques imposés par les autorités dans le
                        territoire de compétence. Vos données seront conservées localement par le partenaire point de
                        contact du programme pendant au moins 15 ans après la fin du programme.
                    </p>
                    <p>
                        Bayer applique des mesures de sécurité techniques et organisationnelles pour protéger vos
                        renseignements personnels de la manipulation, de la perte et de la destruction, de même
                        que pour empêcher l’accès à ces renseignements et en prévenir l’utilisation, la divulgation
                        ou l’altération sans autorisation. Les fournisseurs de services et fournisseurs qui traitent les
                        renseignements personnels au nom de Bayer ont l’obligation contractuelle de protéger les
                        renseignements et de n’utiliser les renseignements leur ayant été fournis que pour les fins
                        soulignées dans ce formulaire d’inscription, y compris la présente déclaration de confidentialité.
                        Vous avez le droit de consulter et de modifier tous les renseignements personnels recueillis
                        pour participer au programme. Si vous désirez poser des questions sur les renseignements
                        figurant dans votre dossier ou demander leur modification, veuillez communiquer avec :
                        Agente de la protection des renseignements personnels de McKesson.
                    </p>
                    <p>
                        McKesson Canada, tél : 1-855-993-9932, télécopieur : 1-855-933-9532<br/>
                        Adresse électronique : info@eyleadirect.ca
                    </p>
                    <p>
                        <strong>Consentement</strong><br/>
                        Déclaration du patient :<br/>
                        Je confirme ce qui suit :<br/>       
                    </p>
                    <ul>
                        <li>J’ai lu et je comprends le programme offert et j’ai obtenu des réponses à toutes mes questions.</li>
                        <li>Je comprends que je recevrai une copie du feuillet d’information au patient et de la présente déclaration de consentement une fois que je l’aurai signé.</li>
                        <li>Je comprends que les services du programme commenceront après que le médecin aura prescrit le médicament.</li>
                        <li>Le fournisseur de services pourrait communiquer avec moi pour discuter de mes renseignements personnels pour ce qui est de mes assurances et pour des sondages sur la satisfaction du patient.</li>
                        <li>Je comprends que ce service est donné sans frais par le fournisseur de services au nom de Bayer, et que Bayer peut modifier ou annuler ce programme et les services associés en tout temps.</li>
                        <li>Le fournisseur de services pourrait communiquer avec moi par message texte et/ou par courriel afin de soutenir ma participation au programme.</li>
                        <li>Le fournisseur de services pourrait communiquer avec le professionnel de la santé qui me traite pour discuter de mon trouble médical ainsi que de toute plainte et/ou de tout effet indésirable liés au produit.</li>
                        <li>Le fournisseur de services pourrait communiquer avec moi pour effectuer le suivi des effets indésirables, des plaintes techniques sur le produit et/ou des problèmes d’utilisation ou d’autres effets liés à l’innocuité.</li>
                        <li>Le Service de la pharmacovigilance de Bayer pourrait communiquer avec le fournisseur de services et/ou le professionnel de la santé qui me traite pour effectuer un suivi quant aux effets indésirables, aux plaintes techniques sur le produit et/ou aux problèmes d’utilisation ou à d’autres effets liés à l’innocuité.</li>
                        <li>La décision de participer au programme est entièrement volontaire. Je peux décider de quitter le programme en tout temps sans influencer la qualité de mes soins de santé.</li>
                        <li>J’accepte de participer au programme tel que décrit dans la partie Renseignements à l’intention du patient.</li>
                    </ul>  
                    <p>
                        <strong>Consentement</strong><br/>
                        Mon consentement aux suivantes permet le traitement de mes renseignements personnels.<br/>
                        Mon consentement est volontaire. Il permettra ma participation au programme.<br/>       
                    </p>
                    <ul>
                        <li>Je consens à la collecte, à l’utilisation et à la divulgation de mes renseignements personnels aux fins de l’administration de ce programme.</li>
                        <li>J’accepte que, dans l’éventualité d’effet indésirable, de plainte technique sur le produit,
                            de problème d’utilisation ou d’autre effet lié à l’innocuité, les renseignements nécessaires
                            soient envoyés au service local de la pharmacovigilance de Bayer et aux autorités
                            sanitaires, aux fins de production des rapports requis.
                            </li>
                        <li>J’accepte que, dans l’éventualité d’une vérification ou d’une inspection, les renseignements
                            nécessaires soient transmis aux vérificateurs ou aux autorités sanitaires, le cas échéant.
                            </li>
                        <li>Je sais que je peux me retirer du programme en tout temps, et que ce retrait inclut mon
                            consentement à l’utilisation future de mes renseignements personnels.
                            </li>
                        <li>Je comprends que les renseignements déjà recueillis peuvent être utilisés conformément
                            à la façon et à l’objectif décrits dans la partie Énoncé sur la confidentialité pour le patient
                            et aux lois applicables.
                            </li>
                        <li>Si, en tout temps et pour quelque raison que ce soit, le fournisseur de services pour ma
                            clinique change, j’autorise le transfert de mes renseignements personnels au nouveau
                            fournisseur de services afin de poursuivre ma participation au programme.
                            </li>
                    </ul>      
                </ng-container>
            </ng-container>

            <ng-container *ngIf="showLucentisEnrollmentForm()">
                <ng-container *ngIf="selectedPatient.language === 'en'">
                    <p>
                        <strong>What is the ViaOptaTM ForSight Reimbursement Assistance Program?</strong><br/>
                        The ViaOptaTM ForSight Reimbursement Assistance Program is a patient support program (“Program”)
                        provided by Novartis Pharmaceuticals Canada Inc. and/or its affiliates (collectively “Novartis”, we, us,
                        our) to Canadian patients who have been prescribed Lucentis®. Your health care professional believes
                        you could benefit from the Program. The Program services may include health/disease/product
                        information, insurance reimbursement assistance or treatment services (the “Services”).
                        A third-party service provider is the administrator of the Program: its employees and/or agents
                        handle your Personal Information, which is processed in accordance with privacy laws and Novartis
                        privacy/data protection standards. You will be notified should the administrator of the Program
                        change, including in the case of administration by a Novartis department; your Personal Information
                        will continue to be protected with equivalent safeguards.
                    </p>
                    <p>
                        Your participation in the Program is voluntary. If you choose not to participate, neither your medical
                        treatment nor your insurance coverage eligibility will be impacted. However, if you do not participate,
                        you cannot receive assistance or Services from the Program. The Program is not intended
                        to provide medical advice or medical diagnoses. You agree to seek the advice of your physician
                        or other qualified health care professional if you have health concerns, and not to disregard
                        professional medical advice based on information obtained from the Program. Novartis reserves
                        the right to modify or terminate the Program at any time without prior notice.
                    </p>
                    <p>
                        Why is personal information collected, for which purposes and to whom could it be shared with?
                        Information, such as your date of birth, contact information, drug/medical, and insurance/financial
                        information (collectively “Personal Information”) is collected to communicate with you, provide you
                        with the Program’s Services, audit or monitor the Program, perform certain activities as required
                        or permitted by law, including to process and report adverse events (“AEs”). We may contact you
                        at the contact information you have provided; e-mail, phone or other (if via cellular, we will not
                        assume any resulting cellular phone charges). Only relevant personnel will have access to your
                        Personal Information. Your Personal Information may be collected from and disclosed to health care professionals, insurance providers or other third-parties, as needed for the Program’s administration and Services.
                    </p>
                    <p>
                        Our third-party providers are contractually obliged to strict data protection and security requirements. In the case of AEs processing and reporting to regulatory authorities, if monitoring or auditing
                        is performed, or if required and/or permitted by law, it may be that Novartis employees or agents
                        will have access to your Personal Information. Novartis employees or agents may also have access
                        to your Personal Information to provide you with the service of delivering product to you, your clinic
                        or pharmacist, if you are eligible for such service, as it may be available within the Program. In such
                        case, Novartis employees or agents will only have access to data of your Personal Information needed
                        to manage the distribution and delivery of product. The administrator or Novartis’ agents may de-identify, aggregate (combine with other data) and/or
                        anonymize your Personal Information to conduct analyses for commercial, research/publication purposes or to improve the Program. Your Personal Information may be stored or processed outside of Canada,
                        including for AEs processing and reporting requirements. In such case, Novartis ensures that your
                        Personal Information is protected. Your Personal Information may be subject to the laws of foreign
                        jurisdictions, with a different level of protection than your country of residence.
                    </p>
                    <p>
                        <strong>What happens if I withdraw from the Program?</strong><br/>
                        You may revoke your consent at any time. Withdrawing your consent will result in the termination
                        of your participation in the Program and its Services; no new personal information will be collected;
                        the file containing your Personal Information will be maintained during the term of the Program
                        for monitoring and regulatory purposes; de-identified, aggregated or anonymized data may continue
                        to be used as described above.
                    </p>
                    <p>
                        You may request access or correction to your file by contacting the Novartis Privacy Officer
                        at 385 Bouchard Blvd, Dorval, Québec, H9S 1A9 or privacy.pharmacanada@novartis.com.
                        By signing the consent, you agree to the collection, use and disclosure of your Personal Information
                        as described herein. You can learn more about how Novartis protects privacy at
                        www.novartis.ca/en/privacy-policy.
                    </p>
                </ng-container>
                <ng-container *ngIf="selectedPatient.language === 'fr'">
                    <p>
                        <strong>En quoi consiste le Programme d’aide au remboursement VISION de ViaOptaMC?</strong><br/>
                        Le Programme d’aide au remboursement VISION de ViaOptaMC est un programme de soutien aux
                        patients (le « Programme ») offert par Novartis Pharma Canada Inc. ou ses sociétés affiliées (« Novartis »,« nous », « notre ») qui vise à fournir aux patients canadiens ayant reçu une ordonnance de LucentisMD.
                    </p>
                    <p>
                        Votre professionnel de la santé estime que vous pourriez bénéficier du Programme. Les services du
                        Programme peuvent inclure des renseignements sur la santé, le traitement d’une affection/un produit,
                        une aide en matière de remboursement ou des services de traitement (les « Services »).
                        Un fournisseur de services tiers est l’administrateur du Programme : ses employés et/ou ses agents
                        traitent vos renseignements personnels conformément aux lois sur la confidentialité et aux normes de
                        protection de la vie privée et des données de Novartis. Vous serez avisé dans le cas d’un changement
                        d’administrateur du Programme, y compris dans le cas d’une administration par un service de Novartis;
                        vos renseignements personnels continueront de faire l’objet de mesures de protection équivalentes.
                        Votre participation au Programme est volontaire. Si vous décidez de ne pas y participer, ni votre
                        traitement médical ou votre admissibilité au régime d’assurance-maladie ne sera touché. Toutefois,
                        si vous n’y participez pas, vous ne pouvez pas recevoir d’aide de la part du Programme, ni bénéficier
                        de ses services. Le Programme n’a pas pour but de prodiguer des conseils médicaux ni de poser de
                        diagnostics. Vous convenez de toujours consulter votre médecin ou un autre professionnel de la santé
                        compétent pour tout problème de santé et de ne pas ignorer les conseils médicaux professionnels
                        qui vous sont prodigués au profit des renseignements obtenus dans le cadre du Programme. Novartis
                        se réserve le droit de modifier ou de mettre fin au Programme en tout temps et sans préavis.
                        Pourquoi des renseignements personnels sont-ils recueillis, à quelles fins, et avec qui
                        peuvent-ils être partagés?
                    </p>
                    <p>
                        Des renseignements tels que votre date de naissance, vos coordonnées, les médicaments que vous
                        prenez, les traitements que vous recevez, votre assurance et vos informations financières (collectivement
                        « Renseignements personnels ») sont recueillis dans le but de communiquer avec vous, vous fournir les
                        Services du Programme, vérifier ou surveiller le Programme, réaliser certaines activités aux fins exigées
                        ou autorisées par la loi, y compris pour traiter et signaler des événements indésirables (« EI »). Il peut
                        nous arriver de communiquer avec vous par l’entremise des coordonnées que vous avez fournies, soit
                        par courriel, téléphone ou autre (si nous communiquons avec vous par cellulaire, nous n’assumons
                        aucuns des frais y afférents). Seul le personnel concerné aura accès à vos Renseignements personnels.
                        Vos renseignements personnels recueillis de professionnels de la santé, d’assureurs et/ou d’autres
                        tiers ou divulgués à ceux-ci (p. ex., lorsque nécessaire dans le cadre de l’administration du Programme et des services). Nos fournisseurs tiers sont contractuellement tenus de respecter des exigences strictes
                        en matière de protection des données et de sécurité.
                    </p>
                    <p>
                        Dans le cas du traitement et du signalement d’EI à des organismes de réglementation, si une surveillance
                        ou un audit est effectué, ou si la loi l’exige ou le permet, il se peut que des employés ou des agents
                        de Novartis aient accès à vos renseignements personnels. Les employés ou agents de Novartis peuvent
                        également avoir accès à vos informations personnelles pour vous fournir le service de livraison du produit,
                        à votre clinique ou à votre pharmacien, si vous êtes éligible pour ce service, dans la mesure où il est
                        disponible dans le cadre du Programme. Dans ce cas, les employés ou agents de Novartis n’auront accès
                        qu’à vos renseignements personnels nécessaires à la gestion de la distribution et de la livraison du produit.
                    </p>
                    <p>
                        L’administrateur ou les agents de Novartis peuvent dépersonnaliser, regrouper (combiner avec
                        d’autres données) ou anonymiser vos renseignements personnels pour effectuer des analyses à des
                        fins commerciales, de recherche ou de publication ou pour améliorer le Programme. Vos renseignements
                        personnels peuvent être conservés ou traités à l’extérieur du Canada, y compris pour se conformer
                        aux exigences de signalement des EI et leur traitement. Dans un tel cas, Novartis garantit que vos
                        renseignements personnels sont protégés. Vos renseignements personnels peuvent être assujettis
                        aux lois d’autres pays ayant un autre niveau de protection qui diffère de celui de votre pays de résidence.
                    </p>
                    <p>
                        <strong>Que se passe-t-il si je me retire du Programme?</strong><br/>
                        Vous pouvez révoquer votre consentement à tout moment. Le retrait de votre consentement entraînera
                        la résiliation de votre participation au Programme et l’arrêt de prestation des services; aucun nouveau
                        renseignement ne sera recueilli; le dossier comportant vos renseignements personnels sera conservé
                        pour la durée du Programme à des fins de surveillance et de réglementation; des données anonymisées
                        ou agrégées pourront continuer à être utilisées comme il est décrit ci-dessus.
                        Vous pouvez demander l’accès ou des corrections à votre dossier en communiquant avec l’agent de
                        protection de la vie privée de l’administrateur à 385, boulevard Bouchard, Dorval (Québec) H9S 1A9
                        ou par courriel confidentialite.pharmacanada@novartis.com.
                    </p>
                    <p>
                        En signant le présent formulaire de consentement, vous consentez à la collecte, à l’utilisation
                        et à la divulgation de vos renseignements personnels, comme il est décrit dans le présent document.
                        Vous pouvez en apprendre davantage sur les pratiques de confidentialité de Novartis à l’adresse
                        suivante : www.novartis.ca/fr/confidentialite.
                    </p>
                </ng-container>
            </ng-container>
            </div>
            <mat-card>
                <mat-card-content>
                    <div>
                        <span class="agree-text">{{ agreeText }}</span>
                    </div>
                    <div style="margin-top: 20px; margin-bottom: 20px;">
                        <button class="agree-button" ngDefaultControl mat-raised-button [color]="termsone ? 'primary' : 'light'" [(ngModel)]="termsone" (click)="termsone = !termsone">{{ agreeButton }}</button>
                    </div>
                    <div style="border-top: 2px solid black; border-left: 2px solid black; border-right: 2px solid black; border-bottom: 4px dashed black;">
                        <signature-pad [id]="'d0'" [options]="{'minWidth': 2, 'canvasWidth': 700,'canvasHeight': 200 }"
                            (onBeginEvent)="drawStart(0)" (onEndEvent)="drawComplete(0)"></signature-pad>
                    </div>
                </mat-card-content>
            </mat-card>
         
            <br/><br/>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="warn" [disabled]="!signature" (click)="clearSignature(0);">{{ clearSignatureButton }} </button>
        <button mat-button mat-dialog-close>{{ cancelButton }}</button>
        <button mat-button color="accent" [disabled]="!termsone || !this.signature" (click)="submitConsent()">{{ saveButton }}</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #availableEnrollments>
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle>{{ 'patients.forms.title' | translate }}</h2>
    <mat-dialog-content>
        <!-- <p>
            {{ 'patients.forms.message' | translate }}
        </p> -->
        <div>
            <div class="form-button-container" *ngIf="showPublicSpecialPriorAuthorization()">
                <img src="../../../assets/images/logo_medicare.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #1E4C42 !important; color: white !important;" (click)="createNBSA()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.medicare' | translate }}
                </button>
            </div>
            <div class="form-button-container" *ngIf="showPrivateSpecialPriorAuthorization()">
                <img src="../../../assets/images/logo_medavie_bluecross.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #3094d7 !important; color: white !important;" (click)="createNBSA()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.bluecross' | translate }}
                </button>
            </div>
            <div class="form-button-container" *ngIf="showEyleaEnrollmentForm()">
                <img src="../../../assets/images/eylea_program_logo.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #00C0F3 !important; color: white !important;" (click)="createEyeQEnrolment()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.eyeq' | translate }}
                </button>
            </div>
            <div class="form-button-container" *ngIf="showLucentisEnrollmentForm()">
                <img src="../../../assets/images/lucentis_program_logo.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #E8903D !important; color: white !important;" (click)="createLucentisEnrolment()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.lucentis' | translate }}
                </button>
            </div>
            <div class="form-button-container" *ngIf="showBeovuEnrollmentForm()">
                <img src="../../../assets/images/beovu_program_logo.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #007843 !important; color: white !important;" (click)="createBeovuEnrolment()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.beovu' | translate }}
                </button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #injectionAddConfirmDialog>
    <h2 matDialogTitle><span>{{ 'injectionclinics.addpatient.title' | translate }}</span></h2>
    <mat-dialog-content>
        <div style="margin-bottom: 7px;">
            <span>{{ 'injectionclinics.addpatient.message' | translate }}</span>
        </div>
        <div *ngIf="user.clinic.requiresAppointmentTime" class="ngx-timepicker-field-example">
            <!-- <ngx-timepicker-field [(ngModel)]="appointmentTime" [toggleIcon]="icon" [defaultTime]="'08:00 am'"></ngx-timepicker-field>
            <ng-template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
                    <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"/>
                </svg>
            </ng-template>
        </div>
        <br/>
        <div> -->
            <mat-form-field appearance="outline" class="fc150" >
                <mat-label>{{'injectionclinics.appointmenttime' | translate}}</mat-label>
                <!-- onfocus="this.select()" -->
                <input
                    cdkFocusInitial
                    matInput
                    aria-label="appointment time"
                    [ngxTimepicker]="defaultValue"
                    [(ngModel)]="appointmentTime"
                 />

                 <mat-icon matSuffix>schedule</mat-icon>
            </mat-form-field>
            <ngx-material-timepicker #defaultValue>
            </ngx-material-timepicker>
        </div>
        <div>
            <mat-form-field class="fc150" appearance="outline">
                <mat-label>{{ 'rx.medication' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedPatient.medication" [compareWith]="compareMedications">
                  <mat-option *ngFor="let medication of medications" [value]="medication">{{ medication.name }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div>
            <mat-form-field class="fc150" appearance="outline">
                <mat-label>{{ 'rx.eye' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedPatient.eye">
                  <mat-option *ngFor="let eye of eyes" [value]="eye['value']">{{ user.language === 'fr' ? eye['name_fr'] : eye['name'] }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div>
            <mat-form-field class="fc400" appearance="outline">
                <mat-label>{{ 'rx.notes' | translate }}</mat-label>
                <textarea matInput rows="7" [(ngModel)]="note"></textarea>
              </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.cancel' | translate }}</button>
        <button *ngIf="!user.clinic.requiresAppointmentTime" mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
        <button *ngIf="user.clinic.requiresAppointmentTime" [disabled]="user.clinic.requiresAppointmentTime && !appointmentTime" mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
    </mat-dialog-actions>
</ng-template>
