import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';
import { Group } from '../shared/models/group';
import { Patient } from '../shared/models/patient';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  ngUnsubscribe: Subject<void> = new Subject();

  faxes: any = null;
  subscription: any;
  public loading: boolean = false;

  constructor(
    private db: AngularFirestore,
    private utility: UtilityService
  ) { }

  getFaxes() {
    this.loading = true;
    return this.db.collection(`faxes`, (ref) =>
    ref
      .orderBy('createdAt', 'desc')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getRx(clinicId: string) {
    this.loading = true;
    return this.db.collection(`prescriptions`, (ref) =>
    ref
      .where('clinic_id', '==', clinicId)
      .where('status', '!=', 'Batch')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getOrders() {
    this.loading = true;
    return this.db.collection('orders', (ref) =>
      ref
        .orderBy('createdAt', 'desc')
    ).valueChanges({idField: 'id'})
    .pipe(
      map((orders) => {
        return orders;
      }),
      takeUntil(this.ngUnsubscribe),
      tap(() => this.loading = false)
    );
  }

  dispose() {
    // console.log("Destroy Order Service")
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
