import { Component, OnInit } from '@angular/core';
import { PatientService } from '../patient.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { takeUntil } from 'rxjs/operators';
import { Patient } from 'src/app/shared/models/patient';
import { User } from 'functions/src';
import { DataService } from 'src/app/services/data.service';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { chunk } from 'lodash';
import { UtilityService } from 'src/app/services/utility.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-patient-upload',
  templateUrl: './patient-upload.component.html',
  styleUrls: ['./patient-upload.component.scss']
})
export class PatientUploadComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject();

  loading: boolean = false;
  user: User;
  appSettings: any;
  patients: Patient[] = [];
  header = true;

  constructor(
    public patientService: PatientService,
    private ngxCsvParser: NgxCsvParser,
    private auth: AuthService,
    private db: DataService,
    private afs: AngularFirestore,
    private utility: UtilityService,
    public spinner: SpinnerService
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();

    this.db.getAppSettings()
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(settings => {
      this.appSettings = settings;
    })
  }

  selectCSVFile(fileInputEvent: any)  {
    const csvFile = fileInputEvent.target.files[0];
    this.loading = true;
    this.ngxCsvParser.parse(csvFile, { header: this.header, delimiter: ',' })
    .pipe()
    .subscribe((patients: Array<Patient>) => {
      this.patients = patients.map(patient => this.validatePatientData(patient));
      this.loading = false;
    }, (error: NgxCSVParserError) => {
      // console.log('Error', error);
      this.loading = false;
    });
  }

  validatePatientData(patient: Patient) {
    if (patient.healthcard) {
      patient.last_name = patient.last_name ? patient.last_name : patient['maiden'] ? patient['maiden'] : '';
      patient['insurance_provider'] = {name: "Medicare", name_fr: "Medicare", number_name: "Medicare #", number_name_fr: "Medicare #", type: "Public", provinces: ["NB"]};
    }

    if (patient.dob)  {
      patient.dob = this.validateDOB(patient.dob);
    }
    return patient;
  }

  async uploadPatients() {
    this.spinner.spin$.next(true);
    const createdAt = this.utility.timestamp;
    const createdBy = `${this.user.first_name} ${this.user.last_name}`;
    const createdById = this.user.id;

    // console.log("Patients to upload: ", this.patients.length);
    let count = 1;
    const batches = chunk(this.patients, 200).map(patients => {
      // console.log("Batch: ", count);
      const batch = this.afs.firestore.batch();
      patients.forEach(patient => {
          patient.id = this.afs.createId();
          patient.chart_id = patient.chart_id ? patient.chart_id : null,
          patient.address = patient.address ? patient.address.trim() : null;
          patient.address2 = patient.address2 ? patient.address2.trim() : null;
          patient.city = patient.city ? patient.city.trim() : null;
          patient.province = patient.province ? patient.province.trim() : 'QC';
          patient.postal_code = patient.postal_code ? patient.postal_code.trim() : null;
          patient.phone_number = patient.phone_number ? this.validateE164(patient.phone_number.trim()) : null;
          patient.phone_number_cell = patient.phone_number_cell ? this.validateE164(patient.phone_number_cell.trim()) : null;
          patient.email = patient.email ? patient.email.trim() : null;
          patient.healthcard = patient.healthcard ? patient.healthcard.trim() : null;
          patient.createdAt = createdAt;
          patient.createdBy = createdBy;
          patient.createdById = createdById;
          patient.updatedAt = createdAt;
          patient.updatedBy = createdBy;
          patient.updatedById = createdById;
          patient.full_name = patient.first_name + ' ' + patient.last_name;

          batch.set(this.afs.firestore.doc(`/clinics/${this.user.clinic_id}/patients/${patient.id}`),{...patient});
        });
      count++;
      return batch.commit()
    });
    // console.log("Patient write started");
    await Promise.all(batches)
    // console.log("Patient write complete");
    this.spinner.spin$.next(false);
  }

  validateE164(phone_number: string) {
    if (phone_number.startsWith('+1')) return phone_number;
    if (phone_number.startsWith('+')) return phone_number.replace('+', '+1');
    return `+1${phone_number}`;
  }

  validateDOB(dob: string)  {
    const dobArray = dob.split("-");
    // console.log('DOB', dobArray, dobArray.length);
    if (dobArray.length === 3)  {
      // console.log('Do DOB Validate', dobArray)
      if (dobArray[0].length === 4) {
        return `${dobArray[0]}-${dobArray[1]}-${dobArray[2]}`
      } else {
        return `${dobArray[2]}-${dobArray[1]}-${dobArray[0]}`
      }
    } else {
      return null;
    }
  }

}
