<div>
    <mat-toolbar color="accent">
        <span>Patient Upload</span>
        <span class="fill-space"></span>
        <ng-container *ngIf="patients.length === 0">
            <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="selectCSVFile($event)" accept=".csv"/>
            <button mat-flat-button (click)="csvInput.click()"><mat-icon>file_present</mat-icon>Select Patient File</button>
          </ng-container>
          <ng-container *ngIf="patients.length > 0">
            <button mat-flat-button (click)="uploadPatients()"><mat-icon>upload</mat-icon>Upload {{ patients.length }} Patients</button>
          </ng-container>
    </mat-toolbar>
    <kendo-grid
        [kendoGridBinding]="patients"
        kendoGridSelectBy="id"
        [pageSize]="15"
        [pageable]="true"
        [sortable]="{
            allowUnsort: true,
            mode: 'single'
        }"
        [filterable]="true"
        [groupable]="false"
        [reorderable]="true"
        [resizable]="true"
        [columnMenu]="{ filter: true }"
        [loading]="loading"
    >
    <kendo-grid-messages filterBooleanAll="{{ 'patients.filter.all' | translate }}" filterIsTrue="{{ 'patients.filter.true' | translate }}" filterIsFalse="{{ 'patients.filter.false' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
    </kendo-grid-messages>
    <!-- <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column> -->
    <kendo-grid-column field="last_name" title="{{ 'patients.lastname' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="first_name" title="{{ 'patients.firstname' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="dob" title="{{ 'patients.dob' | translate }}"></kendo-grid-column>
    <!-- <kendo-grid-column field="dob" title="{{ 'patients.dob' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.dob | date: 'yyyy-MM-dd'}}
        </ng-template> 
    </kendo-grid-column> -->
    <!-- <kendo-grid-column field="dob" filter="date" format="{0:yyyy-MM-dd}" title="{{ 'patients.dob' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.dob | date: 'yyyy-MM-dd'}}
        </ng-template>
    </kendo-grid-column> -->
    <kendo-grid-column field="gender" title="{{ 'patients.gender' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="healthcard" title="{{ 'patients.healthcard' | translate }}"></kendo-grid-column>
    <!-- <kendo-grid-column field="phone_number" title="{{ 'patients.phone' | translate }}"></kendo-grid-column> -->
  </kendo-grid>
  </div>    