import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { first, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { InjectionClinic, User } from '../models/user';
import { DataService } from 'src/app/services/data.service';
import { OrderService } from 'src/app/order/order.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateFilterFn } from '@angular/material/datepicker';
import { Location } from '../models/location';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('newPasswordDialog') newPasswordDialog: TemplateRef<any>;
  @ViewChild('showAboutDialog') showAboutDialog: TemplateRef<any>;
  @ViewChild('injectionClinic') injectionClinic: TemplateRef<any>;
  ngUnsubscribe: Subject<void> = new Subject();
  user?: User | undefined;
  userSub: any;
  clinics: any[] = [];
  clinicSub: any;
  locations: Location[] = [];
  injectionClinics: any[] = [];
  injectionClinicsSub: any;
  batchCount: number = 0;
  newPassword: string = '';
  mandatoryPasswordChange: boolean = false;
  version: string;
  releaseDate: string;
  releaseNotes: string[] = [];
  doctors: any;

  isPhone$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isTablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Tablet])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  rxForm: FormGroup;
  doctorControl = new FormControl(null, [
    Validators.required,
  ]);
  clinicDateControl = new FormControl(null, [
    Validators.required,
  ]);
  locationControl = new FormControl(null, [
    Validators.required,
  ]);
  nameControl = new FormControl(null, [
    Validators.required,
  ]);
  injectionClinicDialogRef: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public afAuth: AngularFireAuth,
    public auth: AuthService,
    private router: Router,
    private data: DataService,
    private orderService: OrderService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    public utility: UtilityService
  ) {}

  async ngOnInit()  {
    this.rxForm = new FormGroup({
      name: this.nameControl,
      clinicdate: this.clinicDateControl,
    });
  }
  async ngOnDestroy() {
    // console.log('Disposing of shared UI shell')
    this.userSub.unsubscribe();
    this.user = undefined;
    this.clinicSub.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.userSub = this.auth.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
      this.translate.use(this.user.language);
      this.clinicSub = this.data.getClinics().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clinics => {
        // console.log('Clinics: ', this.clinics)
        this.clinics = clinics;
      })

      this.injectionClinicsSub = this.orderService.getInjectionClinicsByClinic(this.user.clinic_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(injectionclinics => {
        // console.log('Injection Clinics: ', injectionclinics)
        this.injectionClinics = injectionclinics;
      })

      // console.log(this.user.resetPassword)
      if (this.user.resetPassword)  {
        this.mandatoryPasswordChange = true;
        this.changePassword()
      } else {
        this.mandatoryPasswordChange = false;
      }

      this.orderService.getCurrentInjectionClinicBatch(this.user.clinic_id, this.user.injectionclinic?.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(rxBatch => this.batchCount = rxBatch.length);
    });
    this.version = environment.VERSION;
    this.releaseDate = environment.RELEASEDATE;
    // const dbVersion = await this.data.getAppVersion().pipe(first()).toPromise();
    // this.releaseNotes = dbVersion['releasenotes'];
  }

  async logout()  {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    await this.auth.logout();
    this.router.navigate(['/login']).then( (e) => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        // console.log("Navigation has failed!");
      }
    });
  }

  async selectClinic(clinic: any, clinicId: string, clinicName: string, clinicProvince: string)  {
    // console.log(clinicId, clinicName);
    await this.data.updateUserClinic(clinic, clinicId, clinicName, clinicProvince);
    location.reload();
    // await  this.router.navigate(['/patient-list']);
  }

  async chooseLanguage(language: string){
    await this.translate.use(language);
    await this.auth.updateCustomUserData({language: language});
  }

  async changePassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '350px';
    let dialogRef = this.matDialog.open(this.newPasswordDialog, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        if (result === 'yes') {
          this.auth.updatePassword(this.newPassword).then(async () => {
            this.utility.showSnackBar(this.translate.instant('global.newpassword.success'), 2000, 'center', 'top');
          })
          .catch((error) => {
            this.utility.showSnackBar(this.translate.instant('global.error.password'), 2000, 'center', 'top');
          });
        }
      }
    });
  }

  showAbout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.height = '500px';
    let dialogRef = this.matDialog.open(this.showAboutDialog, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  dispose() {
    // console.log('Disposing of shared UI shell')
    this.userSub.unsubscribe();
    this.user = undefined;
    this.clinicSub.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  openCreateInjectionClinic() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    this.injectionClinicDialogRef = this.matDialog.open(this.injectionClinic, dialogConfig);
    this.injectionClinicDialogRef.afterClosed().subscribe((result) => {
    });
  }

  submitInjectionClinic() {
    // const faxnumber = this.appSettings.faxNumber;
    this.orderService.createInjectionClinic(this.user.clinic, this.nameControl.value, this.clinicDateControl.value, this.locationControl.value).then(() => {
      this.utility.showSnackBar("The injection clinic has been created", 2000, 'center', 'top');
      this.injectionClinicDialogRef.close();
    })
    .catch((err) => {
      this.utility.showSnackBar("There was a problem completing the consent.  Please try again", 2000, 'center', 'top');
    })
  }
  

  async selectInjectionClinic(injectionclinic: InjectionClinic)  {
    // console.log(injectionclinic);
    await this.data.updateUserInjectionClinic(injectionclinic);
    location.reload();
  }
}