import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';
import { Group } from '../shared/models/group';
import { Patient } from '../shared/models/patient';
import { DateTime } from 'luxon';
import { Doctor } from '../shared/models/doctor';
import { Observable, Subject } from 'rxjs';
import { User } from '../shared/models/user';
import firebase from "firebase/app";
@Injectable({
  providedIn: 'root'
})
export class InjectionClinicService {
  ngUnsubscribe: Subject<void> = new Subject();
  faxes: any = null;
  subscription: any;
  public loading: boolean = false;

  constructor(
    private db: AngularFirestore,
    private utility: UtilityService
  ) { }

  getOpenInjectionClinicsByClinic(clinicId)  {
    this.loading = true;
    return this.db.collection(`clinics/${clinicId}/injectionclinics`, (ref) =>
      ref
        .where('submitted', '==', false)
        .orderBy('createdAt', 'asc')
    ).valueChanges({idField: 'id'})
    .pipe(
      map((orders) => {
        return orders;
      }),
      takeUntil(this.ngUnsubscribe),
      tap(() => this.loading = false)
    ); 
  }

  async updateInjectionClinicName(user: User, clinicId: string, injectionClinicId: string, name: string) {
    let batch = this.db.firestore.batch();
    const clinicICRef = this.db.doc(`clinics/${clinicId}/injectionclinics/${injectionClinicId}`).ref;
    batch.set(clinicICRef , { name: name}, { merge: true });
    const injectionClinicRef = this.db.doc(`injectionclinics/${injectionClinicId}`).ref;
    batch.set(injectionClinicRef, { name: name } , {merge: true} );

    if (user.injectionclinic) {
      let injectionclinic = user.injectionclinic;
      injectionclinic.name = name;
      const userRef = this.db.doc(`users/${user.id}`).ref;
      batch.set(userRef, { injectionclinic: injectionclinic } , {merge: true} );
    }
    return batch.commit();
  } 

  async deleteInjectionClinic(user: User, clinicId: string, injectionClinicId: string)  {
    const batch = this.db.firestore.batch();
    const clinicICRef = this.db.doc(`clinics/${clinicId}/injectionclinics/${injectionClinicId}`).ref;
    batch.delete(clinicICRef);
    const injectionClinicRef = this.db.doc(`injectionclinics/${injectionClinicId}`).ref;
    batch.delete(injectionClinicRef);
    const userRef = this.db.doc(`users/${user.id}`).ref;
    batch.set(userRef, { injectionclinic: null } , {merge: true} );
    const batchList: Observable<firebase.firestore.QuerySnapshot> = await this.db.collection('prescriptions', ref => ref.where('clinic_id', '==', clinicId).where('injectionclinic.id', '==', injectionClinicId).where('status', '==', 'Batch')).get();
    batchList.pipe(
      mergeMap(rx => rx.docs),
      map((rx: firebase.firestore.QueryDocumentSnapshot) => batch.delete(rx.ref))
    ).toPromise().then(() => batch.commit());  
  }

  dispose() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
