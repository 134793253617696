import { Component, OnInit } from '@angular/core';
import {
  CreateFormGroupArgs,
  EditMode,
  SchedulerEvent,
} from '@progress/kendo-angular-scheduler';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-clinic-schedule',
  templateUrl: './clinic-schedule.component.html',
  styleUrls: ['./clinic-schedule.component.scss'],
})
export class ClinicScheduleComponent implements OnInit {
  public formGroup: FormGroup;
  private currentYear: number;
  selectedDate: Date;
  events: SchedulerEvent[] = [];
  station: string;
  stationName: string;
  testEvents: SchedulerEvent[] = [
    {
      id: 1,
      title: 'John Smith',
      start: new Date('2021-04-26T09:00:00'),
      end: new Date('2021-04-26T09:30:00'),
      recurrenceRule: null,
      // recurrenceRule: 'FREQ=DAILY;COUNT=5;'
    },
    {
      id: 2,
      title: 'Peter George',
      start: new Date('2021-04-26T09:30:00'),
      end: new Date('2021-04-26T10:00:00'),
      recurrenceRule: null,
    },
  ];

  constructor(private formBuilder: FormBuilder) {
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  ngOnInit(): void {
    this.station = 'one';
    this.stationName = 'Station / Nurse One';
    this.currentYear = new Date().getFullYear();
    this.selectedDate = new Date();
    this.events = this.testEvents;
  }

  parseAdjust = (eventDate: string): Date => {
    const date = new Date(eventDate);
    date.setFullYear(this.currentYear);
    return date;
  };

  randomInt = (min, max): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  changeStation(station: string, stationName: string) {
    this.station = station;
    this.stationName = stationName;
  }

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    const dataItem = args.dataItem;
    const isOccurrence = args.mode === EditMode.Occurrence;
    const exceptions = isOccurrence ? [] : dataItem.recurrenceExceptions;

    this.formGroup = this.formBuilder.group(
      {
        id: args.isNew ? this.getNextId() : dataItem.id,
        start: [dataItem.start, Validators.required],
        end: [dataItem.end, Validators.required],
        startTimezone: [dataItem.startTimezone],
        endTimezone: [dataItem.endTimezone],
        isAllDay: dataItem.isAllDay,
        title: dataItem.title,
        description: dataItem.description,
        recurrenceRule: dataItem.recurrenceRule,
        recurrenceId: dataItem.recurrenceId,
        recurrenceExceptions: [exceptions],
      },
      {
        validator: this.startEndValidator,
      }
    );

    return this.formGroup;
  }
  public getNextId(): number {
    const len = this.events.length;

    return len === 0 ? 1 : this.events[this.events.length - 1].id + 1;
  }

  public startEndValidator: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('start').value;
    const end = fg.get('end').value;

    if (start !== null && end !== null && start.getTime() < end.getTime()) {
      return null;
    } else {
      return { range: 'End date must be greater than Start date' };
    }
  };
}
