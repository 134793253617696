import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Patient } from 'src/app/shared/models/patient';
import { User } from 'src/app/shared/models/user';
import { InjectionClinicService } from '../injection-clinic.service';

const createFormGroup = (dataItem) =>
  new FormGroup({
    name: new FormControl(dataItem.name),
    id: new FormControl(dataItem.id, Validators.required),
  });
@Component({
  selector: 'injection-clinic-list',
  templateUrl: './injection-clinic-list.component.html',
  styleUrls: ['./injection-clinic-list.component.scss'],
})
export class InjectionClinicListComponent implements OnInit, OnDestroy {
  @ViewChild('callDeleteConfirmDialog') callDeleteConfirmDialog: TemplateRef<any>;
  ngUnsubscribe: Subject<void> = new Subject();
  faxes: any;
  public gridView: any;
  user: User = <User>{};
  appSettings: any;

  public gridData: any[];
  public formGroup: FormGroup;
  private editedRowIndex: number;
  
  constructor(
    private auth: AuthService,
    public injectionClinicService: InjectionClinicService,
    private utility: UtilityService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private db: DataService
  ) {}

  ngOnInit() {}

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.injectionClinicService.getOpenInjectionClinicsByClinic(this.user.clinic_id).pipe(
      map((clinics) => {
        return clinics;
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(clinics => {
      this.faxes = clinics;
    })

    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;
    });
  }

  ngOnDestroy() {
    console.log('Destroy Injection Clinic Service');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.formGroup = createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const clinic = formGroup.value;
    this.injectionClinicService.updateInjectionClinicName(this.user, this.user.clinic_id, clinic.id, clinic.name).then(() => {
      this.utility.showSnackBar(this.translate.instant('updatesuccess'), 2000, 'center', 'top');
      this.faxes[rowIndex].name = clinic.name;
    })
    .catch((err) => {
      this.utility.showSnackBar(this.translate.instant('updateerror'), 2000, 'center', 'top');
    })
    sender.closeRow(rowIndex);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler({ dataItem }): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              this.injectionClinicService.deleteInjectionClinic(this.user, this.user.clinic_id, dataItem.id).then(() => {
                  this.utility.showSnackBar(this.translate.instant('deletesuccess'), 2000, 'center', 'top');
                })
                .catch((err) => {
                  this.utility.showSnackBar(this.translate.instant('deleteerror'), 2000, 'center', 'top');
                })
            } 
        }
    })
  }

}

import { Directive, ElementRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[popupAnchor]",
  exportAs: "popupAnchor",
})
export class PopupAnchorDirective {
  constructor(public element: ElementRef) {}
}
