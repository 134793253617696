import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataBindingDirective, GridDataResult } from '@progress/kendo-angular-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Roles, User } from 'src/app/shared/models/user';
import { AdminService } from '../../admin-service.service';
import { UserService } from '../user.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('deleteConfirmDialog') deleteConfirmDialog: TemplateRef<any>;
  @ViewChild('addDialog') addDialog: TemplateRef<any>;
  ngUnsubscribe: Subject<void> = new Subject();
  public users: any;
  language: string;
  public gridView: any;
  user: User = <User>{};
  roles: Roles = <Roles>{};
  loading: boolean = false;
  isSavingUser: boolean = false;
  addDialogRef: any;
  public mySelection: string[] = [];
  public sort: SortDescriptor[] = [
    {
        field: 'last_name',
        dir: 'asc'
    },
    {
      field: 'first_name',
      dir: 'asc'
    }
  ];
  private editedRowIndex: number;

  public deleteUserFullName: string;
  public typedUserFullName: string;

  userForm: FormGroup;
  userFirstNameControl = new FormControl('', [Validators.required,]);
  userLastNameControl = new FormControl('', [Validators.required,]);
  userEmailControl = new FormControl(null, [Validators.required, Validators.email,]);
  userLanguageControl = new FormControl(this.translate.currentLang, [Validators.required]);
  userRolesControl = new FormControl('', [Validators.required])
  emailExists: boolean = false;

  matcher = new MyErrorStateMatcher();

  constructor(
    private auth: AuthService,
    public userService: UserService, 
    private adminService: AdminService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private utility: UtilityService,
    private spinner: SpinnerService
    ) { }

  ngOnInit() {
    this.user.roles = this.roles;

    this.userForm = new FormGroup({
      first_name: this.userFirstNameControl,
      last_name: this.userLastNameControl,
      email: this.userEmailControl,
      language: this.userLanguageControl,
      role: this.userRolesControl,
    })
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.language = this.translate.currentLang;
    this.users = this.userService.getUsers(this.user.roles.clinic_admin ? this.user.clinic_id : null).pipe(takeUntil(this.ngUnsubscribe));
    if (this.user.roles.clinic_admin) { this.userRolesControl.disable(); }
    else { this.userRolesControl.enable(); }
    this.userRolesControl.setValue('clinic_admin');
    this.userEmailControl.valueChanges
    .pipe(
      debounceTime(1000)
    )
    .subscribe(async (email) => {
      if (email)  {
        if (email.length > 4 && email.includes('@')) {
          this.userService.getUserByEmailAddress(email).subscribe(emailFromServer => {
            if (emailFromServer.length > 0) {
              this.emailExists = true;
            } else {
              this.emailExists = false;
            }
          })  
        } 
      }
    })
  }

  ngOnDestroy() {
    // console.log('Destroy User View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  displayRole(roles: any): string  {
    if (roles.retina_admin)  {
      return 'Admin';
    } else if (roles.retina_pharmacist)  {
      return 'Pharmacist';
    } else if (roles.retina_reporting) {
      return 'Reporting'
    } else  if (roles.clinic_admin) {
      return 'Clinic'
    } else {
      return '';
    }
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.users, {
        filter: {
            logic: "or",
            filters: [
                {
                    field: 'last_name',
                    operator: 'contains',
                    value: inputValue
                },
                {
                    field: 'first_name',
                    operator: 'contains',
                    value: inputValue
                },
                {
                    field: 'email',
                    operator: 'contains',
                    value: inputValue
                },
                {
                    field: 'clinic_name',
                    operator: 'contains',
                    value: inputValue
                }
            ],
        }
    }).data;

    this.dataBinding.skip = 0;
  }

  public addHandler({sender}) {
    this.closeEditor(sender);

    // this.formGroup = new FormGroup({
    //     ProductID: new FormControl(),
    //     ProductName: new FormControl('', Validators.required),
    //     UnitPrice: new FormControl(0),
    //     UnitsInStock: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
    //     Discontinued: new FormControl(false)
    // });

    // sender.addRow(this.formGroup);
  }

  public editHandler({sender, rowIndex, dataItem}) {
      this.closeEditor(sender);

      // this.formGroup = new FormGroup({
      //     ProductID: new FormControl(dataItem.ProductID),
      //     ProductName: new FormControl(dataItem.ProductName, Validators.required),
      //     UnitPrice: new FormControl(dataItem.UnitPrice),
      //     UnitsInStock: new FormControl(
      //             dataItem.UnitsInStock,
      //             Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
      //     Discontinued: new FormControl(dataItem.Discontinued)
      // });

      this.editedRowIndex = rowIndex;

      // sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({sender, rowIndex}) {
      this.closeEditor(sender, rowIndex);
  }

  public saveHandler({sender, rowIndex, formGroup, isNew, dataItem}) {
    const product = formGroup.value;
    product.key = dataItem.key;
    sender.closeRow(rowIndex);
  }

  public removeHandler({dataItem}) {

  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  public resetData() {

  }

  SendPasswordReset(emailAddress: string) {
    this.auth.sendPasswordResetEmail(emailAddress)
  }

  DeleteUser(user: User) {
    this.deleteUserFullName = user.display_name;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    let dialogRef = this.matDialog.open(this.deleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              if (this.deleteUserFullName === this.typedUserFullName) {
                this.spinner.spin$.next(true);
                this.adminService.deleteFirebaseUser(user.id).then(() => {
                  this.utility.showSnackBar(this.translate.instant('users.deletesuccess'), 2000, 'center', 'top');
                  this.spinner.spin$.next(false);
                })
                .catch((err) => {
                  this.utility.showSnackBar(this.translate.instant('users.error.delete'), 2000, 'center', 'top');
                  this.spinner.spin$.next(false);
                })
              } else {
                this.deleteUserFullName = null;
                this.typedUserFullName = null;
              }
            } 
        }
    })
  }

  AddUser() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    this.addDialogRef = this.matDialog.open(this.addDialog, dialogConfig);
    this.addDialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result !== 'yes') {
              
            } 
        }
    })
  }

  submitUserRequest() {
    this.isSavingUser = true;
    this.spinner.spin$.next(true);
    try {
      let newUser: User = <User>{};
      const u = this.userForm.value;
      newUser.clinic = this.user.clinic;
      newUser.clinic_id = this.user.clinic_id;
      newUser.clinic_name = this.user.clinic_name;
      newUser.clinic_province = this.user.clinic_province;
      newUser.display_name = u.first_name + ' ' + u.last_name;
      newUser.first_name = u.first_name;
      newUser.last_name = u.last_name;
      newUser.display_name = u.first_name + ' ' + u.last_name;
      newUser.language = u.language;
      newUser.email = u.email;
      newUser.phone_number = null;
      newUser.territory = null;
      newUser.resetPassword = true;
      const roles: Roles = <Roles>{};
      roles.clinic_admin = u.role === 'clinic_admin' ? true : false;
      roles.retina_admin = u.role === 'retina_admin' ? true : false;
      roles.retina_pharmacist = u.role === 'retina_pharmacist' ? true : false;
      roles.retina_reporting = u.role === 'retina_reporting' ? true : false;
      newUser.roles = roles;
      // console.log(newUser, u.role);
      this.adminService.createFirebaseUser(newUser)
      .then(() => {
        this.utility.showSnackBar(this.translate.instant('users.addsuccess'), 2000, 'center', 'top');
        this.isSavingUser = false;
        this.userForm.reset();
        this.addDialogRef.close();
        this.spinner.spin$.next(false);
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant('users.error.add'), 2000, 'center', 'top');
        this.spinner.spin$.next(false);
        throw err;
      })
    } catch(error) {
      this.isSavingUser = false;
      // console.log(error);
      this.spinner.spin$.next(false);
    } 
  }
}
