import { Component, OnDestroy, OnInit, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Address, Clinic } from 'src/app/shared/models/clinic';
import { User } from 'src/app/shared/models/user';
import { AdminService } from '../../admin-service.service';
import { UserService } from '../../user/user.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-clinic-list',
  templateUrl: './clinic-list.component.html',
  styleUrls: ['./clinic-list.component.scss'],
})
export class ClinicListComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('deleteConfirmDialog') deleteConfirmDialog: TemplateRef<any>;
  @ViewChild('setupClinicDialog') setupClinicDialog: TemplateRef<any>;
  @ViewChild('clinicSuppliesDialog') clinicSuppliesDialog: TemplateRef<any>;
  @ViewChild('stepper') stepper: MatStepper;
  
  ngUnsubscribe: Subject<void> = new Subject();
  clinics: any;
  public gridView: any;
  user: User = <User>{};
  language: string;
  appSettings: any;
  provinces: any[] = [];
  phoneregex: RegExp = /^\+[1-9]\d{10,14}(\se?x?t?(\d*))?$/
  postalcoderegex: RegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

  addClinicDialogRef: any;
  isSavingClinic: boolean = false;
  selectedClinic: Clinic;
  deleteClinicName: string;
  typedClinicName: string;

  clinicForm: FormGroup;
  clinicNameControl = new FormControl('', [Validators.required,]);
  clinicLanguageControl = new FormControl('fr', [Validators.required,]);
  emailControl = new FormControl(null, [Validators.email,]);
  faxNumberControl = new FormControl('', [Validators.required,Validators.pattern(this.phoneregex)]);
  phoneNumberControl = new FormControl('', [Validators.required,Validators.pattern(this.phoneregex)]);
  addressControl = new FormControl('', [Validators.required,]);
  address2Control = new FormControl();
  cityControl = new FormControl('', [Validators.required,]);
  provinceControl = new FormControl('QC', [Validators.required,]);
  postalCodeControl = new FormControl('', [Validators.required,Validators.pattern(this.postalcoderegex)]);
  notesControl = new FormControl('');

  locationForm: FormGroup;
  doctorForm: FormGroup;
  adminUserForm: FormGroup;
  userFirstNameControl = new FormControl('', [Validators.required,]);
  userLastNameControl = new FormControl('', [Validators.required,]);
  userEmailControl = new FormControl(null, [Validators.required, Validators.email,]);
  userLanguageControl = new FormControl('fr', [Validators.required]);
  emailExists: boolean = false;

  matcher = new MyErrorStateMatcher();

  clinicSupplies: any[] = [];
  lastCategory: string;

  constructor(
    private auth: AuthService,
    public adminService: AdminService,
    private userService: UserService,
    public utility: UtilityService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private db: DataService,
    private fb: FormBuilder,
    private spinner: SpinnerService
  ) {}

  ngOnInit() {
    this.clinicForm = new FormGroup({
      clinic_name: this.clinicNameControl,
      language: this.clinicLanguageControl,
      email: this.emailControl,
      phone_number: this.phoneNumberControl,
      fax_number: this.faxNumberControl,
      address: this.addressControl,
      address2: this.address2Control,
      city: this.cityControl,
      province: this.provinceControl,
      postal_code: this.postalCodeControl,
      notes: this.notesControl,
    });

    this.locationForm = this.fb.group({
      locations: this.fb.array([])
    })

    this.doctorForm = this.fb.group({
      doctors: this.fb.array([this.createDoctorForm()])
    })

    this.adminUserForm = new FormGroup({
      first_name: this.userFirstNameControl,
      last_name: this.userLastNameControl,
      email: this.userEmailControl,
      language: this.userLanguageControl,
    })
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.language = this.translate.currentLang;
    this.clinics = this.adminService.getClinics().pipe(
      map((patients) => {
        // patients.forEach((patient) => {
        //   patient['dob'] = DateTime.fromFormat(patient['dob'], 'yyyy-MM-dd');
        // });
        return patients;
      }),
      takeUntil(this.ngUnsubscribe)
    );

    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;
      this.provinces = settings['activeProvinces'];
    });

    this.userEmailControl.valueChanges
    .pipe(
      debounceTime(1000)
    )
    .subscribe(async (email) => {
      if (email)  {
        if (email.length > 4 && email.includes('@')) {
          this.userService.getUserByEmailAddress(email).subscribe(emailFromServer => {
            if (emailFromServer.length > 0) {
              this.emailExists = true;
            } else {
              this.emailExists = false;
            }
          })  
        } 
      }
    })
  }

  ngOnDestroy() {
    // console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public AddClinic() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.addClinicDialogRef = this.matDialog.open(this.setupClinicDialog, dialogConfig);
    this.addClinicDialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result !== 'yes') {
              this.clinicForm.reset();
              this.locationForm.reset([]);
              this.doctorForm.reset([]);
              this.adminUserForm.reset();
              this.isSavingClinic = false;
            } 
        }
    })
  }

  public EditClinic(clinic: Clinic) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    // // console.log(patient.dob);
    // patient.dob = patient.dob.toString();
    // // console.log(patient.dob);
    // dialogConfig.data = patient;
    // this.matDialog.open(PatientAddComponent, dialogConfig);
  }

  DeleteClinic(clinicId: string, clinicName: string) {
    this.deleteClinicName = clinicName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    let dialogRef = this.matDialog.open(this.deleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
               if (this.deleteClinicName === this.typedClinicName) {
                this.adminService.deleteClinic(clinicId).then(() => {
                  this.utility.showSnackBar(this.translate.instant('clinic.delete.success'), 2000, 'center', 'top');
                })
                .catch((err) => {
                  this.utility.showSnackBar(this.translate.instant('clinic.error.delete'), 2000, 'center', 'top');
                })
               } else {
                this.deleteClinicName = null;
                this.typedClinicName = null;
               }
            } 
        }
    })
  }

  nextClicked(event) {
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
  }

  addLocationForm() {
    (this.locationForm.controls['locations'] as FormArray).push(this.createLocationForm());
  }

  removeLocationForm()  {
    (this.locationForm.controls['locations'] as FormArray).removeAt((this.locationForm.controls['locations'] as FormArray).length - 1);
  }

  createLocationForm()  {
    return this.fb.group({
      name: new FormControl('', [Validators.required]),
      email:  new FormControl('', [Validators.email]),
      phone_number: new FormControl('', [Validators.pattern(this.phoneregex)]),
      fax_number: new FormControl('', [Validators.pattern(this.phoneregex)]),
      address: new FormControl('', [Validators.required]),
      address2: null,
      city: new FormControl('', [Validators.required]),
      province: new FormControl('QC', [Validators.required]),
      postal_code: new FormControl('', [Validators.required, Validators.pattern(this.postalcoderegex)]),
    })
  }

  addDoctorForm() {
    (this.doctorForm.controls['doctors'] as FormArray).push(this.createDoctorForm());
  }

  removeDoctorForm()  {
    (this.doctorForm.controls['doctors'] as FormArray).removeAt((this.doctorForm.controls['doctors'] as FormArray).length - 1);
  }

  createDoctorForm()  {
    return this.fb.group({
      last_name: new FormControl('', [Validators.required]),
      first_name:  new FormControl('', [Validators.required]),
      license_number: new FormControl('', [Validators.required]),
      language: new FormControl('fr', [Validators.required,]),
    })
  }

  async completeSetup() {
    this.isSavingClinic = true;
    this.spinner.spin$.next(true);
    const newClinic = this.clinicForm.value;
    const newDoctors = this.doctorForm.value;
    const newLocations = this.locationForm.value;
    const newAdminUser = this.adminUserForm.value;
    // console.log(newAdminUser);
    let clinic: Clinic = <Clinic>{};
    let address: Address = <Address>{};
    clinic.clinic_name = newClinic.clinic_name;
    address.address = newClinic.address;
    address.address2 = newClinic.address2;
    address.city = newClinic.city;
    address.province = newClinic.province;
    address.postal_code = newClinic.postal_code;
    clinic.address = address;
    clinic.phone_number = newClinic.phone_number;
    clinic.fax_number = newClinic.fax_number;
    clinic.locations = newLocations.locations;
    try {
      const clinicId =  await this.adminService.setupClinic(clinic, newDoctors.doctors, this.user);
      newAdminUser.clinic_id = clinicId;
      newAdminUser.clinic_name = clinic.clinic_name;
      newAdminUser.clinic_province = clinic.address.province;
      newAdminUser.roles.clinic_admin = true;
      newAdminUser.roles.retina_admin = false;
      newAdminUser.roles.retina_reporting = false;
      newAdminUser.roles.retina_pharmacist = false;
      await this.adminService.createFirebaseUser(newAdminUser).then(() => {
        this.clinicForm.reset();
        this.locationForm.reset([]);
        this.doctorForm.reset([]);
        this.adminUserForm.reset();
        this.isSavingClinic = false;
        this.addClinicDialogRef.close();
        this.emailExists = false;
        this.emailControl.setValue('');
        this.utility.showSnackBar(this.translate.instant('clinic.add.success'), 2000, 'center', 'top');
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant('clinic.error.add'), 2000, 'center', 'top');
        throw err;
      })
    } catch(error) {
      this.isSavingClinic = false;
      // console.log(error);
    } finally {
      this.spinner.spin$.next(false);
    }
  }

  updateClinicSupplies(clinic: Clinic)  {
    this.selectedClinic = clinic;
    this.adminService.getSuppliesList()
    .pipe(
      take(1)
    )
    .subscribe(supplies => {
      this.clinicSupplies = supplies;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = '800px';
      let dialogRef = this.matDialog.open(this.clinicSuppliesDialog, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {});
    })
  }

  supplyChange(change: MatSelectionListChange) {
    let supply = change.option.value;
    if (change.option.selected) {
      if(supply.clinics) {
        supply.clinics.push(this.selectedClinic.clinic_id);
      }
      else {
        supply['clinics'] = [];
        supply.clinics.push(this.selectedClinic.clinic_id);
      }
    } else {
      // console.log('Supply deselected');
      if(supply.clinics)  {
        const index: number = supply.clinics.indexOf(this.selectedClinic.clinic_id, 0);
        if (index > -1) {
          supply.clinics.splice(index, 1);
       }
      }
      if(supply.clinics_default)  {
        const index: number = supply.clinics_default.indexOf(this.selectedClinic.clinic_id, 0);
        if (index > -1) {
          supply.clinics_default.splice(index, 1);
        }
      }
    }
    // console.log(supply);
    this.adminService.updateSupply(supply)
    .then(()=>{})
    .catch((err) => {});
  }

  categoryDivider(category: string) {
    if (this.lastCategory !== category) {
      this.lastCategory = category;
      return true;
    } else {
      this.lastCategory = category;
      return false;
    }
  }

}
