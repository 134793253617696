<mat-toolbar color="accent">
    <span>Rx Report</span>
    <span class="fill-space"></span>
    <!-- <button mat-flat-button (click)="AddPatient()">{{ 'patients.newpatient' | translate }}</button> -->
</mat-toolbar>
<kendo-grid
    [kendoGridBinding]="rx | async"
    kendoGridSelectBy="id"
    [pageSize]="15"
    [pageable]="true"
    [sortable]="{
        allowUnsort: true,
        mode: 'single'
    }"
    [sort]="sort"
    [filterable]="true"
    [groupable]="false"
    [reorderable]="true"
    [resizable]="true"
    [columnMenu]="{ filter: true }"
    [loading]="reportService.loading"
>
<kendo-grid-messages filterBooleanAll="{{ 'report.filter.all' | translate }}" filterIsFalse="{{ 'report.filter.unfilled' | translate }}" filterIsTrue="{{ 'report.filter.filled' | translate }}" noRecords="{{ 'global.norecords' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
</kendo-grid-messages>
<!-- <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column> -->
<kendo-grid-column title="{{ 'orders.ordernumber' | translate }}" field="order_number" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem>
           <!--<button style="width: 30px !important;" mat-button color="warn" (click)="DeletePatient(dataItem.id)"><mat-icon>delete</mat-icon></button> -->
        <a mat-button [matMenuTriggerFor]="orderOptionMneu">{{ dataItem.order_number }}<mat-icon>expand_more</mat-icon></a>
        <mat-menu #orderOptionMneu="matMenu">
            <a mat-menu-item mat-menu-item *ngIf="dataItem.documentPdf.url" mat-button color="accent" [href]="dataItem.documentPdf.url" target="_blank">View Rx</a>
            <a mat-menu-item mat-menu-item *ngIf="dataItem.notes" mat-button color="accent" (click)="viewNote(dataItem.notes)">View Note</a>
        </mat-menu>
    </ng-template>
  </kendo-grid-column>
<kendo-grid-column field="location.name" title="{{ 'report.location' | translate }}" [width]="190"></kendo-grid-column>
<kendo-grid-column field="patient.full_name" title="{{ 'report.patientname' | translate }}" [width]="200"></kendo-grid-column>
<kendo-grid-column field="patient.diagnosis" title="{{ 'patients.indication' | translate }}" [width]="175"></kendo-grid-column>
<kendo-grid-column field="doctor.full_name" title="{{ 'report.physician' | translate }}" [width]="200"></kendo-grid-column>
<kendo-grid-column field="medication_name" title="{{ 'report.medication' | translate }}" [width]="200"></kendo-grid-column>
<kendo-grid-column field="patient.eye" title="{{ 'patients.eye' | translate }}" [width]="175"></kendo-grid-column>
<kendo-grid-column field="quantity_numeric" filter="numeric" title="{{ 'report.quantity' | translate }}" [width]="150"></kendo-grid-column>
<kendo-grid-column field="createAt" title="{{ 'report.datereceived' | translate }}" [width]="210">
    <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.createdAt | date: 'yyyy-MM-dd h:mm:ss a' }}
    </ng-template> 
</kendo-grid-column>
<!-- <kendo-grid-column field="filled" filter="boolean" title="{{ 'report.filled' | translate }}" [width]="190">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span translate>{{ dataItem.filled }}</span>
    </ng-template> 
</kendo-grid-column>
<kendo-grid-column field="status" title="{{ 'orders.status' | translate }}" [width]="190">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span translate>{{ dataItem.status }}</span>
    </ng-template>
  </kendo-grid-column> -->
</kendo-grid>

<ng-template #noteDialog>
    <mat-dialog-content>
        {{ selectedNote }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="ok">{{ 'global.ok' | translate }}</button>
    </mat-dialog-actions>
</ng-template>
