<h2 mat-dialog-title class="title" *ngIf="user"><span *ngIf="isEdit">{{ 'patients.editrx' | translate }}</span><span *ngIf="!isEdit">{{ 'patients.addrx' | translate }}</span> - {{ patient.first_name }} {{ patient.last_name }}</h2>
<mat-dialog-content class="mat-typography" *ngIf="language">
  <form [formGroup]="rxForm">
    <!-- <img class="logo" [src]="logo"> -->
    <div>
      <mat-form-field>
        <mat-label>{{ 'rx.doctor' | translate }}</mat-label>
        <mat-select cdkFocusInitial [formControl]="doctorControl" [compareWith]="compareFn">
          <mat-option *ngFor="let doctor of doctors" [value]="doctor">Dr {{ doctor.full_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;    
     <mat-form-field>
        <mat-label>{{ 'rx.location' | translate }}</mat-label>
        <mat-select [formControl]="locationControl">
          <mat-option *ngFor="let location of locations" [value]="location">{{ location.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="patient">{{ patient.first_name }} {{ patient.last_name }} - {{ patient.dob }} - {{ ( patient.gender === 'MALE' || patient.gender === 'M' ? 'patients.male' : 'patients.female' ) | translate }} - {{ patient.diagnosis ? patient.diagnosis : 'No indication'  }}</div>
    <ng-container *ngIf="patient.insurance_provider">
      <div *ngIf="patient.insurance_provider.type === 'Public'" class="healthcard">{{ patient.insurance_provider.name }} - {{ patient.healthcard}}</div>
      <div *ngIf="patient.insurance_provider.type === 'Private'" class="healthcard">{{ patient.insurance_provider.name }}<br/>{{ 'patients.insurancepolicy' | translate }}: {{ patient.insurance_policy }}<span *ngIf="patient.insurance_certificate"> - {{ 'patients.insurancecertificate' | translate }}: {{ patient.insurance_certificate }}</span><span *ngIf="patient.insurance_carrier"> - {{ 'patients.insurancecarrier' | translate }}: {{ patient.insurance_carrier }}</span></div>
    </ng-container>
    <div class="address">
      <div *ngIf="patient.address">{{ patient.address }}</div>
      <div *ngIf="patient.city || patient.province"><span *ngIf="patient.city">{{ patient.city }}</span><span *ngIf="patient.city && patient.province">, </span><span *ngIf="patient.province">{{ patient.province }}</span></div>
      <div *ngIf="patient.postal_code">{{  patient.postal_code }}</div>
      <div *ngIf="patient.phone_number">{{ patient.phone_number }}</div>
    </div>
    <div>
      <mat-form-field class="fc125">
        <mat-label>{{ 'patients.indication' | translate }}</mat-label>
        <mat-select [formControl]="diagnosisControl">
            <mat-option *ngFor="let diag of diagnosis" [value]="diag.value">{{ language === 'fr' ? diag.name_fr : diag.name }}</mat-option>
        </mat-select>
      </mat-form-field> 
      &nbsp;
      <mat-form-field>
        <mat-label>{{ 'rx.medication' | translate }}</mat-label>
        <mat-select [formControl]="medicationControl" [compareWith]="compareMedications">
          <mat-option *ngFor="let medication of medications" [value]="medication">{{ medication.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field color="accent" class="fc100">
        <mat-label>{{ 'rx.eye' | translate }}</mat-label>
        <mat-select [formControl]="eyeControl">
          <mat-option *ngFor="let eye of eyes" [value]="eye['value']">{{ language === 'fr' ? eye['name_fr'] : eye['name'] }}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field class="fc100" *ngIf="lucodes.length > 0">
        <mat-label>LU Code</mat-label>
        <mat-select [formControl]="lucodeControl">
          <mat-option *ngFor="let lucode of lucodes" [value]="lucode">{{ lucode }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div>
      <mat-checkbox color="primary" [formControl]="tobraControl">Tobramycin 1-2 GTTS AE Q4H x 3D POST IN</mat-checkbox>
    </div> -->
    <div>
      <mat-form-field class="fc200">
        <input matInput [formControl]="appointmentDateControl" [matDatepicker]="ad" placeholder="{{ 'rx.appointmentdate' | translate}}">
        <mat-datepicker-toggle matSuffix [for]="ad"></mat-datepicker-toggle>
        <mat-datepicker #ad disabled="false"></mat-datepicker>
      </mat-form-field>
    
</div>
    <div>
      <mat-form-field class="note">
        <mat-label>{{ 'rx.notes' | translate }}</mat-label>
        <textarea matInput class="note_field" [formControl]="notesControl"></textarea>
      </mat-form-field>
    </div>
    <!-- <div *ngIf="this.doctor.signature" class="doctor_signature">
        <img class="signature" [src]="doctor.signature" />
        <div class="doctor">Dr. {{ doctor.first_name }} {{ doctor.last_name }} - Prescriber Signature
        </div>
        <div class="datestamp">{{ datestamp }}</div>
    </div> -->
    <ng-container *ngIf="appSettings">
    <div class="disclaimer_container" *ngIf="appSettings.showDisclaimer">
      <div class="disclaimer" *ngFor="let item of appSettings.rxDisclaimer">
        <p *ngIf="item.type ==='p'">{{ language === 'fr' ? item.value_fr : item.value }}</p>
        <ul *ngIf="item.type ==='l'">
          <ng-container *ngIf="language === 'en'">
            <li *ngFor="let li of item.value">{{ li }} </li>
          </ng-container>
          <ng-container *ngIf="language === 'fr'">
            <li *ngFor="let li of item.value_fr">{{ li }} </li>
          </ng-container>
        </ul>
      </div>
      <div *ngIf="appSettings.rxSubstitution">
        <ng-container *ngIf="language === 'en'">
          {{ appSettings.rxSubstitution }}
        </ng-container>
        <ng-container *ngIf="language === 'fr'">
          {{ appSettings.rxSubstitution_fr }}
        </ng-container>
      </div>
    </div>
  </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'rx.cancel' | translate }}</button>
  <button mat-button [disabled]="!rxForm.valid" color="accent" (click)="addRx()">{{ 'rx.addrxtobatch' | translate }}</button>
</mat-dialog-actions>