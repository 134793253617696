import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DataResult, GroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { map, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Rx } from 'src/app/shared/models/rx';
import { MedicationSummary } from 'src/app/shared/models/medication';
import { User } from 'src/app/shared/models/user';
import { OrderService } from '../../order.service';
import { RxAddComponent } from '../../rx/rx-add/rx-add.component';
import { SignaturePad } from 'angular2-signaturepad';
import { MatStepper } from '@angular/material/stepper';
import { CreatedBy, RxOrder } from 'src/app/shared/models/order';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';
import { DateTime } from 'luxon';
import * as moment from "moment";
import {cloneDeep} from 'lodash';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-rx-list',
  templateUrl: './rx-list.component.html',
  styleUrls: ['./rx-list.component.scss']
})
export class RxListComponent implements OnInit, OnDestroy {
  @ViewChild('callDeleteConfirmDialog') callDeleteConfirmDialog: TemplateRef<any>;
  @ViewChild('orderSubmitConfirmDialog') orderSubmitConfirmDialog: TemplateRef<any>;
  @ViewChild('submitDialog') submitDialog: TemplateRef<any>;
  @ViewChild('viewDialog') viewDialog: TemplateRef<any>;
  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;
  @ViewChild('stepper') stepper: MatStepper;
  ngUnsubscribe: Subject<void> = new Subject();
  user: User;
  order: any[] = [];
  orderdoctors: Doctor[] = [];
  doctorSuppliesList: any[] = [];
  pharmacies: any[] = [];
  summary: any[] = [];
  totalQuantity: number = 0;
  submitDialogRef: any;
  language: string;
  appSettings: any;
  phoneregex: RegExp = /^\+[1-9]\d{10,14}(\se?x?t?(\d*))?$/
  selectedRx: Rx;


  public sort: SortDescriptor[] = [
    {
      field: "injectiondate",
      dir: "asc",
    },
  ];

  public groups: GroupDescriptor[] = [
    { field: 'doctor.full_name', aggregates: [{aggregate: "count", field: 'doctor.full_name'}]}, 
    { field: 'medication_name', aggregates: [{aggregate: "count", field: 'medication_name'}]}, 
    
    //{ field: 'quantity', aggregates: [{aggregate: "count", field: "quantity"}] }
  ];

  orderForm: FormGroup;
  faxControl = new FormControl('', [
    Validators.required,
  ]);
  faxnumberOtherControl = new FormControl('', [Validators.pattern(this.phoneregex)]);
  expectedDeliveryDateControl = new FormControl(null);
  notesControl = new FormControl();
  matcher = new MyErrorStateMatcher();

  public gridView: DataResult;
  constructor(
    private auth: AuthService,
    public orderService: OrderService,
    private matDialog: MatDialog,
    public utility: UtilityService,
    private router: Router,
    private translate: TranslateService,
    private db: DataService,
  ) {
   }

  ngOnInit() {
    this.orderForm = new FormGroup({
      faxnumber: this.faxControl,
      faxnumberother: this.faxnumberOtherControl,
      expectedDeliveryDate: this.expectedDeliveryDateControl,
      notes: this.notesControl
    });
  }

  ngOnDestroy() {
    // console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;

      this.pharmacies = this.appSettings['pharmacies'].filter(p => p.provinces.includes(this.user.clinic_province));
      this.faxControl.setValue(this.pharmacies[0]);
    });

    await this.translate.use(this.user.language).toPromise();
    this.language = this.translate.currentLang;
   // this.orderService.getCurrentInjectionClinicBatch(this.user.clinic_id, this.user.injectionclinic.id)

   this.orderService.getCurrentBatch(this.user.clinic_id, this.user.id)
    .pipe(
      takeUntil(this.ngUnsubscribe))
    .subscribe((prescriptions) => {
      prescriptions.map(r => {
        if (r.appointmentTime)  {
          // const injectionDateTimeString = `${DateTime.fromSeconds(r.injectiondate.seconds).toFormat('yyyy-MM-dd')} ${r.appointmentTime.replace(' ', ':00').toLowerCase()}`;
          // const result = DateTime.fromSQL(injectionDateTimeString)
          var hour = moment(r.appointmentTime, ["h:mm A"]).format("HH");
          var minute = moment(r.appointmentTime, ["h:mm A"]).format("mm");
          const d = DateTime.fromJSDate(r.injectiondate.toDate()).set({
            hour: parseInt(hour),
            minute: parseInt(minute)
          })
          // console.log(d)
          r.injectiondate = d.toJSDate();
        }
      })
      this.order = prescriptions;
    });
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.gridView = process(this.order, { group: this.groups });
  }

  async deleteBatch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              this.orderService.deleteRxBatchByInjectionClinic(this.user.clinic_id, this.user.injectionclinic.id).then(() => {
                this.utility.showSnackBar(this.translate.instant('rx.clearsuccess'), 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar(this.translate.instant('rx.errors.clear'), 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  async viewRx(rx: Rx)  {
    this.selectedRx = rx;
    // console.log(this.selectedRx);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    let dialogRef = this.matDialog.open(this.viewDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.selectedRx = null;
    });
  }

  async deleteRx(rxId: string)  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
        if (result !== undefined) {
            if (result === 'yes' || result === 'oui') {
              this.orderService.deleteRx(rxId).then(() => {
                this.utility.showSnackBar(this.translate.instant('rx.deletesuccess'), 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar(this.translate.instant('rx.errors.delete'), 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  /********* Submit Order *****************/
  async openSubmitOrderDialog() {
    this.orderdoctors = this.getUniqueDoctors();
    this.doctorSuppliesList = [];
    this.summary = [];
    this.totalQuantity = 0;

    const unique = [...new Set(this.order.map((item, idx) => item.medication.name))]; 
    // console.log('Unique medications from order', unique);
    unique.map(medication => {
      // console.log('single medication from order', medication);
      const appSettingsMedication = this.appSettings.medications.find(m => m.name === medication && m.provinces.includes(this.user.clinic_province))
      // console.log('matching database medication', appSettingsMedication);
      const medicationSummary: MedicationSummary = <MedicationSummary>{};
      medicationSummary.name = medication;
      medicationSummary.din = appSettingsMedication.din;
      medicationSummary.name_fr = appSettingsMedication.name_fr ? appSettingsMedication.name_fr : medication;
      medicationSummary.count = this.order.filter(rx => rx.medication.name === medication).length;
      medicationSummary.quantity = this.order.filter(rx => rx.medication.name === medication).reduce((sum, current) => sum + current.quantity_numeric, 0);
      this.summary.push(medicationSummary);
    })
    this.totalQuantity = this.summary.reduce((sum, current) => sum + current.quantity, 0);

    await this.utility.asyncForEach(this.orderdoctors, async (doctor) => {
      // // console.log(doc);
      this.orderService.getDefaultSuppliesByDoctor(doctor.id)
      .pipe(
        take(1),
        map(supplies => {
          supplies.forEach(supply => {
            supply['selected'] = true;
          })
          return supplies
        })
      )
      .subscribe(supplies => {
        const newDocSupply = { doctor: {}, supplies: [] }
        newDocSupply.doctor = doctor;
        newDocSupply.supplies = supplies;
        this.doctorSuppliesList.push(newDocSupply);
      })
    });
    // console.log("Doctor supplies: ", this.doctorSuppliesList);
    this.faxControl.setValue(this.appSettings.pharmacies[0]);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.submitDialogRef = this.matDialog.open(this.submitDialog, dialogConfig);
    this.submitDialogRef.afterOpened().subscribe(result => {
      this.orderdoctors.map((doctor, idx) => {
        // console.log('Order docs: ', doctor)
        if (doctor.signature) this.setExistingSignature(idx, doctor.signature);
      })
    });
    this.submitDialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
          if (result !== 'Cancel') {

          } 
      }
    })
  }

  async submitOrder() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.orderSubmitConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
        if (result !== undefined) {
            if (result === 'yes') {
              let order: RxOrder = <RxOrder>{};
              order.clinic = this.user.clinic;
              order.clinic_id = this.user.clinic_id;
              order.clinic_name = this.user.clinic_name;
              order.clinic_province = this.user.clinic_province;
              order.orderDoctors = this.orderdoctors;
              order.rx = cloneDeep(this.order); 
              order.rx.map(rx => {
                delete rx.doctor.createdAt;
                delete rx.doctor.createdBy;
                delete rx.doctor.createdById;
                delete rx.doctor.updatedAt;
                delete rx.doctor.updatedBy;
                delete rx.doctor.updatedById;
                delete rx.patient.doctor;
                delete rx.patient.last_rx;

                delete rx.clinic['createdAt'];
                delete rx.clinic['createdBy'];
                delete rx.clinic['createdById'];
                delete rx.clinic['updatedAt'];
                delete rx.clinic['updatedBy'];
                delete rx.clinic['updatedById'];
              })
              order.summary = this.summary;
              order.totalQuantity = this.totalQuantity;
              order.defaultSupplies = this.doctorSuppliesList;
              order.status = 'Queued';
              order.type = 'Rx';
              order.complete = false;
              order.pharmacy = this.faxControl.value;
              order.notes = this.notesControl.value ? this.notesControl.value : null;
              order.expectedDeliveryDate = this.expectedDeliveryDateControl.value ? this.expectedDeliveryDateControl.value : null;
              
              let createdBy: CreatedBy = <CreatedBy>{};
              createdBy.id = this.user.id;
              createdBy.name = this.user.display_name;
              order.createdBy = createdBy;
              order.email = this.user.email;

              this.orderService.createRxOrder(order).then(async () => {
                this.submitDialogRef.close();
                await this.router.navigate(['/patient-list']);
                this.utility.showSnackBar(this.translate.instant('rx.submitsuccess'), 2000, 'center', 'top');
              })
              .catch((err) => {
                // console.log('Error: ', err);
                this.utility.showSnackBar(this.translate.instant('rx.errors.submit'), 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  selectionChange(change: MatSelectionListChange) {
    const ary = change.option.value.split('-');
    this.doctorSuppliesList[parseInt(ary[0])].supplies[parseInt(ary[1])].selected = change.option.selected;
  }

  getUniqueDoctors(): Doctor[] {
    let doctors = this.order.map(rx => rx.doctor)
    let uniqueDoctors = [...new Map(doctors.map(item => [item['id'], item])).values()] as Doctor[];
    uniqueDoctors.map(doctor => {
      this.orderService.getDoctor(this.user.clinic_id, doctor.id).subscribe(doc => {
        // console.log('Doc', doc);
        doctor.signature = doc['signature'];
        doctor.license_number = doc['license_number'];
        // console.log(doctor);
        return doctor;
      })
    })
    return uniqueDoctors;
  }

  public comparePharmacies(c1: any, c2: any): boolean {
    if (c1 && c2) {
      return c1.name == c2.name;
    }
    return false;
  }

  nextClicked(event) {
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
  }

  setExistingSignature(index, signature: string) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.fromDataURL(signature);
  }

  clearSignature(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.clear();
    this.orderdoctors[index].signature = null;
  }

  drawStart(index) {
    // console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    // console.log("Ended drawing on signature", index);
    let signaturePad = this.signaturePads.toArray()[index];
    if(!signaturePad.isEmpty())  {
      this.orderdoctors[index].signature = signaturePad.toDataURL();
    }
  }

}
