import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';
import { Group } from '../shared/models/group';
import { Patient } from '../shared/models/patient';
import { DateTime } from 'luxon';
import { Clinic } from '../shared/models/clinic';
import { Doctor } from '../shared/models/doctor';
import { Location } from '../shared/models/location';
import { User } from 'functions/src';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Supply } from '../shared/models/supply';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  clinics: any = null;
  subscription: any;
  public loading: boolean = false;

  constructor(
    private db: AngularFirestore,
    private utility: UtilityService,
    private httpClient: HttpClient
  ) { }

  getClinics() {
    this.loading = true;
    return this.db.collection(`clinics/`, (ref) =>
    ref
      .where('enabled', '==', true)
      .orderBy('clinic_name', 'asc')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getClinic(clinicId: string) {
    this.loading = true;
    return this.db.collection(`clinics/`).doc(clinicId)
    .valueChanges()
    .pipe(
      tap(() => this.loading = false)
    );
  }

  setupClinic(clinic: Clinic, doctors: Doctor[], user: User) {
    const createdAt = this.utility.timestamp;
    const clinicId = this.db.createId();
    clinic.id = clinicId;
    clinic.clinic_id = clinicId;
    clinic.createdAt = createdAt;
    clinic.updatedAt = createdAt;
    clinic.enabled = true;
    clinic.createdBy = user.first_name + ' ' + user.last_name;
    clinic.createdById = user.id;
    clinic.updatedBy = clinic.createdBy
    clinic.updatedById = user.id; 
    const promises = new Array<Promise<any>>();
    let batch = this.db.firestore.batch();
    const clinicDocRef = this.db.doc(`clinics/${clinicId}`).ref;
    batch.set(clinicDocRef, clinic);
    doctors.forEach((doctor) => {
      const doctorId = this.db.createId();
      doctor.createdAt = createdAt;
      doctor.createdBy = user.first_name + ' ' + user.last_name;
      doctor.createdById = user.id;
      doctor.updatedAt = doctor.createdAt;
      doctor.createdBy = doctor.createdBy;
      doctor.createdById = doctor.createdById;
      doctor.full_name = doctor.first_name + ' ' + doctor.last_name;
      batch.set(this.db.doc(`clinics/${clinicId}/doctors/${doctorId}`).ref, doctor);
    });
    promises.push(batch.commit());
    promises.push(this.db.doc(`clinics/${clinicId}`).set(clinic))
    return Promise.all(promises).then(() => {
      return clinicId;
    })
    .catch((error) => {
      throw error;
    })
  }

  editClinic(clinicId: string, clinicData: Clinic) {
      const updatedAt = this.utility.timestamp;
      const patientDoc = { updatedAt, ...clinicData };
      return this.db.doc(`clinics/${clinicId}`).set(clinicData, {merge: true});
  }

  deleteClinic(clinicId)  {
    return this.db.collection(`clinics/`).doc(clinicId).set({enabled: false}, {merge: true});
  }

  async createFirebaseUser(user: any) {
    const password = this.createPassword(10);
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    // console.log(user);
    let body = ''
    body += 'secret=' + encodeURIComponent(environment.GUID);
    body += '&email=' + encodeURIComponent(user.email);
    body += '&language=' + encodeURIComponent(user.language);
    body += '&password=' + encodeURIComponent(password);
    body += '&first_name=' + encodeURIComponent(user.first_name);
    body += '&last_name=' + encodeURIComponent(user.last_name);
    body += '&clinic_id=' + encodeURIComponent(user.clinic_id);
    body += '&clinic_name=' + encodeURIComponent(user.clinic_name);
    body += '&clinic_province=' + encodeURIComponent(user.clinic_province);
    body += '&clinic_admin=' + user.roles.clinic_admin.toString() + '&retina_admin=' + user.roles.retina_admin.toString() + '&retina_reporting=' + user.roles.retina_reporting.toString() + '&retina_pharmacist=' + user.roles.retina_pharmacist.toString();
    // console.log('user post: ', body)
    return this.httpClient.post<any>(`${environment.FIREBASE_API_URL}/createUser`, body, { headers }).toPromise();
  }

  async deleteFirebaseUser(userId: string)  {
    // console.log(userId);
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    let body = ''
    body += 'secret=' + encodeURIComponent(environment.GUID);
    body += '&uid=' + encodeURIComponent(userId);
    return this.httpClient.post<any>(`${environment.FIREBASE_API_URL}/deleteUser`, body, { headers }).toPromise().then(() => {
      return this.db.collection(`users/`).doc(userId).delete();
    })
    .catch((error) => {
      return error;
    })
  }


  createPassword(passwordLength: number): string {
    const allowed = {
        uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
        lowers: "qwertyuiopasdfghjklzxcvbnm",
        numbers: "1234567890",
        symbols: "!@$&"
    }
    
    const getRandomCharFromString = (str) => str.charAt(Math.floor(Math.random() * str.length))
    let pwd = "";
    pwd += getRandomCharFromString(allowed.uppers); //pwd will have at least one upper
    pwd += getRandomCharFromString(allowed.lowers); //pwd will have at least one lower
    pwd += getRandomCharFromString(allowed.numbers); //pwd will have at least one number
    pwd += getRandomCharFromString(allowed.symbols);//pwd will have at least one symbol
    for (let i = pwd.length; i < passwordLength; i++)
        pwd += getRandomCharFromString(Object.values(allowed).join('')); //fill the rest of the pwd with random characters
    return pwd;
  }

  getSuppliesList() {
    this.loading = true;
    return this.db.collection<Supply>(`supplies`, (ref) =>
    ref
      .orderBy('category', 'asc')
      .orderBy('name', 'asc')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getClinicSuppliesList(clinicId: string) {
    this.loading = true;
    return this.db.collection("supplies", ref => 
      ref
        .where("clinics", "array-contains", clinicId)
        .orderBy("category", "asc")
        .orderBy("name", "asc")
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  async updateSupply(supply)  {
    const updatedAt = this.utility.timestamp;
    const doc = { updatedAt, ...supply };
    return await this.db.collection(`supplies`)
    .doc(supply.din)
    .set(doc);
  }

  async setSupply(supply: Supply, user: User) {
    supply.updatedAt = this.utility.timestamp;
    supply.updatedBy = user.display_name;
    supply.updatedById = user.id;
    let createdAt: any = null;
    if (!supply.din)  {
      supply.din = this.db.createId();
      supply.createdAt = supply.updatedAt;
      supply.createdBy = supply.updatedBy;
      supply.createdById = supply.updatedById;
    }
    return this.db.doc(`supplies/${supply.din}`).set({...supply}, {merge: true})
  }

  deleteSupply(supplyId: string)  {
    return this.db.collection(`supplies/`).doc(supplyId).delete();
  }

  async setDoctor(user: User, doctor: Doctor) {
    doctor.updatedAt = this.utility.timestamp;
    doctor.updatedBy = user.display_name;
    doctor.updatedById = user.id;
    let createdAt: any = null;
    if (!doctor.id)  {
      doctor.id = this.db.createId();
      doctor.createdAt = doctor.updatedAt;
      doctor.createdBy = doctor.updatedBy;
      doctor.createdById = doctor.updatedById;
    }
    return this.db.doc(`clinics/${user.clinic_id}/doctors/${doctor.id}`).set({...doctor}, {merge: true})
  }

  getDoctors(clinicId: string) {
    this.loading = true;
    return this.db.collection(`clinics/${clinicId}/doctors`, (ref) =>
    ref
      .orderBy('last_name', 'asc')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  deleteDoctor(clinicId: string, doctorId: string)  {
    return this.db.collection(`clinics/${clinicId}/doctors`).doc(doctorId).delete();
  }

}
