import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DataResult, GroupDescriptor, process } from '@progress/kendo-data-query';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Rx } from 'src/app/shared/models/rx';
import { User } from 'src/app/shared/models/user';
import { OrderService } from '../../order.service';
import { RxAddComponent } from '../../rx/rx-add/rx-add.component';
import { SignaturePad } from 'angular2-signaturepad';
import { MatStepper } from '@angular/material/stepper';
import { CreatedBy, RxOrder, SupplyOrder } from 'src/app/shared/models/order';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Clinic } from 'src/app/shared/models/clinic';
import { Location } from 'src/app/shared/models/location';
import { Subject } from 'rxjs';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-supply-order',
  templateUrl: './supply-order.component.html',
  styleUrls: ['./supply-order.component.scss']
})
export class SupplyOrderComponent implements OnInit, OnDestroy {
  @ViewChild('callDeleteConfirmDialog') callDeleteConfirmDialog: TemplateRef<any>;
  @ViewChild('orderSubmitConfirmDialog') orderSubmitConfirmDialog: TemplateRef<any>;
  @ViewChild('submitDialog') submitDialog: TemplateRef<any>;
  @ViewChild('orderNothingEnteredConfirmDialog') orderNothingEnteredConfirmDialog: TemplateRef<any>;
  
  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;
  @ViewChild('stepper') stepper: MatStepper;

  ngUnsubscribe: Subject<void> = new Subject();
  order: any[] = [];

  user: User;
  orderdoctors: Doctor[] = [];
  submitDialogRef: any;
  doctors: any;
  language: string;
  clinic: Clinic;
  doctor: Doctor = <Doctor>{};
  locations: Location[] = [];
  pharmacies: any[] = [];
  appSettings: any;
  signature: string;
  selectedSupplies: any[] = [];
  phoneregex: RegExp = /^\+[1-9]\d{10,14}(\se?x?t?(\d*))?$/

  public groups: GroupDescriptor[] = [
    { field: 'category', aggregates: [{aggregate: "count", field: 'name'}]}, 
  ];

  orderForm: FormGroup;
  doctorControl = new FormControl(null, [
    Validators.required,
  ]);
  locationControl = new FormControl(null, [
    Validators.required,
  ]);
  faxControl = new FormControl(null, [
    Validators.required,
  ]);
  faxnumberOtherControl = new FormControl(null, [
    Validators.pattern(this.phoneregex),
  ]);
  expectedDeliveryDateControl = new FormControl(null);

  notesControl = new FormControl(null);
  matcher = new MyErrorStateMatcher();
  loading: boolean = false;

  public gridView: DataResult;
  constructor(
    private auth: AuthService,
    public orderService: OrderService,
    private matDialog: MatDialog,
    private utility: UtilityService,
    private router: Router,
    private translate: TranslateService,
    private db: DataService
  ) {
   }

  ngOnInit(): void {
    this.language = this.translate.currentLang;

    this.orderForm = new FormGroup({
      doctor: this.doctorControl,
      location: this.locationControl,
      faxnumber: this.faxControl,
      faxnumberother: this.faxnumberOtherControl,
      expectedDeliveryDate: this.expectedDeliveryDateControl,
      notes: this.notesControl
    });
  }

  ngOnDestroy() {
    // console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;

      this.pharmacies = this.appSettings['pharmacies'].filter(p => p.provinces.includes(this.user.clinic_province));
      this.faxControl.setValue(this.pharmacies[0]);
    });

    this.loading = true;
    this.orderService.getSupplyByClinic(this.user.clinic_id).pipe(
      tap(() => this.loading = false),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(supplies => {
      this.order = supplies;
    })

    this.orderService.getDoctorsByClinic(this.user.clinic_id).subscribe(doctors => {
      this.doctors = doctors;
      if (this.doctors.length === 1) {
        this.doctorControl.setValue(this.doctors[0]);
      }
    })
    
    this.orderService.getClinic(this.user.clinic_id).valueChanges({idField: 'id'}).pipe(take(1)).subscribe(clinic => {
      // console.log("Clinic Info: ", clinic);
      this.clinic = clinic;
      let primaryLocation = <Location>{};
      primaryLocation.name = this.clinic.clinic_name;
      primaryLocation.address = this.clinic.address.address;
      primaryLocation.city = this.clinic.address.city;
      primaryLocation.phone_number = this.clinic.phone_number;
      this.locations.push(primaryLocation);
      this.locations = this.locations.concat(this.clinic.locations);
      // console.log("Locations: ", this.locations);
      this.locationControl.setValue(this.locations[0]);


    });
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.gridView = process(this.order, { group: this.groups });
  }

  async resetOrder() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              this.order.forEach((s) => s.quantity = null);
            } 
        }
    })
  }
 
  /********* Submit Order *****************/
  async openSubmitOrderDialog() {
    this.faxControl.setValue(this.appSettings.pharmacies[0]);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.selectedSupplies = this.order.filter(s => s['quantity'] > 0);
    // console.log(this.selectedSupplies);
    if (this.selectedSupplies.length === 0)  {
      let dialogRef = this.matDialog.open(this.orderNothingEnteredConfirmDialog, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
          // console.log("Dialog close result: ", result);
          if (result !== undefined) {
              if (result !== 'ok') {
  
              } 
          }
      })
    } else {
      dialogConfig.width = '800px';
      this.submitDialogRef = this.matDialog.open(this.submitDialog, dialogConfig);
      this.submitDialogRef.afterOpened().subscribe(result => {
        this.setExistingSignature(0, this.doctorControl.value.signature);
      });
        this.submitDialogRef.afterClosed().subscribe(result => {
          // console.log("Dialog close result: ", result);
          if (result !== undefined) {
              if (result !== 'Cancel') {
                
              } else {
                this.orderForm.reset();
              }
          } else {
            this.orderForm.reset();
          }
      })
    }
  }

  async submitOrder() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.orderSubmitConfirmDialog, dialogConfig);
    
    dialogRef.afterClosed().subscribe(async result => {
        if (result !== undefined) {
            if (result === 'yes') {
              const otherFaxNumber = this.faxnumberOtherControl.value;
              let order: SupplyOrder = <SupplyOrder>{};
              order.clinic = this.user.clinic;
              order.clinic_id = this.user.clinic_id;
              order.clinic_name = this.user.clinic_name;
              order.clinic_province = this.user.clinic_province;
              order.Supplies = this.selectedSupplies;
              order.status = 'Queued';
              order.type = 'Supply';
              order.complete = false;
              order.pharmacy = this.faxControl.value;
              order.doctor = this.doctorControl.value;
              if (this.signature) order.doctor.signature = this.signature;
              order.location = this.locationControl.value;
              order.notes = this.notesControl.value ? this.notesControl.value : null;
              order.expectedDeliveryDate = this.expectedDeliveryDateControl.value ? this.expectedDeliveryDateControl.value : null;
              let createdBy: CreatedBy = <CreatedBy>{};
              createdBy.id = this.user.id;
              createdBy.name = this.user.display_name;
              order.createdBy = createdBy;
              order.email = this.user.email;
              // console.log("Supply order: ", order);
              this.orderService.createSupplyOrder(order).then(async () => {
                this.submitDialogRef.close();
                await this.router.navigate(['/patient-list']);
                this.utility.showSnackBar(this.translate.instant('rx.submitsuccess'), 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar(this.translate.instant('rx.errors.submit'), 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  comparePharmacies(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.name === c2.name : c1 === c2; 
  }

  getUniqueDoctors(): Doctor[] {
    let doctors: Doctor[] = this.order.map(rx => rx.doctor)
    let uniqueDoctors = [...new Map(doctors.map(item => [item['id'], item])).values()];
    return uniqueDoctors;
  }

  showSignature(doctor: Doctor)  {
    if (doctor.signature) {
      this.clearSignature(0);
      this.setExistingSignature(0, doctor.signature);
    } 
    else {
      this.signature = null;
      this.clearSignature(0);
    }
  }

  nextClicked(event) {
    // console.log(event);
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
  }

  setExistingSignature(index, signature: string) {
    let signaturePad = this.signaturePads.toArray()[index];
    this.signature = signature;
    signaturePad.fromDataURL(signature);
  }

  clearSignature(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.clear();
    this.signature = null;
  }

  drawStart(index) {
    // console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    console.log("Ended drawing on signature", signaturePad.toDataURL());
    if(!signaturePad.isEmpty())  {
      this.signature = signaturePad.toDataURL();
    }
  }

}
