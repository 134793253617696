<mat-toolbar color="accent">
    <span>Bookings</span>
    <span class="fill-space"></span>
    <a mat-button [matMenuTriggerFor]="stationMenu"><mat-icon>health_and_safety</mat-icon>{{ stationName }}&nbsp;<mat-icon>expand_more</mat-icon></a>
    <mat-menu #stationMenu="matMenu">
      <a mat-menu-item mat-menu-item (click)="changeStation('one', 'Station / Nurse One')">Station / Nurse One</a>
      <a mat-menu-item mat-menu-item (click)="changeStation('two', 'Station / Nurse Two')">Station / Nurse Two</a>
      <a mat-menu-item mat-menu-item (click)="changeStation('three', 'Station / Nurse Three')">Station / Nurse Three</a>
    </mat-menu>
</mat-toolbar>
<kendo-scheduler [kendoSchedulerBinding]="events"
[selectedDate]="selectedDate"
scrollTime="08:00"
[showWorkHours]="true"
style="height: 600px;"
[kendoSchedulerReactiveEditing]="createFormGroup"
>
<kendo-scheduler-day-view>
</kendo-scheduler-day-view>

<kendo-scheduler-week-view>
</kendo-scheduler-week-view>

<kendo-scheduler-month-view>
</kendo-scheduler-month-view>

<kendo-scheduler-timeline-view>
</kendo-scheduler-timeline-view>

<kendo-scheduler-agenda-view>
</kendo-scheduler-agenda-view>
</kendo-scheduler>