import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  ngUnsubscribe: Subject<void> = new Subject();
  patients: any = null;
  subscription: any;
  public loading: boolean = false;

  constructor(
    private db: AngularFirestore,
  ) { }

  getUsers(clinicId?: string) {
    this.loading = true;
    // console.log(clinicId);
    if (clinicId) {
      return this.db.collection('users', (ref) =>
      ref
        .orderBy('last_name', 'asc')
        .where('roles.clinic_admin', '==', true)
        .where('clinic_id', '==', clinicId)
      )
      .valueChanges()
      .pipe(
        tap(() => this.loading = false)
      );
    } else {
      return this.db.collection('users', (ref) =>
      ref
        .orderBy('last_name', 'asc')
      )
      .valueChanges()
      .pipe(
        tap(() => this.loading = false)
      );
    }
  }

  getUser(userId: string) {
    return this.db.collection(`users`).doc(userId).valueChanges();
  }

  getUserByEmailAddress(emailAddress: string) {
    this.loading = true;
    return this.db.collection('users', (ref) =>
      ref
        .where('email', '==', emailAddress)
        .limit(1)
      )
      .valueChanges()
      .pipe(
        tap(() => this.loading = false)
      );
  }

  deleteUser(userId: string)  {
    return this.db.collection(`users/`).doc(userId).set({enabled: false}, {merge: true});
  }  

  dispose() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
