<div>
    <mat-toolbar color="accent">
        <span>{{ 'injectionclinics.title' | translate }}</span>
        <span class="fill-space"></span>
        <!-- <button mat-flat-button (click)="AddPatient()">{{ 'patients.newpatient' | translate }}</button> -->
    </mat-toolbar>
    <kendo-grid
    (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
      (remove)="removeHandler($event)"
        [kendoGridBinding]="faxes"
        kendoGridSelectBy="id"
        [pageSize]="15"
        [pageable]="true"
        [sortable]="{
            allowUnsort: true,
            mode: 'single'
        }"
        [filterable]="true"
        [groupable]="false"
        [reorderable]="true"
        [resizable]="true"
        [columnMenu]="{ filter: true }"
        [loading]="injectionClinicService.loading"
    >
    <kendo-grid-messages noRecords="{{ 'global.norecords' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
    </kendo-grid-messages>
    <!-- <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column> -->
    <kendo-grid-column field="name" title="Name" [width]="400">
        <ng-template
          kendoGridEditTemplate
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
        >
          <input
            #input
            class="k-textbox"
            [formControl]="formGroup.get(column.field)"
          />
          <!-- <kendo-popup
            [anchor]="input"
            *ngIf="
              formGroup.get(column.field).invalid &&
              !(isNew && formGroup.get(column.field).untouched)
            "
            popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
          >
            <span class="k-icon k-i-warning"></span>
            Product name is required
          </kendo-popup> -->
        </ng-template>
      </kendo-grid-column>
    <!-- <kendo-grid-column field="name" title="{{ 'injectionclinics.name' | translate }}"></kendo-grid-column> -->
    <kendo-grid-column field="clinicDate" title="{{ 'injectionclinics.clinic_date' | translate }}"  [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.clinicDate?.toDate() | date: 'yyyy-MM-dd'}}
        </ng-template> 
    </kendo-grid-column>
    <kendo-grid-column field="createAt" title="{{ 'global.added' | translate }}"  [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.createdAt?.toDate() | date: 'yyyy-MM-dd HH:mm a'}}
        </ng-template> 
    </kendo-grid-column>
    <kendo-grid-command-column title="">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
          <button kendoGridEditCommand>Edit</button>
          <button kendoGridRemoveCommand>Remove</button>
          <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
            {{ isNew ? "Add" : "Update" }}
          </button>
          <button kendoGridCancelCommand>
            {{ isNew ? "Discard changes" : "Cancel" }}
          </button>
        </ng-template>
      </kendo-grid-command-column>
  </kendo-grid>
  </div>    

  <ng-template #callDeleteConfirmDialog>
    <h2 matDialogTitle>{{ 'injectionclinics.delete.title' | translate }}</h2>
    <mat-dialog-content>
        {{ 'injectionclinics.delete.message' | translate }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
    </mat-dialog-actions>
</ng-template>