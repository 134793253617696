import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { first, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Group } from 'src/app/shared/models/group';
import { User } from 'src/app/shared/models/user';
import { PatientService } from '../patient.service';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators, ValidationErrors, AbstractControl, ValidatorFn} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { DataService } from 'src/app/services/data.service';
import { Province } from 'src/app/shared/models/province';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UtilityService } from 'src/app/services/utility.service';
import { Diagnosis } from 'src/app/shared/models/diagnosis';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Medication } from 'src/app/shared/models/medication';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { OrderService } from 'src/app/order/order.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Clinic } from 'src/app/shared/models/clinic';
import {ChangeDetectorRef} from '@angular/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.scss']
})
export class PatientAddComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject();
  pageTitle: string = null;
  isEdit: boolean = false;
  user: User;
  language: string;
  doctors: any;
  groups: Group[] = [];
  medications: any[] = [];
  medicationsFiltered: any[] = [];
  eyes: any[] = [];
  lucodes: string[] = [];
  insuranceProviders: any[] = [];
  appSettings: any;
  provinces: Province[] = [];
  diagnosis: Diagnosis[] = [];
  privateRequired: boolean = false;
  publicRequired: boolean = false;

  patientId: string = null;
  patientForm: FormGroup;
  firstNameControl = new FormControl(null, [
    Validators.required,
  ]);
  lastNameControl = new FormControl(null, [
    Validators.required,
  ]);
  groupControl = new FormControl();
  dobControl = new FormControl(null);
  // dobControl = new FormControl(null, [
  //   Validators.required,
  // ]);
    // healthCardControl = new FormControl('', [
  //   Validators.required,
  // ]);
  healthCardControl = new FormControl(null);
  languageControl = new FormControl('', [
    Validators.required,
  ]);
  genderControl = new FormControl(null, [
    Validators.required,
  ]);
  doctorControl = new FormControl(null, [
    Validators.required,
  ]);
  diagnosisControl = new FormControl('', [
    Validators.required,
  ]);
  medicationControl = new FormControl('', [
    Validators.required,
  ]);
  eyeControl = new FormControl('', [
    Validators.required,
  ]);
  emailControl = new FormControl(null, [
    Validators.email,
  ]);
  phoneNumberControl = new FormControl();
  addressControl = new FormControl('');
  address2Control = new FormControl();
  cityControl = new FormControl('');
  provinceControl = new FormControl('');
  postalCodeControl = new FormControl('');
  notesControl = new FormControl('');
  insuranceProviderControl = new FormControl('');
  chartIdControl = new FormControl('');
  insurancePolicyControl = new FormControl('');
  insuranceCertificateControl = new FormControl('');
  insuranceCarrierControl = new FormControl('');
  insurancePlanMemberNameControl = new FormControl('');

  matcher = new MyErrorStateMatcher();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
    private db: DataService,
    private orderService: OrderService,
    private patientService: PatientService,
    private utility: UtilityService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<PatientAddComponent>,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
    this.patientForm = new FormGroup({
      first_name: this.firstNameControl,
      last_name: this.lastNameControl,
      group: this.groupControl,
      dateofbirth: this.dobControl,
      healthcard: this.healthCardControl,
      gender: this.genderControl,
      language: this.languageControl,
      email: this.emailControl,
      phone_number: this.phoneNumberControl,
      address: this.addressControl,
      address2: this.address2Control,
      city: this.cityControl,
      province: this.provinceControl,
      postal_code: this.postalCodeControl,
      notes: this.notesControl,
      doctor: this.doctorControl,
      diagnosis: this.diagnosisControl,
      medication: this.medicationControl,
      eye: this.eyeControl,
      chart_id: this.chartIdControl,
      insurance_provider: this.insuranceProviderControl,
      insurance_policy: this.insurancePolicyControl,
      insurance_certificate: this.insuranceCertificateControl,
      insurance_carrier: this.insuranceCarrierControl,
      insurance_planmembername: this.insurancePlanMemberNameControl
      // insurance_criteria: this.insuranceCriteriaControl
    }, 
    // { validators: dobHealthcardValidator }
    //{ validators: this.atLeastDOBorHealthcardRequired }
    // this.atLeastOne('dateofbirth','healthcard')
    );
  }

  ngOnDestroy() {
    // console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    await this.db.getAppSettings().subscribe(async settings => {
      this.appSettings = settings;
      this.provinces = settings['activeProvinces'];
      this.diagnosis = settings['diagnosis'];
      settings['medications'].forEach(medication => {
        if (medication.provinces.includes(this.user.clinic_province)) {
          this.medications.push(medication);
        }
      });
      this.eyes = settings['eyes'];
      settings['insurance'].forEach(insuranceProvider => {
        if (insuranceProvider.provinces.includes(this.user.clinic_province)) {
          this.insuranceProviders.push(insuranceProvider);
        }
      });

      this.doctors = await this.orderService.getDoctorsByClinic(this.user.clinic_id).pipe(first()).toPromise();
      if (this.doctors.length === 1) {
        this.doctorControl.setValue(this.doctors[0]);
      }

      this.patientService.getClinicGroups(this.user.clinic_id).valueChanges({idField: 'id'}).pipe(take(1)).subscribe(groups => {
        let defaultGroup: Group = <Group>{};
        defaultGroup.description = null;
        defaultGroup.group_name = this.translate.currentLang === 'fr' ? "Aucun groupe" : "No groups";
        defaultGroup.id = null;
        this.groups.push(defaultGroup);
        if (groups.length > 0)  {
          this.groups = this.groups.concat(groups);
        }
        this.groupControl.setValue(defaultGroup);
      })
  
      if (this.data) {
        const existingPatient = this.data;
        this.isEdit = true;
        this.patientId = existingPatient.id;
        existingPatient.gender = existingPatient.gender === 'M' || existingPatient.gender === 'MALE' || existingPatient.gender === 'Male' ? 'M' : 'F';
        // console.log(this.patientId);
        this.patientForm.patchValue(existingPatient);
        if (existingPatient.dob !== '' && existingPatient.dob !== null) {
          // // console.log(`Date of birth - ${existingPatient.dob}`);
          const dobNew: any = DateTime.fromISO(`${existingPatient.dob}`).toJSDate() //.plus({day: 1}).toJSDate();
          // // console.log(`Parse dob - ${dobNew}`);
          this.dobControl.setValue(dobNew);
        }  
        this.pageTitle = existingPatient.full_name;
        this.adjustValidators();
        // if(existingPatient.insurance_criteria.includes('private')) this.privateRequired = true;
        // if (existingPatient.insurance_provider === 'RAMQ') this.insuranceGroupControl.disable();
      } else {
        this.provinceControl.setValue(this.user.clinic_province);
        this.languageControl.setValue(this.translate.currentLang);
      }
      this.onPatientFormChanges();
    })
  }

  async addPatient(addRx?: boolean, addConsent?: boolean)  {
    // // console.log(this.patientForm.value);
    let dateofbirth = this.dobControl.value;
    // // console.log("DOB from control: ", dateofbirth)
    let dob = DateTime.fromJSDate(dateofbirth).toFormat('yyyy-MM-dd');
    // // console.log("DOB on save: ", dob)
    const patient = this.patientForm.value;
    patient.dob = dob;
    patient.id = this.patientId;
    patient.full_name = patient.first_name + ' ' + patient.last_name;
    delete patient.dateofbirth;
    if (patient.group.group_name === 'No groups' || patient.group.group_name === 'Aucun groupe') {
      patient.group = '';
    }

    if (this.isEdit)  {
      // console.log('Add Rx', addRx)
      patient.updatedBy = this.user.display_name;
      patient.updatedById = this.user.id;
      await this.patientService.editPatient(this.user.clinic_id, patient).then(() => {
        let closeParams = { patientId: patient.id, addRx: false, addConsent: false };
        if (addRx) closeParams.addRx = true;
        if (addConsent) closeParams.addConsent = true;
        this.dialogRef.close(closeParams);
        this.utility.showSnackBar(this.translate.instant('patients.success.edit'), 2000, 'center', 'top');
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant("patients.error.add"), 2000, 'center', 'top');
      })
    } else {
      patient.id = this.afs.createId();
      patient.createdBy = this.user.display_name;
      patient.createdById = this.user.id;
      patient.updatedBy = this.user.display_name;
      patient.updatedById = this.user.id;
      patient.consent = null;
      await this.patientService.addPatient(this.user.clinic_id, patient).then(() => {
        let closeParams = { patientId: patient.id, addRx: false, addConsent: false };
        if (addRx) closeParams.addRx = true;
        if (addConsent) closeParams.addConsent = true;
        this.dialogRef.close(closeParams);
        this.utility.showSnackBar(this.translate.instant('patients.success.add'), 2000, 'center', 'top');
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant("patients.error.add"), 2000, 'center', 'top');
      })
    }
  }

  // checkProviderValue()  {
  //   const provider = this.insuranceProviderControl.value;
  //   if (provider === 'RAMQ') {
  //     // console.log('Disabled insurance group');
  //     this.insuranceGroupControl.setValue(null);
  //     this.insuranceGroupControl.disable();
  //   } else {
  //     // console.log('Enable insurance group');
  //     this.insuranceGroupControl.enable();
  //   }
  // }

  onPatientFormChanges(): void {
    this.ref.detectChanges();
    this.insuranceProviderControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(() => {
      this.adjustValidators();
    });

    // this.dobControl.valueChanges.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // )
    // .subscribe(() => {
    //   const dob = this.dobControl.value;
    //   if (dob) {
    //     this.healthCardControl.setValidators(null);
    //     this.healthCardControl.updateValueAndValidity();
    //   } else {
    //     this.healthCardControl.setValidators([Validators.required]);
    //     this.healthCardControl.updateValueAndValidity();
    //   }
    // });

    // this.healthCardControl.valueChanges.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // )
    // .subscribe(() => {
    //   const healthcard = this.healthCardControl.value;
    //   if (healthcard) {
    //     this.dobControl.setValidators(null);
    //     this.dobControl.updateValueAndValidity();
    //   } else {
    //     this.dobControl.setValidators([Validators.required]);
    //     this.dobControl.updateValueAndValidity();
    //   }
    // });
  }

  adjustValidators()  {
    // this.ref.detectChanges();
    const provider = this.insuranceProviderControl.value;  

    if (provider) {
      const privateValidator = provider.type === 'Private' ? [Validators.required] : null;
      const publicValidator = provider.type === 'Public' ? [Validators.required] : null;
      this.privateRequired = provider.type === 'Private' ? true : false;
      this.publicRequired = provider.type === 'Public' ? true : false;
  
      // this.insurancePolicyControl.setValidators(privateValidator);
      // this.insurancePolicyControl.updateValueAndValidity();
  
      // this.insuranceCertificateControl.setValidators(privateValidator);
      // this.insuranceCertificateControl.updateValueAndValidity();
  
      // this.insuranceCarrierControl.setValidators(privateValidator);
      // this.insuranceCarrierControl.updateValueAndValidity();
  
      if (!this.privateRequired)  {
        this.insurancePolicyControl.setValidators(privateValidator);
        this.insurancePolicyControl.updateValueAndValidity();
    
        this.insuranceCertificateControl.setValidators(privateValidator);
        this.insuranceCertificateControl.updateValueAndValidity();
    
        this.insuranceCarrierControl.setValidators(privateValidator);
        this.insuranceCarrierControl.updateValueAndValidity();
  
        this.insuranceCertificateControl.setValue('');
        this.insuranceCarrierControl.setValue('');
        this.insurancePlanMemberNameControl.setValue('');
      }
  
      if (!this.publicRequired)  {
        this.insurancePolicyControl.setValidators(publicValidator);
        this.insurancePolicyControl.updateValueAndValidity();
    
        this.insuranceCertificateControl.setValidators(null);
        this.insuranceCertificateControl.updateValueAndValidity();
    
        this.insuranceCarrierControl.setValidators(null);
        this.insuranceCarrierControl.updateValueAndValidity();
      }
    } else {
      this.insurancePolicyControl.setValidators(null);
      this.insurancePolicyControl.updateValueAndValidity();
  
      this.insuranceCertificateControl.setValidators(null);
      this.insuranceCertificateControl.updateValueAndValidity();
  
      this.insuranceCarrierControl.setValidators(null);
      this.insuranceCarrierControl.updateValueAndValidity();

      this.insurancePolicyControl.setValue('');
      this.insuranceCertificateControl.setValue('');
      this.insuranceCarrierControl.setValue('');
      this.insurancePlanMemberNameControl.setValue('');

      this.privateRequired = false;
      this.publicRequired = false;
    }
    // this.medicationsFiltered = this.medications.filter(m => m.insuranceType === this.insuranceProviderControl.value.type)


    this.ref.detectChanges();
  }

  public showPolicyLabel()  {
    if (this.insuranceProviderControl.value)  {
      if (this.insuranceProviderControl.value.type === 'Public')  {
        return this.language === 'fr' ? this.insuranceProviderControl.value.number_name_fr : this.insuranceProviderControl.value.number_name;
      }
    }
    return this.language === 'fr' ? 'Policy # FR' : 'Policy #'
  }

  public compareInsuranceProviders(c1: any, c2: any): boolean {
    if (c1 && c2) {
      return c1.name == c2.name;
    }
    return false;
  }

  public compareMedications(c1: Medication, c2: Medication): boolean {
    if (c1 && c2) {
      return c1.din == c2.din;
    }
    return false;
  }

  compareDoctors(c1: Doctor, c2: Doctor): boolean {
    if (c1 && c2) {
      return c1.license_number == c2.license_number;
    }
    return false;
  }

  close() {
    this.dialogRef.close();
  }

async createNBSA() {
  const url = '/assets/forms/medicare_sa.pdf';
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const form = pdfDoc.getForm();
  const doctorFirstName = form.getTextField('First Name');
  const doctorLastName = form.getTextField('Last Name')
  const patientFirstName  = form.getTextField('First Name_1');
  const patientLastName  = form.getTextField('Last Name_1');
  const patientDOB = form.getTextField('Date of Birth DDMMYYYY');
  const medicareNumber  = form.getTextField('undefined');
  const clinicAddress = form.getTextField('Mailing Address Street City Province Postal Code');
  const doctorTelephone = form.getTextField('Telephone');
  const doctorFax = form.getTextField('Fax');
  const drugName = form.getTextField('Drug Name');
  const doseRegimen = form.getTextField('Dose and Regimen');
  const diagnosisIndication = form.getTextField('Diagnosis/Indication/Rational for Use');
  const previousTherapies = form.getTextField('Relevant Previous Drug Therapies');
  const otherInformation = form.getTextField('Other Relevant Information');
  const doctorLicense = form.getTextField('License or Registration Number');
  const formDate = form.getTextField('Date');

  const doctor = this.doctorControl.value as Doctor
  doctorFirstName.setText(doctor.first_name);
  doctorLastName.setText(doctor.last_name);
  doctorLicense.setText(doctor.license_number);
  doctorFax.setText(doctor.fax_number);
  doctorTelephone.setText(doctor.phone_number);
  clinicAddress.setText(
    [
      this.user.clinic.address.address,
      this.user.clinic.address.city,
      this.user.clinic.address.province,
      this.user.clinic.address.postal_code
    ]
    .join('\n')
  );

  patientFirstName.setText(this.firstNameControl.value);
  patientLastName.setText(this.lastNameControl.value);
  patientDOB.setText(this.utility.formatDate(this.dobControl.value, 'DD/MM/YYYY'));
  medicareNumber.setText(this.healthCardControl.value);

  const medName = this.medicationControl.value.name;
  if (medName.includes('Eylea'))  {
    drugName.setText('Eylea');
    doseRegimen.setText('2mg/0.05ml monthly intravitreal injection')
  } else if (medName.includes('Lucentis')) {
    drugName.setText('Lucentis');
    doseRegimen.setText('10mg/ml monthly intravitreal injection')
  } else if (medName.includes('Beovu'))  {
    drugName.setText('Beovu');
    doseRegimen.setText('6 mg/0.05 mL monthly intravitreal injection');
  }

  if (this.diagnosisControl.value === 'wAMD') {
    diagnosisIndication.setText('wet age related macular degeneration');
    otherInformation.setText(
      [
        'Best Corrected Visual Acuity (BCVA) is between 20/40 and 20/320',
        'The lesion size is less than 12 disc areas in greatest linear dimension',
        'There is evidence of recent (less than 3 months) presumed disease progression as indicated by optical coherence tomography (OCT)'
      ]
      .join('\n')
    );
  } else if (this.diagnosisControl.value === 'DME') {
    diagnosisIndication.setText('Diabetic Macular Edema');
    otherInformation.setText(
      [
        'Patient has center-involved macular edema',
        'Best Corrected Visual Acuity is less than 20/32',
        'Central retinal thickness is greater than 250 micrometers'
      ]
      .join('\n')
    );
  } else if (this.diagnosisControl.value === 'CRVO') {
    diagnosisIndication.setText('Retinal Vein Occlusion');
    otherInformation.setText(
      [
        'Patient has center-involved macular edema',
        'Best Corrected Visual Acuity is less than 20/32',
        'Central retinal thickness is greater than 250 micrometers'
      ]
      .join('\n')
    );
  }

  previousTherapies.setText('None');
  formDate.setText(this.utility.formatDate(null, 'DD/MM/YYYY'))

  const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
  const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
  const page = pdfDoc.getPage(0);
  const pngDims = pngImage.scale(0.2)
  page.drawImage(pngImage, {
    x: 30,
    y: 105,
    width: pngDims.width,
    height: pngDims.height,
  });

    // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  // const pages = pdfDoc.getPages()
  // const firstPage = pages[0]
  // const { width, height } = firstPage.getSize()
  // firstPage.drawText('This was a test by Kent', {
  //   x: 5,
  //   y: height / 2 + 300,
  //   size: 50,
  //   font: helveticaFont,
  //   color: rgb(0.95, 0.1, 0.1),
  //   rotate: degrees(-45),
  // })

  const pdfBytes = await pdfDoc.save()
  var file = new Blob([pdfBytes], { type: 'application/pdf' });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
  // const blob = new Blob([pdfBytes]);
  // const fileName = `test.pdf`;
  // const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    // if (link.download !== undefined) {
    //   const url = URL.createObjectURL(blob);
    //   link.setAttribute('href', url);
    //   link.setAttribute('download', fileName);
    //   link.style.visibility = 'hidden';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }
  }

  async createMBCSA() {
    const url = '/assets/forms/bluecross_sa_eylea_lucentis.pdf';
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
  
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
    const form = pdfDoc.getForm();
    const doctorName = form.getTextField('Physician Name');
    // const doctorLicense = form.getTextField('License or Registration Number');
    const doctorTelephone = form.getTextField('Telephone Number_3');
    const doctorFax = form.getTextField('Fax Number_2');

    const patientPlanMemberName  = form.getTextField('Plan Member Name');
    const address = form.getTextField('Address');
    const city = form.getTextField('City');
    const province = form.getTextField('Province');
    const postalCode = form.getTextField('Postal Code');

    const patientName = form.getTextField('Patient Name if not plan member');
    const address2 = form.getTextField('Address_2');
    const city2 = form.getTextField('City_2');
    const province2 = form.getTextField('Province_2');
    const postalCode2 = form.getTextField('Postal Code_2');

    const patientDOB = form.getTextField('Date of Birth');
    // const medicareNumber  = form.getTextField('undefined');
    // const clinicAddress = form.getTextField('Mailing Address Street City Province Postal Code');
    // const drugName = form.getTextField('Drug Name');
    // const doseRegimen = form.getTextField('Dose and Regimen');
    // const diagnosisIndication = form.getTextField('Diagnosis/Indication/Rational for Use');
    // const previousTherapies = form.getTextField('Relevant Previous Drug Therapies');
    // const otherInformation = form.getTextField('Other Relevant Information');

    const formDate = form.getTextField('Date_3');
  
    const doctor = this.doctorControl.value as Doctor
    doctorName.setText(doctor.full_name);
    // doctorLicense.setText(doctor.license_number);
    doctorFax.setText(doctor.fax_number);
    doctorTelephone.setText(doctor.phone_number);
    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const page = pdfDoc.getPage(3);
    const pngDims = pngImage.scale(0.15)
    page.drawImage(pngImage, {
      x: 110,
      y: 40,
      width: pngDims.width,
      height: pngDims.height,
    });
    const pName = this.firstNameControl.value + ' ' + this.lastNameControl.value;
    const planMemberName = this.insurancePlanMemberNameControl.value ? this.insurancePlanMemberNameControl.value : pName; 
    patientPlanMemberName.setText(planMemberName);
    form.getTextField('Telephone Number').setText(this.phoneNumberControl.value ? this.phoneNumberControl.value : 'NA');
    form.getTextField('Policy Number').setText(this.insurancePolicyControl.value);
    form.getTextField('ID Number').setText(this.insuranceCarrierControl.value);

    form.getTextField('Telephone Number_2').setText(this.phoneNumberControl.value ? this.phoneNumberControl.value : 'NA');
    form.getTextField('ID Number_2').setText(this.insuranceCarrierControl.value);
    patientName.setText(pName);
    patientDOB.setText(this.utility.formatDate(this.dobControl.value, 'DD/MM/YYYY'));
    address.setText(this.addressControl.value);
    address2.setText(this.addressControl.value);
    city.setText(this.cityControl.value);
    city2.setText(this.cityControl.value);
    province.setText(this.provinceControl.value);
    province2.setText(this.provinceControl.value);
    postalCode.setText(this.postalCodeControl.value);
    postalCode2.setText(this.postalCodeControl.value);

    let pharmacyName = '';
    let pharmacyAddress = '';
    let pharmacyPhone = '';
    let pharmacyFax = '';
    if (this.user.clinic_province === 'NB') {
      pharmacyName = 'Sentrex Pharmacy Moncton';
      pharmacyPhone = '1 (833) 753-1468';
      pharmacyFax = '1 (833) 478-1407';
    } else if (this.user.clinic_province === 'NS')  {
      pharmacyName = 'Sentrex Pharmacy Halifax';
      pharmacyPhone = '1 (833) 743-0623';
      pharmacyFax = '1 (833) 516-2199';
    } else if (this.user.clinic_province === 'NL')  {
      pharmacyName = 'Sentrex Pharmacy Corner Brook';
      pharmacyPhone = '1 (833) 753-1469';
      pharmacyFax = 'NA';
    } else if (this.user.clinic_province === 'ON')  {
      pharmacyName = '';
      pharmacyPhone = '';
      pharmacyFax = '';
    }
    form.getCheckBox('Fax').uncheck;
    form.getCheckBox('Telephone').check;
    form.getTextField('Name of ProgramPharmacy').setText(pharmacyName);
    form.getTextField('Contact Number').setText(pharmacyPhone);
    form.getTextField('Fax Number').setText(pharmacyFax);

    // const medName = this.medicationControl.value.name;
    // if (medName.includes('Eylea'))  {
    //   drugName.setText('Eylea');
    //   doseRegimen.setText('2mg/0.05ml monthly intravitreal injection')
    // } else if (medName.includes('Lucentis')) {
    //   drugName.setText('Lucentis');
    //   doseRegimen.setText('10mg/ml monthly intravitreal injection')
    // } else if (medName.includes('Beovu'))  {
    //   drugName.setText('Beovu');
    //   doseRegimen.setText('6 mg/0.05 mL monthly intravitreal injection');
    // }
  
    // if (this.diagnosisControl.value === 'wAMD') {
    //   diagnosisIndication.setText('wet age related macular degeneration');
    //   otherInformation.setText(
    //     [
    //       'Best Corrected Visual Acuity (BCVA) is between 20/40 and 20/320',
    //       'The lesion size is less than 12 disc areas in greatest linear dimension',
    //       'There is evidence of recent (less than 3 months) presumed disease progression as indicated by optical coherence tomography (OCT)'
    //     ]
    //     .join('\n')
    //   );
    // } else if (this.diagnosisControl.value === 'DME') {
    //   diagnosisIndication.setText('Diabetic Macular Edema');
    //   otherInformation.setText(
    //     [
    //       'Patient has center-involved macular edema',
    //       'Best Corrected Visual Acuity is less than 20/32',
    //       'Central retinal thickness is greater than 250 micrometers'
    //     ]
    //     .join('\n')
    //   );
    // } else if (this.diagnosisControl.value === 'CRVO') {
    //   diagnosisIndication.setText('Retinal Vein Occlusion');
    //   otherInformation.setText(
    //     [
    //       'Patient has center-involved macular edema',
    //       'Best Corrected Visual Acuity is less than 20/32',
    //       'Central retinal thickness is greater than 250 micrometers'
    //     ]
    //     .join('\n')
    //   );
    // }
  
    // previousTherapies.setText('None');
    formDate.setText(this.utility.formatDate(null, 'DD/MM/YYYY'))
  
      // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    // const pages = pdfDoc.getPages()
    // const firstPage = pages[0]
    // const { width, height } = firstPage.getSize()
    // firstPage.drawText('This was a test by Kent', {
    //   x: 5,
    //   y: height / 2 + 300,
    //   size: 50,
    //   font: helveticaFont,
    //   color: rgb(0.95, 0.1, 0.1),
    //   rotate: degrees(-45),
    // })
  
    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    // const blob = new Blob([pdfBytes]);
    // const fileName = `test.pdf`;
    // const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      // if (link.download !== undefined) {
      //   const url = URL.createObjectURL(blob);
      //   link.setAttribute('href', url);
      //   link.setAttribute('download', fileName);
      //   link.style.visibility = 'hidden';
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // }
  }

  async createEyeQEnrolment() {
    const url = '/assets/forms/eylea_enrolment.pdf';
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    // const fields = form.getFields()
    // fields.forEach(field => {
    //   const name = field.getName()
    //   // console.log('Field name:', name)
    // })

    if (this.diagnosisControl.value === 'wAMD') {
      form.getCheckBox('Check Box wAMD 2').check();
    } else if (this.diagnosisControl.value === 'DME') {
      form.getCheckBox('Check Box DME 2').check();
    } else if (this.diagnosisControl.value === 'CRVO') {
      form.getCheckBox('Check Box 22').check();
    } else if (this.diagnosisControl.value === 'BRVO') {
      form.getCheckBox('Check Box BRVO 2').check();
    } else if (this.diagnosisControl.value === 'mCNV') {
      form.getCheckBox('Check Box mCNV 2').check();
    }

    if (this.insuranceProviderControl.value.type === 'Public')  {
      form.getCheckBox('Check Box Public Coverage 2').check();
    } else {
      form.getCheckBox('Check Box 23').check();
    }

    if (this.eyeControl.value === 'OD') {
      form.getCheckBox('Check Box Right Eye').check();
      form.getCheckBox('Check Box 11').check();
    } else if (this.eyeControl.value === 'OS') {
      form.getCheckBox('Check Box 14').check();
      form.getCheckBox('Check Box 11').check();
    } else {
      form.getCheckBox('Check Box 15').check();
      form.getCheckBox('Check Box 2 vials').check();
    }
    
    form.getTextField('Text Field Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    form.getTextField('Text Field Consent Verbal Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    form.getTextField('Text Field Consent Injection Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));

    form.getTextField('Text Field Patient Name').setText(this.firstNameControl.value + ' ' + this.lastNameControl.value);
    form.getTextField('Text Field Patient Birthday').setText(this.utility.formatDate(this.dobControl.value, 'MM-DD-YYYY'));
    form.getTextField('Text Field Patient Address Line 1').setText(this.addressControl.value);
    form.getTextField('Text Field Patient Address Line 2').setText(this.cityControl.value + ' ' + this.provinceControl.value + ', ' + this.postalCodeControl.value);
    form.getCheckBox('Check Box Phone').check();
    if (this.insuranceProviderControl.value.type === 'Private') {
      form.getTextField('Text Field Patient Private Insurer').setText(this.insuranceProviderControl.value.name);
    }
    form.getTextField('Text Field Patient Phone').setText(this.phoneNumberControl.value ? this.phoneNumberControl.value : '');
    form.getTextField('Text Field Patient Email').setText(this.emailControl.value ? this.emailControl.value : '');

    if (this.languageControl.value === 'en')  {
      form.getCheckBox('Check Box English').check();
    } else {
      form.getCheckBox('Check Box French').check();
    }

    const doctor = this.doctorControl.value as Doctor
    form.getTextField('Text Field Consent Verbal Name').setText(doctor.full_name);
    form.getTextField('Text Field Prescribing Physician').setText(doctor.full_name);
    form.getTextField('Text Field Clinic').setText(this.user.clinic.clinic_name + ', ' + this.user.clinic.address.address + ', ' + this.user.clinic.address.city + ' ' + this.user.clinic.address.province + ', ' + this.user.clinic.address.postal_code);
    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const page = pdfDoc.getPage(0);
    const pngDims = pngImage.scale(0.15)
    page.drawImage(pngImage, {
      x: 70,
      y: 380,
      width: pngDims.width,
      height: pngDims.height,
    });
    page.drawImage(pngImage, {
      x: 100,
      y: 40,
      width: pngDims.width,
      height: pngDims.height,
    });
    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  async createLucentisEnrolment() {
    const url = '/assets/forms/lucentis_enrolment.pdf';
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    // const fields = form.getFields()
    // fields.forEach(field => {
    //   const name = field.getName()
    //   // console.log('Field name:', name)
    // })

    if (this.diagnosisControl.value === 'wAMD') {
      form.getCheckBox('Check Box4').check();
    } else if (this.diagnosisControl.value === 'DME') {
      form.getCheckBox('Check Box5').check();
    } else if (this.diagnosisControl.value === 'CRVO') {
      form.getCheckBox('Check Box6').check();
    } else if (this.diagnosisControl.value === 'BRVO') {
      form.getCheckBox('Check Box7').check();
    } else if (this.diagnosisControl.value === 'mCNV') {
      form.getCheckBox('Check Box8').check();
    }

    if (this.insuranceProviderControl.value.type === 'Public')  {
      form.getTextField('Healthcard Number').setText(this.healthCardControl.value);
    } else {
      form.getRadioGroup('YN3').select("Choice1");
    }

    if (this.eyeControl.value === 'OD') {
      form.getCheckBox('Check Box11').check();
      form.getCheckBox('Check BoxA5').check();
    } else if (this.eyeControl.value === 'OS') {
      form.getCheckBox('Check Box10').check();
      form.getCheckBox('Check BoxA4').check();
    } else {
      form.getCheckBox('Check Box10').check();
      form.getCheckBox('Check Box11').check();
      form.getCheckBox('Check BoxA4').check();
      form.getCheckBox('Check BoxA5').check();
    }
    
    // form.getTextField('Text Field Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Verbal Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Injection Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));

    form.getTextField('Patient Surname').setText(this.lastNameControl.value);
    form.getTextField('Patient First Name').setText(this.firstNameControl.value);
    form.getTextField('Date of Birth 1').setText(this.utility.formatDate(this.dobControl.value, 'MM-DD-YYYY'));
    form.getTextField('Patient Address').setText(this.addressControl.value);
    form.getTextField('Patient City').setText(this.cityControl.value);
    form.getTextField('Patient Prov').setText(this.provinceControl.value);
    form.getTextField('Patient Postal Code').setText(this.postalCodeControl.value);
    form.getCheckBox('Check Box3').check();

    if (this.genderControl.value === 'F') {
      form.getRadioGroup('Gender').select("Choice1");
    } else {
      form.getRadioGroup('Gender').select("Choice2");
    }
    // form.getTextField('Text Field Patient Address Line 2').setText(this.cityControl.value + ' ' + this.provinceControl.value + ', ' + this.postalCodeControl.value);
    // form.getCheckBox('Check Box Phone').check();
    // if (this.insuranceProviderControl.value.type === 'Private') {
    //   form.getTextField('Text Field Patient Private Insurer').setText(this.insuranceProviderControl.value.name);
    // }
    form.getTextField('Phone Number 1').setText(this.phoneNumberControl.value ? this.phoneNumberControl.value : '');
    if (this.emailControl.value)  {
      form.getCheckBox('Check Box2').check();
      form.getTextField('Email Address 1').setText(this.emailControl.value ? this.emailControl.value : '');
    }
    // form.getTextField('Text Field Patient Email').setText(this.emailControl.value ? this.emailControl.value : '');

    // if (this.languageControl.value === 'en')  {
    //   form.getCheckBox('Check Box English').check();
    // } else {
    //   form.getCheckBox('Check Box French').check();
    // }

    form.getRadioGroup('Dose').select("Choice1");
    form.getTextField('Patient Name 2').setText(this.firstNameControl.value + ' ' + this.lastNameControl.value);
    form.getTextField('Date 5').setText(this.utility.formatDate(this.dobControl.value, 'MM-DD-YYYY'));
    form.getCheckBox('Check BoxA2').check();
    const doctor = this.doctorControl.value as Doctor
    form.getTextField('Physician Name').setText(doctor.full_name);
    form.getTextField('License Number').setText(doctor.license_number);
    form.getTextField('Physician Address').setText(this.user.clinic.address.address);
    form.getTextField('City 2').setText(this.user.clinic.address.city);
    form.getTextField('Prov 2').setText(this.user.clinic.address.province);
    form.getTextField('Code 2').setText(this.user.clinic.address.postal_code);
    form.getTextField('Phone Number 2').setText(doctor.phone_number);
    form.getTextField('Phone Number 3').setText(doctor.fax_number);
    // + ', ' + this.user.clinic.address.city + ' ' + this.user.clinic.address.province + ', ' + this.user.clinic.address.postal_code);
    form.getTextField('Date 6').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const page = pdfDoc.getPage(0);
    const pngDims = pngImage.scale(0.25)
    page.drawImage(pngImage, {
      x: page.getWidth() / 2 + 30,
      y: 140,
      width: pngDims.width,
      height: pngDims.height,
    });
    const page2 = pdfDoc.getPage(1);
    const pngDims2 = pngImage.scale(0.15)
    page2.drawImage(pngImage, {
      x: 60,
      y: 190,
      width: pngDims2.width,
      height: pngDims2.height,
    });

    let pharmacyName = '';
    let pharmacyAddress = '';
    let pharmacyCity = '';
    let pharmacyProvince = '';
    let pharmacyPostalCode = '';
    let pharmacyPhone = '';
    let pharmacyFax = '';
    if (this.user.clinic_province === 'NB') {
      pharmacyName = 'Sentrex Pharmacy Moncton';
      pharmacyAddress = '3–240 Commerce Street';
      pharmacyCity = 'Moncton';
      pharmacyProvince = 'NB';
      pharmacyPostalCode = 'E1H 2G2';
      pharmacyPhone = '1 (833) 753-1468';
      pharmacyFax = '1 (833) 478-1407';
    } else if (this.user.clinic_province === 'NS')  {
      pharmacyName = 'Sentrex Pharmacy Halifax';
      pharmacyAddress = '3H–110 Chain Lake Drive';
      pharmacyCity = 'Halifax';
      pharmacyProvince = 'NS';
      pharmacyPostalCode = 'B3S 1A9';
      pharmacyPhone = '1 (833) 743-0623';
      pharmacyFax = '1 (833) 516-2199';
    } else if (this.user.clinic_province === 'NL')  {
      pharmacyName = 'Sentrex Pharmacy Corner Brook';
      pharmacyAddress = '4 Grenfell Drive, Suite 101';
      pharmacyCity = 'Corner Brook';
      pharmacyProvince = 'NL';
      pharmacyPostalCode = 'A2H 0J6';
      pharmacyPhone = '1 (833) 753-1469';
      pharmacyFax = 'NA';
    } else if (this.user.clinic_province === 'ON')  {
      pharmacyName = '';
      pharmacyAddress = '';
      pharmacyPhone = '';
      pharmacyFax = '';
    }
    form.getTextField('Name of Pharmacy').setText(pharmacyName);
    form.getTextField('Pharmacy Address').setText(pharmacyAddress);
    form.getTextField('City 4').setText(pharmacyCity);
    form.getTextField('Prov 4').setText(pharmacyProvince);
    form.getTextField('Code 4').setText(pharmacyPostalCode);
    form.getTextField('Phone Number 4').setText(pharmacyPhone);
    form.getTextField('Phone Number 5').setText(pharmacyFax);
    form.getTextField('Shipping').setText('Same as pharmacy address above');

    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  async createBeovuEnrolment() {
    const url = '/assets/forms/beovu_enrolment.pdf';
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    const fields = form.getFields()
    fields.forEach(field => {
      const name = field.getName()
      // console.log('Field name:', name)
    })

    if (this.diagnosisControl.value === 'wAMD') {
      form.getCheckBox('Check Box 6').check();
    // } else if (this.diagnosisControl.value === 'DME') {
    //   form.getCheckBox('Check Box5').check();
    // } else if (this.diagnosisControl.value === 'CRVO') {
    //   form.getCheckBox('Check Box6').check();
    // } else if (this.diagnosisControl.value === 'BRVO') {
    //   form.getCheckBox('Check Box7').check();
    // } else if (this.diagnosisControl.value === 'mCNV') {
    //   form.getCheckBox('Check Box8').check();
    }

    if (this.insuranceProviderControl.value.type === 'Private')  {
      form.getCheckBox('Check Box 7').check();
    } else {
      form.getTextField('Health Card Number').setText(this.healthCardControl.value);
    }

    if (this.eyeControl.value === 'OD') {
      form.getCheckBox('Check Box 9').check();
      form.getCheckBox('Check Box 17').check();
    } else if (this.eyeControl.value === 'OS') {
      form.getCheckBox('Check Box 8').check();
      form.getCheckBox('Check Box 16').check();
    } else {
      form.getCheckBox('Check Box 8').check();
      form.getCheckBox('Check Box 9').check();
      form.getCheckBox('Check Box 16').check();
      form.getCheckBox('Check Box 17').check();
    }
    form.getCheckBox('Check Box 18').check();
    
    // form.getTextField('Text Field Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Verbal Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    // form.getTextField('Text Field Consent Injection Date').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));

    form.getTextField('Text Field 4').setText(this.lastNameControl.value + ' ' + this.firstNameControl.value);
    form.getTextField('Patient Name').setText(this.lastNameControl.value + ' ' + this.firstNameControl.value);
    form.getTextField('Date of Birth').setText(this.utility.formatDate(this.dobControl.value, 'MM-DD-YYYY'));
    form.getTextField('Date of Birth_2').setText(this.utility.formatDate(this.dobControl.value, 'MM-DD-YYYY'));
    form.getTextField('Address').setText(this.addressControl.value);
    form.getTextField('City').setText(this.cityControl.value);
    form.getTextField('Province').setText(this.provinceControl.value);
    form.getTextField('Postal Code').setText(this.postalCodeControl.value);
    form.getCheckBox('Check Box5').check();

    if (this.genderControl.value === 'F') {
      form.getCheckBox('Check Box3').check();
    } else {
      form.getCheckBox('Check Box4').check();
    }
    // form.getTextField('Text Field Patient Address Line 2').setText(this.cityControl.value + ' ' + this.provinceControl.value + ', ' + this.postalCodeControl.value);
    // form.getCheckBox('Check Box Phone').check();
    // if (this.insuranceProviderControl.value.type === 'Private') {
    //   form.getTextField('Text Field Patient Private Insurer').setText(this.insuranceProviderControl.value.name);
    // }
    form.getTextField('Telephone Number').setText(this.phoneNumberControl.value ? this.phoneNumberControl.value : '');
    if (this.emailControl.value)  {
      form.getCheckBox('Check Box 2').check();
      form.getTextField('Email').setText(this.emailControl.value ? this.emailControl.value : '');
    }
    form.getCheckBox('Check Box 12').check();
    // form.getTextField('Text Field Patient Email').setText(this.emailControl.value ? this.emailControl.value : '');

    // if (this.languageControl.value === 'en')  {
    //   form.getCheckBox('Check Box English').check();
    // } else {
    //   form.getCheckBox('Check Box French').check();
    // }

    form.getCheckBox('Check Box 14').check();
    form.getTextField('Patient Name').setText(this.firstNameControl.value + ' ' + this.lastNameControl.value);
    form.getTextField('Date of next injection').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    const doctor = this.doctorControl.value as Doctor
    form.getTextField('Physician Name').setText(doctor.full_name);
    form.getTextField('Physician License Registration No').setText(doctor.license_number);
    form.getTextField('Address_2').setText(this.user.clinic.address.address);
    form.getTextField('City_2').setText(this.user.clinic.address.city);
    form.getTextField('Province_2').setText(this.user.clinic.address.province);
    form.getTextField('Postal Code_2').setText(this.user.clinic.address.postal_code);
    form.getTextField('Telephone Number_2').setText(doctor.phone_number);
    form.getTextField('Fax Number').setText(doctor.fax_number);

    form.getTextField('Date_2').setText(this.utility.formatDate(null, 'MM-DD-YYYY'));
    const doctorSignatureImageBytes = await fetch(doctor.signature).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(doctorSignatureImageBytes)
    const page = pdfDoc.getPage(0);
    const pngDims = pngImage.scale(0.25)
    page.drawImage(pngImage, {
      x: page.getWidth() / 2 + 30,
      y: 140,
      width: pngDims.width,
      height: pngDims.height,
    });
    const page2 = pdfDoc.getPage(1);
    const pngDims2 = pngImage.scale(0.15)
    page2.drawImage(pngImage, {
      x: 30,
      y: 115,
      width: pngDims2.width,
      height: pngDims2.height,
    });

    let pharmacyName = '';
    let pharmacyAddress = '';
    let pharmacyCity = '';
    let pharmacyProvince = '';
    let pharmacyPostalCode = '';
    let pharmacyPhone = '';
    let pharmacyFax = '';
    if (this.user.clinic_province === 'NB') {
      pharmacyName = 'Sentrex Pharmacy Moncton';
      pharmacyAddress = '3–240 Commerce Street';
      pharmacyCity = 'Moncton';
      pharmacyProvince = 'NB';
      pharmacyPostalCode = 'E1H 2G2';
      pharmacyPhone = '1 (833) 753-1468';
      pharmacyFax = '1 (833) 478-1407';
    } else if (this.user.clinic_province === 'NS')  {
      pharmacyName = 'Sentrex Pharmacy Halifax';
      pharmacyAddress = '3H–110 Chain Lake Drive';
      pharmacyCity = 'Halifax';
      pharmacyProvince = 'NS';
      pharmacyPostalCode = 'B3S 1A9';
      pharmacyPhone = '1 (833) 743-0623';
      pharmacyFax = '1 (833) 516-2199';
    } else if (this.user.clinic_province === 'NL')  {
      pharmacyName = 'Sentrex Pharmacy Corner Brook';
      pharmacyAddress = '4 Grenfell Drive, Suite 101';
      pharmacyCity = 'Corner Brook';
      pharmacyProvince = 'NL';
      pharmacyPostalCode = 'A2H 0J6';
      pharmacyPhone = '1 (833) 753-1469';
      pharmacyFax = 'NA';
    } else if (this.user.clinic_province === 'ON')  {
      pharmacyName = '';
      pharmacyAddress = '';
      pharmacyPhone = '';
      pharmacyFax = '';
    }
    form.getTextField('Name of Pharmacy').setText(pharmacyName);
    form.getTextField('Address_3').setText(pharmacyAddress);
    form.getTextField('City_3').setText(pharmacyCity);
    form.getTextField('Province_3').setText(pharmacyProvince);
    form.getTextField('Postal Code_3').setText(pharmacyPostalCode);
    form.getTextField('Telephone Number_3').setText(pharmacyPhone);

    const pdfBytes = await pdfDoc.save()
    var file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  showPrivateSpecialPriorAuthorization() {
    if (!this.medicationControl.value || !this.insuranceProviderControl.value) return false;
    if (this.insuranceProviderControl.value.type === 'Private' && this.insuranceProviderControl.value.name === 'Medavie Blue Cross' && (this.medicationControl.value.name.includes('Eylea') || this.medicationControl.value.name.includes('Lucentis'))) {
      return true;
    }
    return false;
  }

  showPublicSpecialPriorAuthorization() {
    if (!this.medicationControl.value || !this.insuranceProviderControl.value) return false;
    if (this.insuranceProviderControl.value.type === 'Public') return true;
    return false;
  }

  showEyleaEnrollmentForm() {
    if (!this.medicationControl.value) return false;
    if (this.medicationControl.value.name === 'Eylea Vial' || this.medicationControl.value.name === 'Eylea PFS')  {
      return true;
    }
    return false;
  }

  showLucentisEnrollmentForm() {
    if (!this.medicationControl.value) return false;
    if (this.medicationControl.value.name === 'Lucentis Vial' || this.medicationControl.value.name === 'Lucentis PFS')  {
      return true;
    }
    return false;
  }

  showBeovuEnrollmentForm() {
    if (!this.medicationControl.value) return false;
    if (this.medicationControl.value.name === 'Beovu')  {
      return true;
    }
    return false;
  }
}

export const dobHealthcardValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const dob = control.get('dateofbirth');
  const hc = control.get('healthcard');
  return dob && hc && (dob.value || hc.value) ? null : { oneRequired: true };
};
