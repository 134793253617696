import { Injectable } from '@angular/core';
import * as moment from "moment";
import firebase from "firebase/app";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import {AngularFireStorage} from '@angular/fire/storage';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver/dist/FileSaver';
import * as JSZip from 'jszip';

import {
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
  } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})

export class UtilityService {

  constructor(
    private matSnackbar: MatSnackBar,
    private angularFireStorage: AngularFireStorage,
    private httpClient: HttpClient
  ) { }

  public formatDate(date, format) {
    let myMoment: moment.Moment
    if (!date) {
      return moment().format(format);
    }
    if (date.seconds) {
      myMoment = moment.unix(date.seconds);
    } else {
      myMoment = moment(date);
    }
    return myMoment.format(format);
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  showSnackBar(message: string, duration: number, horizontalPosition: MatSnackBarHorizontalPosition, verticalPosition: MatSnackBarVerticalPosition) {
    this.matSnackbar.open(message, '', {
      duration: duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  public roundDate(date, duration, method) {
    return moment(Math[method]((+date) / (+duration)) * (+duration)); 
  }

  pad(num, padlen, padchar) {
    var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  public formatE164(phone_number: string) {
    phone_number = phone_number.replace('-','').replace('(','').replace(')','').replace(/\s+/g,'').replace('+', '');
    if(phone_number.startsWith("1")) {
      phone_number = "+" + phone_number;
    } else {
      phone_number = "+1" + phone_number;
    }
    return phone_number;
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  public downloadZippedFiles(ordernumber: string): void {

    const zipFile: JSZip = new JSZip();
    let count = 0;
    var listRef = this.angularFireStorage.ref('/prescriptions/' + ordernumber);
    listRef.listAll().toPromise().then((res) => {
    res.prefixes.forEach((folderRef) => {
      console.log('folder: ', folderRef.name);
    });
    res.items.forEach((itemRef) => {
      itemRef.getDownloadURL().then(url => {
        this.httpClient.get(url, {responseType: 'blob'}).subscribe(response => {
          zipFile.file(itemRef.name, response, {binary: true});
          count++;
          if (count === res.items.length) {
            zipFile.generateAsync({type: 'blob'}).then(content => {
              saveAs(content, ordernumber + '.zip');
            });
          }
        });
      })
    });
  }).catch((error) => {
    console.log('error: ', error);
  });
  }
  
}
