import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { User } from 'src/app/shared/models/user';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.scss'],
})
export class UtilityComponent implements OnInit, OnDestroy {
  @ViewChild('saveDialog') saveDialog: TemplateRef<any>;

  ngUnsubscribe: Subject<void> = new Subject();
  settings: any;
  newsettings: any;
  user: User = <User>{};

  collection: string;
  document: string;
  newdocument: string;
  documentpath: string;
  newdocumentpath: string;

  constructor(
    private auth: AuthService,
    private db: DataService,
    private utility: UtilityService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {}

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    await this.db
      .getAppSettings()
      .subscribe((settings) => (this.settings = JSON.stringify(settings)));
  }

  ngOnDestroy() {
    // console.log('Destroy Utility View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async getDocument() {
    await this.db
      .getDocument(this.documentpath)
      .subscribe((doc) => (this.document = JSON.stringify(doc)));
  }

  saveDocument() {
    const updateData = JSON.parse(this.newdocument);
    this.db
      .setDocument(this.newdocumentpath, updateData)
      .then((success) => console.log(success))
      .catch((error) => console.log('Error: ', error));
  }

  saveSettings() {
    if (this.newsettings) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = '300px';
      let dialogRef = this.matDialog.open(this.saveDialog, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          if (result === 'yes') {
            const updateData = JSON.parse(this.newsettings);
            this.db
              .updateAppSettings(updateData)
              .then((success) => {
                this.utility.showSnackBar(
                  'The settings were successfully saved',
                  2000,
                  'center',
                  'top'
                );
              })
              .catch((error) => {
                this.utility.showSnackBar(
                  'There was a problem saving the settings: ' + error.message,
                  2000,
                  'center',
                  'top'
                );
              });
          }
        }
      });
    }
  }
}
