import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';
import { Group } from '../shared/models/group';
import { Patient } from '../shared/models/patient';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  patients: any = null;
  subscription: any;
  public loading: boolean = false;

  constructor(
    private db: AngularFirestore,
    private utility: UtilityService
  ) { }

  getPatients(clinicId: string) {
    this.loading = true;
    return this.db.collection<Patient>(`clinics/${clinicId}/patients`, (ref) =>
    ref
      .orderBy('last_name', 'asc')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getPatientsPromise(clinicId: string) {
    return this.db.collection<Patient>(`clinics/${clinicId}/patients`, (ref) =>
    ref
      .orderBy('last_name', 'asc')
    )
    .valueChanges({idField: 'id'});
  }

  getPatient(clinicId: string, patientId: string) {
    this.loading = true;
    return this.db.collection<Patient>(`clinics/${clinicId}/patients`).doc(patientId)
    .valueChanges()
    .pipe(
      tap(() => this.loading = false)
    );
  }

  async getPatientConsent(consentId: string): Promise<any> {
    return this.db
    .collection('consents')
    .doc(consentId)
    .ref
    .get()
    .then((doc) => {
        if (doc.exists) {
            // console.log(doc.data());
            return doc.data();
        } else {
            return 'Doc does not exits';
        }
     })
     .catch((err) => {
        console.error(err);
     });
  }


  getClinicGroups(clinicId: string) {
    return this.db.collection<Group>(`clinics/${clinicId}/groups`, ref =>
      ref.orderBy("group_name", "asc")
    );
  }

  editPatient(clinicId: string, patientData: any) {
      const updatedAt = this.utility.timestamp;
      patientData.updatedAt = updatedAt;
      return this.db.doc(`clinics/${clinicId}/patients/${patientData.id}`).set(patientData, {merge: true});
  }

  addPatient(clinicId: string, patientNew: Patient)  {
    return new Promise<any>((resolve, reject) => {
      const createdAt = this.utility.timestamp;
      const updatedAt = createdAt;
      const patientId = patientNew.id;
      patientNew.full_name = patientNew.first_name + ' ' + patientNew.last_name;
      // patientNew.dob = DateTime.fromISO(patientNew.dob)
      const patientDoc = { createdAt, updatedAt, ...patientNew };
      //const patients = this.afs.collection(`clinics/${clinic_id}/patients`);
      this.db.collection(`clinics/${clinicId}/patients`)
        .valueChanges()
        .pipe(take(1))
        .subscribe(patients => {
          let valid = true;
          const sameName = patients.filter((p) => p['full_name'].toLowerCase() === patientNew.full_name.toLowerCase());
          for (const patient of sameName) {
            if (patient['phone_number'].replace(/\D/g,'') === patientNew.phone_number.replace(/\D/g,'') ||
              (patient['email'].length > 0 && patient['email'].toLowerCase() === patientNew.email.toLowerCase()) ||
              patientNew.postal_code.replace(/ /g,'').toLowerCase() === patient["postal_code"].replace(/ /g,'').toLowerCase() ||
              patientNew.dob === patient["dob"] ||
              patientNew.healthcard.replace(/ /g,'').toLowerCase() === patient["healthcard"].replace(/ /g,'').toLowerCase()
            ) {
              valid = false;
            }
          }

          if (valid) {
            this.db.doc(`clinics/${clinicId}/patients/${patientId}`).set(patientDoc).then(() => {
              patientNew.id = patientId;
              // this.managePatientGroup(clinic_id, data);
              resolve(patientId);
            })
          } else {
            reject("This Patient Already Exists");
          }
        });
    });
  }

  deletePatient(clinicId: string, patientId: string)  {
    return this.db.collection(`clinics/${clinicId}/patients`).doc(patientId).delete();
  }

  addPatientConsent(clinicId: string, clinicName: string, patient: Patient, signature: string)  {
    const consentId = this.db.createId();
    const patientId = patient.id;
    const createdAt = this.utility.timestamp;
    
    let batch = this.db.firestore.batch();
    const patientDocRef = this.db.doc(`clinics/${clinicId}/patients/${patientId}`).ref;
    batch.set(patientDocRef, { consentSigned: true, consentDate: createdAt, consentId: consentId }, {merge: true});
    
    const consentDocRef = this.db.doc(`consents/${consentId}`).ref;
    batch.set(consentDocRef , { clinic_id: clinicId, clinic_name: clinicName, id: consentId, patient: patient, createdAt: createdAt, consentDate: createdAt, signature: signature });
    return batch.commit();
  }

  dispose() {
    // console.log('Patient service destroy');
  }
}
