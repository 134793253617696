import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { User } from 'src/app/shared/models/user';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-rx-report',
  templateUrl: './rx-report.component.html',
  styleUrls: ['./rx-report.component.scss']
})
export class RxReportComponent implements OnInit {
  @ViewChild('noteDialog') noteDialog: TemplateRef<any>;
  ngUnsubscribe: Subject<void> = new Subject();
  rx: any;
  public gridView: any;
  user: User = <User>{};
  appSettings: any;
  selectedNote: string;

  public sort: SortDescriptor[] = [
    {
      field: 'order_number',
      dir: 'desc',
    }
  ];
  
  constructor(
    private auth: AuthService,
    public reportService: ReportService,
    private utility: UtilityService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private db: DataService
  ) {}

  ngOnInit() {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.rx = this.reportService.getRx(this.user.clinic_id).pipe(
      map((rx) => {
        rx.forEach((rx) => {
          rx['createdAt'] = DateTime.fromSeconds(rx['createdAt'].seconds).toJSDate();
        });
        return rx;
      }),
      takeUntil(this.ngUnsubscribe)
    );

    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;
    });
  }

  viewNote(note)  {
    this.selectedNote = note;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = 300;
    // dialogConfig.disableClose = true;
    let dialogRef = this.matDialog.open(this.noteDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {});
  }



  ngOnDestroy() {
    // console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
