import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SignaturePadModule } from 'angular2-signaturepad';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { PatientAddComponent } from './patient/patient-add/patient-add.component';
import { PatientDetailComponent } from './patient/patient-detail/patient-detail.component';
import { ShellComponent } from './shared/shell/shell.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { RxListComponent } from './order/rx/rx-list/rx-list.component';
import { UserListComponent } from './admin/user/user-list/user-list.component';
import { RxAddComponent } from './order/rx/rx-add/rx-add.component';
import { RxReorderComponent } from './order/rx/rx-reorder/rx-reorder.component';
import { OrderListComponent } from './order/order/order-list/order-list.component';
import { UtilityComponent } from './admin/utility/utility.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { OrderDetailComponent } from './order/order/order-detail/order-detail.component';
import { FaxReportComponent } from './reports/fax-report/fax-report.component';
import { SupplyOrderComponent } from './order/supply/supply-order/supply-order.component';
import { RxReportComponent } from './reports/rx-report/rx-report.component';
import { ClinicListComponent } from './admin/clinic/clinic-list/clinic-list.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { DoctorListComponent } from './admin/doctor/doctor-list/doctor-list.component';
import { PatientUploadComponent } from './patient/patient-upload/patient-upload.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';

import { USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { ClinicScheduleComponent } from './reports/clinic-schedule/clinic-schedule.component';
import { SupplyListComponent } from './admin/supply/supply-list/supply-list.component';
import { OrderReportComponent } from './reports/order-report/order-report.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { InjectionClinicListComponent } from './injection-clinic/injection-clinic-list/injection-clinic-list.component';

@NgModule({
  declarations: [
    AppComponent,
    PatientListComponent,
    PatientAddComponent,
    PatientDetailComponent,
    ShellComponent,
    HomePageComponent,
    LoginPageComponent,
    RxListComponent,
    UserListComponent,
    RxAddComponent,
    RxReorderComponent,
    OrderListComponent,
    UtilityComponent,
    OrderDetailComponent,
    FaxReportComponent,
    SupplyOrderComponent,
    RxReportComponent,
    ClinicListComponent,
    DoctorListComponent,
    PatientUploadComponent,
    ClinicScheduleComponent,
    SupplyListComponent,
    OrderReportComponent,
    InjectionClinicListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule,
    ButtonsModule,
    GridModule,
    ExcelModule,
    PDFExportModule,
    FontAwesomeModule, 
    FlexLayoutModule,
    SignaturePadModule,
    PasswordStrengthMeterModule,
    NgxCsvParserModule,
    SchedulerModule,
    NgxMaterialTimepickerModule
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    // {
    //   provide: FIRESTORE_EMULATOR,
    //   useValue: environment.production ? undefined : ['localhost', 8080],
    // },
    // {
    //   provide: FUNCTIONS_EMULATOR,
    //   useValue: environment.production ? undefined : ['localhost', 5001],
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
