import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DataResult, GroupDescriptor, process } from '@progress/kendo-data-query';
import { map, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Rx } from 'src/app/shared/models/rx';
import { User } from 'src/app/shared/models/user';
import { SignaturePad } from 'angular2-signaturepad';
import { MatStepper } from '@angular/material/stepper';
import { CreatedBy, RxOrder } from 'src/app/shared/models/order';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from 'src/app/order/order.service';
import { RxAddComponent } from 'src/app/order/rx/rx-add/rx-add.component';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit, OnDestroy  {
  @ViewChild('callDeleteConfirmDialog') callDeleteConfirmDialog: TemplateRef<any>;
  @ViewChild('orderSubmitConfirmDialog') orderSubmitConfirmDialog: TemplateRef<any>;
  @ViewChild('submitDialog') submitDialog: TemplateRef<any>;
  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;
  @ViewChild('stepper') stepper: MatStepper;
  ngUnsubscribe: Subject<void> = new Subject();

  user: User;
  orders: any[] = [];
  orderdoctors: Doctor[] = [];
  submitDialogRef: any;
  language: string;
  // public groups: GroupDescriptor[] = [
  //   { field: 'doctor.full_name', aggregates: [{aggregate: "count", field: 'doctor.full_name'}]}, 
  //   { field: 'medication_name', aggregates: [{aggregate: "count", field: 'medication_name'}]}, 
    
  //   //{ field: 'quantity', aggregates: [{aggregate: "count", field: "quantity"}] }
  // ];

  public gridView: DataResult;
  constructor(
    private auth: AuthService,
    public orderService: OrderService,
    private matDialog: MatDialog,
    public utility: UtilityService,
    private router: Router,
    private translate: TranslateService,
    private reportService: ReportService
  ) {
   }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
  }
  
  ngOnDestroy() {
    // console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.reportService.getOrders().pipe(
      map((orders) => {
        // orders.forEach((order) => {
        //   order['order_number'] = `RD${this.utility.pad(order['number'], 10, '0')}`;
        //   order['rx_count'] = order['rx'].length;
        // });
        return orders;
      }),
      take(1)).subscribe((orders) => {
      this.orders = orders;
      // this.gridView = process(this.order, { group: this.groups });
    })
  }

  // public groupChange(groups: GroupDescriptor[]): void {
  //   this.groups = groups;
  //   this.gridView = process(this.order, { group: this.groups });
  // }

  async deleteBatch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              this.orderService.deleteRxBatchByClinic(this.user.clinic_id).then(() => {
                this.utility.showSnackBar("The order has been cleared.", 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar("There was a problem clearing the order.  Please try again", 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  async editRx(rx: Rx)  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    dialogConfig.data = rx;
    this.matDialog.open(RxAddComponent, dialogConfig);
  }

  async deleteRx(rxId: string)  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.callDeleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              this.orderService.deleteRx(rxId).then(() => {
                this.utility.showSnackBar("The prescription has been deleted and removed from this order.", 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar("There was a problem deleting the prescription.  Please try again", 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  /********* Submit Order *****************/
  async openSubmitOrderDialog() {
    this.orderdoctors = this.getUniqueDoctors();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.submitDialogRef = this.matDialog.open(this.submitDialog, dialogConfig);
      this.submitDialogRef.afterClosed().subscribe(result => {
        // console.log("Dialog close result: ", result);
        if (result !== undefined) {
            if (result !== 'Cancel') {

            } 
        }
    })
  }

  async submitOrder() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '300px';
    let dialogRef = this.matDialog.open(this.orderSubmitConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
        if (result !== undefined) {
            if (result === 'yes') {
              let order: RxOrder = <RxOrder>{};
              order.clinic_id = this.user.clinic_id;
              order.clinic_name = this.user.clinic_name;
              order.clinic_province = this.user.clinic_province;
              order.orderDoctors = this.orderdoctors;
              order.rx = this.orders;
              order.status = 'Queue';
              order.type = 'Rx';
              order.complete = false;
          
              let createdBy: CreatedBy = <CreatedBy>{};
              createdBy.id = this.user.id;
              createdBy.name = this.user.display_name;
              order.createdBy = createdBy;
          
              this.orderService.createRxOrder(order).then(async () => {
                this.submitDialogRef.close();
                await this.router.navigate(['/patient-list']);
                this.utility.showSnackBar("The order was successfully submitted.", 2000, 'center', 'top');
              })
              .catch((err) => {
                this.utility.showSnackBar("There was a problem submitting the order.  Please try again", 2000, 'center', 'top');
              })
            } 
        }
    })
  }

  getUniqueDoctors(): Doctor[] {
    let doctors = this.orders.map(rx => rx.doctor)
    let uniqueDoctors = [...new Map(doctors.map(item => [item['id'], item])).values()];
    return uniqueDoctors;
  }

  nextClicked(event) {
    // console.log(event);
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
  }

  drawStart(index) {
    // console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    // console.log("Ended drawing on signature", index);
    let signaturePad = this.signaturePads.toArray()[index];
    if(!signaturePad.isEmpty())  {
      this.orderdoctors[index].signature = signaturePad.toDataURL();
    }
  }

  downloadRxs(orderNumber: string)  {
    this.utility.downloadZippedFiles(orderNumber);
  }
  
  buildLink(dataItem) {
    if (dataItem.injectionclinic) {
      return '/order-detail/' + dataItem.id + '/' + dataItem.order_number + '/order-report/' + dataItem.injectionclinic.name  + '/' + this.utility.formatDate(dataItem.injectionclinic.clinicDate, 'YYYY-MM-DD') + (dataItem.reconciled ? '/true' : '/false')
    } else {
      return '/order-detail/' + dataItem.id + '/' + dataItem.order_number + '/order-report/NA/NA' + (dataItem.reconciled ? '/true' : '/false')
    }
  }

}
