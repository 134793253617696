import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { Rx } from 'src/app/shared/models/rx';
import { PdfService } from 'src/app/services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from 'src/app/services/utility.service';
import { OrderService } from '../../order.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-rx-reorder',
  templateUrl: './rx-reorder.component.html',
  styleUrls: ['./rx-reorder.component.scss']
})
export class RxReorderComponent implements OnInit {

  user: User;
  logo: string;
  patient: any;
  last_rx: any;
  last_order_date: any;
  appSettings: any;
  datestamp: any;
  language: string;

  rxForm: FormGroup;
  // expectedDeliveryDateControl = new FormControl();
  appointmentDateControl = new FormControl();
  notesControl = new FormControl();

  constructor( 
    private auth: AuthService,
    private db: DataService,
    private pdfRx: PdfService,
    public dialogRef: MatDialogRef<RxReorderComponent>,
    private ts: TranslateService,
    private utility: UtilityService,
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  async ngOnInit() {
    this.language = this.ts.currentLang;
    this.logo = environment.SENTREX_LOGO;
    this.patient = { ...this.data};
    this.last_rx = {...this.data.last_rx};
    delete this.patient.last_rx;
    delete this.last_rx.id;
    delete this.last_rx.patient;
    delete this.last_rx.doctorSignature
    delete this.last_rx.orderId;
    delete this.last_rx.order_id;
    delete this.last_rx.order_number;
    delete this.last_rx.status;
    delete this.last_rx.prescriptionImage;

    this.rxForm = new FormGroup({
      // expectectedDeliveryDate: this.expectedDeliveryDateControl,
      appointmentDate: this.appointmentDateControl,
      notes: this.notesControl
    });

    this.last_order_date = this.utility.formatDate(this.last_rx.createdAt, 'yyyy-MM-DD');
    this.datestamp = DateTime.now().toLocaleString(DateTime.DATETIME_FULL);
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.db.getAppSettings().pipe(take(1)).subscribe(settings => {
      this.appSettings = settings;
    });
    
  }

  async reorderRx() {
    let rx: Rx = <Rx>{};
    rx = this.last_rx;
    delete rx.createdAt;
    delete rx.createdBy;
    rx.clinic = this.user.clinic;
    rx.patient = this.patient;
    rx.patient = this.patient;
    rx.patient_id = this.patient.id;
    // rx.expectedDeliveryDate = this.expectedDeliveryDateControl.value ? this.expectedDeliveryDateControl.value : null;
    rx.appointmentDate = this.appointmentDateControl.value ? this.appointmentDateControl.value : null;
    if (rx.eye === 'BOTH') {
      rx.quantity = "2x";
      rx.quantity_numeric = 2;
    } else {
      rx.quantity = "1x";
      rx.quantity_numeric = 1;
    }
    this.orderService.addRxToBatch(this.user, rx).then(() => {
      this.utility.showSnackBar(this.ts.instant('rx.success.add'), 2000, 'center', 'top');
      this.close();
    })
    .catch((err) => {
      // console.log(err);
    })
  }

  close() {
    this.dialogRef.close();
  }

}
